import { useCallback } from 'react'

import { useAppStore } from '$store/hooks'
import { showPopup } from '$store/slices/popup'
import { PopupConfig } from '$store/slices/popup/types'

const usePromoStatus = (
  openPopup: boolean = true,
  popupConfig: PopupConfig = {
    title: 'Приём окончен',
  },
) => {
  const store = useAppStore()

  const PromoStatusHandler = useCallback(() => {
    const { status } = store.getState().config.promo
    const promoEnd = store.getState().config.promo.period.promo_end

    const promoEndDate = promoEnd.split(' ')[0].replace(/\//g, '.')

    if (status === 'postpromo') {
      if (openPopup) {
        store.dispatch(
          showPopup({
            title: popupConfig.title,
            message: `Акция закончилась ${promoEndDate}`,
          }),
        )
      }

      return true
    }
    return false
  }, [store, openPopup])

  return PromoStatusHandler
}

export default usePromoStatus
