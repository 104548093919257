import React, { useEffect } from 'react'

import { useAppSelector } from '$store/hooks'

const VKPixel: React.FC = () => {
  const vkPixel = useAppSelector((store) => store.config.promo.vk_pixel)

  useEffect(() => {
    if (vkPixel) {
      const t = document.createElement('script')
      t.type = 'text/javascript'
      t.async = true
      t.src = 'https://vk.com/js/api/openapi.js?160'

      t.onload = () => {
        VK.Retargeting.Init(vkPixel)
        VK.Retargeting.Hit()
      }

      document.head.appendChild(t)

      const n = document.createElement('noscript')
      const img = document.createElement('img')
      img.src = `https://vk.com/rtrg?p=${vkPixel}`
      img.style.position = 'fixed'
      img.style.left = '-999px'
      img.alt = ''
      n.appendChild(img)

      document.head.appendChild(n)
    }
  }, [vkPixel])

  return null
}

export default VKPixel
