import React, { useMemo } from 'react'

import { useDispatch } from 'react-redux'

import pbApi from '$api/promobuilding/pbApi'

import { setFullConfig } from '$store/slices/config/thunks/config'

import useFormSubmitHandler from '$hooks/useFormSubmitHandler/useFormSubmitHandler'
import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'
import useValidators from '$hooks/useValidators/useValidators'

import DateTimeField from '$components/form/fields/formikField/field/DateTimeField/DateTimeField'
import FormikConstructor from '$components/form/formik/constructor/FormConstructor/formikConstructor'
import initializeValue from '$components/form/formik/utils/InitializeFormikValues'
import validatorCombiner from '$components/form/validators/validatorCombiner'

const AdultForm: React.FC = () => {
  const initialValues = useMemo(() => initializeValue(['birthday'])(), [])
  const dictionary = useLanguageDictionary()
  const dispatch = useDispatch()
  const setAdultHandler = useFormSubmitHandler<typeof initialValues>(
    [],
    (values) =>
      pbApi.setUserAdult({ is_adult: true, ...values }).then(() => {
        pbApi.getFullConfig().then(({ data: { data } }) => {
          dispatch(setFullConfig(data))
        })
      }),
  )

  const { isRequired, notAllowFutureTime } = useValidators()

  return (
    <>
      <div style={{ textAlign: 'center', marginBottom: 15 }}>
        Для подтверждения совершеннолетия
      </div>
      <FormikConstructor
        name="adult"
        initialValues={initialValues}
        onSubmit={setAdultHandler}
        submitButtonName={dictionary.button.send}
        direction="horizontal"
      >
        <DateTimeField
          format="date"
          dateLabel="Дата рождения"
          name="birthday"
          validate={validatorCombiner([isRequired, notAllowFutureTime])}
        />
      </FormikConstructor>
    </>
  )
}

export default AdultForm
