const errors = {
  fileFormat: {
    0: 'Файл пішімі',
    1: 'жүктеу үшін жарамсыз',
  },
  fileSize: 'Файлдың максималды өлшемі - ',
  isRequired: 'Өріс міндетті түрде толтырылады',
  isRequiredCheckbox: {
    rules: 'Акция ережелерімен келісу қажет',
    majority: 'Кәмелетке толғандығын растау қажет',
    media: 'Ақпараттық таратылымдармен келісім қажет',
    opd: 'Дербес деректерді өңдеу саясатымен келісу қажет',
  },
  isValidEmail: 'Дұрыс емес формат',
  isValidName: {
    0: '',
    1: { name: 'Атауды', lastName: 'Фамилияны', middleName: 'Әкесінің атын' },
    2: 'дұрыс енгізіңіз',
  },
  maxLength: 'Таңбалардың максималды саны',
  minLength: 'Таңбалардың ең аз саны',
  maxVal: 'Максималды мән',
  minVal: 'Минималды мән',
  notAllowFutureTime: 'Таңбалардың ең аз саны',
  onlyCyrillic: 'Мәтінде тек орыс таңбалары болуы мүмкін',
  onlyCyrillicAndOther: 'Орыс тіліндегі мәтінді енгізіңіз',
  passwordsNotMatch: 'Құпия сөздер бірдей емес',
  wrongFormat: 'Жарамсыз формат',
  isInteger: 'Тек бүтін мән',
}

export default errors
