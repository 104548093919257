import React, { useMemo } from 'react'

// COMPONENTS

import { connect, ConnectedProps } from 'react-redux'

import pbApi from '$api/promobuilding/pbApi'

import { useAppSelector } from '$store/hooks'
import { ChecksType } from '$store/slices/config/types/user/userType'
import { showPopup } from '$store/slices/popup'
import { PopupConfig } from '$store/slices/popup/types'
import { setUploadChecks } from '$store/slices/user'
import { AppDispatch, RootState } from '$store/store'

import useFormSubmitHandler from '$hooks/useFormSubmitHandler/useFormSubmitHandler'
import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'
import useValidators from '$hooks/useValidators/useValidators'

import ChecksFieldHint from '$components/form/fields/other/hint/ChecksHint/ChecksFieldHint'
import TextInputField from '$components/form/formik/fields/TextInputField'
import validatorCombiner from '$components/form/validators/validatorCombiner'

import DateTimeField from '$form/fields/formikField/field/DateTimeField/DateTimeField'

import FormikConstructor from '../../../constructor/FormConstructor/formikConstructor'

// VALIDATORS

const ManualUploadCheck: React.FC<Props> = ({
  updateUserChecks,
  showPopupAction,
  recaptcha,
}) => {
  const initialValues = useMemo(
    () => ({
      fd: '',
      fp: '',
      fn: '',
      datetime: '',
      sum: '',
    }),
    [],
  )
  const { isRequired } = useValidators()
  const dictionary = useLanguageDictionary()
  const isKZHandler = useAppSelector(
    (store) => store.config.check!.checkHandler === 'Проверка чеков KZ',
  )
  const submitCheckData = useFormSubmitHandler<typeof initialValues>(
    [],
    (values) =>
      pbApi
        .manualUploadCheck({ ...values })
        .then((res) => {
          updateUserChecks(res.data.checks)
          showPopupAction({
            type: 'success',
            title: dictionary.messages.checkIsUpload,
            message: dictionary.messages.successCheck,
          })
        })
        .catch(() => undefined),
  )

  return (
    <FormikConstructor
      name="checks/promocodes"
      onSubmit={submitCheckData}
      initialValues={initialValues}
      submitButtonName="Отправить"
      dataTest="upload-check-button"
      recaptcha={recaptcha}
    >
      <ChecksFieldHint type="fd">
        <TextInputField
          name="fd"
          placeholder={isKZHandler ? 'РНМ' : dictionary.fields.FD}
          type="text"
          inputMode="numeric"
          data-test="fd-input"
          validate={validatorCombiner([isRequired])}
        />
      </ChecksFieldHint>
      <ChecksFieldHint type="fp">
        <TextInputField
          name="fp"
          placeholder={dictionary.fields.FP}
          type="text"
          inputMode="numeric"
          data-test="fp-input"
          validate={validatorCombiner([isRequired])}
        />
      </ChecksFieldHint>
      {!isKZHandler && (
        <ChecksFieldHint type="fn">
          <TextInputField
            name="fn"
            placeholder={dictionary.fields.FN}
            data-test="fn-input"
            type="text"
            inputMode="numeric"
            validate={validatorCombiner([isRequired])}
          />
        </ChecksFieldHint>
      )}
      <ChecksFieldHint type="datetime">
        <DateTimeField
          name="datetime"
          format="datetime"
          dateLabel={dictionary.fields.date}
          timeLabel={dictionary.fields.time}
          validate={validatorCombiner([isRequired])}
        />
      </ChecksFieldHint>
      <ChecksFieldHint type="sum">
        <TextInputField
          name="sum"
          placeholder={dictionary.fields.sum}
          inputMode="decimal"
          data-test="sum-input"
          validate={validatorCombiner([isRequired])}
        />
      </ChecksFieldHint>
    </FormikConstructor>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({
    recaptcha: state.config.check?.recaptcha,
  }),
  (dispatch: AppDispatch) => ({
    updateUserChecks: (data: Array<ChecksType>) => {
      dispatch(setUploadChecks(data))
    },
    showPopupAction: (popupConfig: PopupConfig) => {
      dispatch(showPopup(popupConfig))
    },
  }),
)

export default connector(ManualUploadCheck)
