import React, { ButtonHTMLAttributes } from 'react'

import classNames from 'classnames'

import classes from './CloseIconStrict.module.scss'

const CloseIconStrict: React.FC<ButtonHTMLAttributes<unknown>> = ({
  onClick,
  disabled,
  className,
  ...props
}) => (
  <div
    {...props}
    onClick={onClick}
    className={classNames(
      classes['icon'],
      disabled && classes['icon_disabled'],
      className,
    )}
  >
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7.5" cy="7.5" r="7.5" fill="#212529" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.7773 4.13397L10.8192 4.17208C11.0352 4.38803 11.0479 4.73026 10.8573 4.96106L10.8192 5.00297L8.32638 7.49549L10.8279 9.99703C11.0439 10.213 11.0566 10.5552 10.866 10.786L10.8279 10.8279C10.612 11.0439 10.2697 11.0566 10.0389 10.866L9.99703 10.8279L7.49549 8.32638L5.00297 10.8192C4.78703 11.0352 4.4448 11.0479 4.214 10.8573L4.17208 10.8192C3.95614 10.6033 3.94343 10.261 4.13397 10.0302L4.17208 9.98832L6.6646 7.49549L4.18079 5.01168C3.96485 4.79574 3.95214 4.45351 4.14268 4.2227L4.18079 4.18079C4.39674 3.96484 4.73897 3.95214 4.96977 4.14268L5.01168 4.18079L7.49549 6.6646L9.98832 4.17208C10.2043 3.95614 10.5465 3.94343 10.7773 4.13397Z"
        fill="white"
      />
    </svg>

    {/* <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.856 0.895202L17.963 0.992495C18.5144 1.54382 18.5468 2.41755 18.0603 3.00681L17.963 3.11382L11.5987 9.47738L17.9853 15.864C18.5366 16.4153 18.569 17.289 18.0826 17.8783L17.9853 17.9853C17.434 18.5366 16.5602 18.5691 15.971 18.0826L15.864 17.9853L9.47737 11.5987L3.1138 17.9631C2.56248 18.5144 1.68875 18.5468 1.09949 18.0604L0.992483 17.9631C0.441155 17.4117 0.408723 16.538 0.89519 15.9487L0.992483 15.8417L7.35605 9.47738L1.01472 3.13605C0.46339 2.58472 0.43096 1.71099 0.917426 1.12173L1.01472 1.01473C1.56605 0.463402 2.43978 0.430971 3.02904 0.917437L3.13604 1.01473L9.47737 7.35606L15.8417 0.992495C16.3931 0.441167 17.2668 0.408736 17.856 0.895202L17.963 0.992495L17.856 0.895202Z"
      />
    </svg> */}
  </div>
)

export default CloseIconStrict
