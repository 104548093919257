import { useCallback } from 'react'

import { useRouter } from 'next/router'

import PopupId from '$constants/common/popupsID/popupsID'
import routePath from '$constants/common/routePath/routePath'

import { useAppStore, useAppDispatch } from '$store/hooks'
import { hidePopup } from '$store/slices/popup'

const useUnAuthRedirect = () => {
  const router = useRouter()
  const dispatch = useAppDispatch()
  const store = useAppStore()

  const redirect = useCallback(() => {
    const vt = store.getState().config.auth?.viewTemplate
    router.replace(
      {
        pathname: vt === 'modal' ? '/' : routePath.auth,
        href: vt === 'modal' ? '/' : routePath.auth,
        query: Object.assign(
          vt === 'modal' ? { popupid: PopupId.sign_in } : {},
        ),
      },
      undefined,
      {
        shallow: true,
      },
    )

    if (vt === 'page') {
      dispatch(hidePopup())
    }
  }, [router, store])

  return () => {
    redirect()
  }
}

export default useUnAuthRedirect
