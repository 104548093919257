import React, {
  PropsWithChildren,
  useState,
  useLayoutEffect,
  useRef,
  useCallback,
  useContext,
} from 'react'

import classNames from 'classnames'

import { ViewportContext } from '$services/ViewportServices/ViewportServices'

import ClipSpinner from '$components/ui/spinners/ClipSpinner/ClipSpinner'

import classes from './HintWrapper.module.scss'

export interface HintWrapperInterface {
  visible?: boolean
  absolute?: boolean
  disabled?: boolean
  hint: React.FC
  align?: 'center' | 'top' | 'bottom'
  preview?: {
    type: 'text' | 'image'
    content: any
    onClick?: () => unknown
  }
  onClick?: (a: any) => void
}

const HintWrapper: React.FC<PropsWithChildren<HintWrapperInterface>> = ({
  hint,
  preview,
  children,
  align = 'center',
  visible = true,
  absolute = false,
  disabled = false,
  onClick,
}) => {
  const Hint = hint
  const [showPreview, setShowPreview] = useState(false)
  const [state, setState] = useState(false)
  const [clickStatus, setClickStatus] = useState(0)
  const hintElement = useRef<HTMLButtonElement>(null)

  const { isMobile, isTablet } = useContext(ViewportContext)

  const iconClickHandler = useCallback((e: MouseEvent) => {
    if (e.target === hintElement.current?.firstChild) {
      setClickStatus((prevClickStatus) =>
        prevClickStatus === 2 ? 1 : ++prevClickStatus,
      )
    } else {
      setClickStatus(0)
    }
  }, [])

  useLayoutEffect(() => {
    window.addEventListener('click', iconClickHandler)
    return () => {
      window.removeEventListener('click', iconClickHandler)
    }
  }, [])

  let timer = setTimeout(() => {}, 0)

  if (!visible) {
    return <>{children}</>
  }

  return (
    <div className={classes['wrapper']}>
      <div className={classes['input']}>{children}</div>
      <div
        className={classNames(classes['block'], {
          [classes['absolute']]: absolute,
        })}
      >
        {state ? (
          <ClipSpinner size={20} />
        ) : (
          <button
            type="button"
            ref={hintElement}
            disabled={disabled}
            onMouseEnter={() => {
              clearTimeout(timer)
              setShowPreview(true)
            }}
            onMouseLeave={() => {
              timer = setTimeout(
                () => {
                  setShowPreview(false)
                },
                isTablet ? 0 : 300,
              )
            }}
            onClick={(e) => {
              if (onClick) {
                if (!isMobile) {
                  setState(true)
                  Promise.resolve(onClick(e)).finally(() => {
                    setState(false)
                  })
                }
                if (clickStatus === 1) {
                  setState(true)
                  Promise.resolve(onClick(e)).finally(() => {
                    setState(false)
                  })
                }
              }
            }}
            className={classNames(classes['pointer'], {
              [classes['visible']]: clickStatus === 1 && isMobile,
            })}
          >
            <Hint />
          </button>
        )}
        {preview && (
          <div
            className={classNames(
              classes['preview'],
              classes[`${preview.type}`],
              classes[`${align}`],
              showPreview && classes['visible'],
            )}
          >
            {preview.type === 'image' && (
              <img src={preview.content} alt="preview-hint" />
            )}
            {preview.type === 'text' && (
              <div className={classes['textBlock']}>{preview.content}</div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default HintWrapper
