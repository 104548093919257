enum ReviewsFieldName {
  email = 'email',
  text = 'text',
  agreement = 'agreement',
  name = 'name',
  file = 'file',
  subject = 'review_on',
}

export default ReviewsFieldName
