import React, { PropsWithChildren } from 'react'

import PageWrapper from '../PageWrapper/PageWrapper'

import s from './FormWrapper.module.scss'

const FormWrapper: React.FC<
  PropsWithChildren<{
    formName: string
  }>
> = ({ formName, children }) => (
  <PageWrapper>
    <h1 className="title">{formName}</h1>
    <div className={s['page-form-wrapper']}>
      <div className={s['page-form-wrapper__form-block']}>{children}</div>
    </div>
  </PageWrapper>
)

export default FormWrapper
