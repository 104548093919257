import React from 'react'

import classes from './Error.module.scss'

const Error: React.FC<{ error: string }> = ({ error = '' }) => (
  <span data-test="error-text" className={classes['inputError']}>
    {error}
  </span>
)

export default Error
