import AdultBlock from '$components/blocks/AdultBlock/AdultBlock'
import MainContainer from '$components/container/MainContainer/MainContainer'
import PageWrapper from '$components/PageWrapper/PageWrapper'

const AdultFormLayout = () => (
  <div style={{ display: 'flex', minHeight: '100vh', alignItems: 'center' }}>
    <MainContainer>
      <PageWrapper>
        <AdultBlock />
      </PageWrapper>
    </MainContainer>
  </div>
)

export default AdultFormLayout
