const links = {
  account: 'Жеке кабинет',
  businessChallenge: 'Бизнем-міндеттер',
  profile: 'Бейін',
  signUp: 'Тіркеу',
  signIn: 'Кіру',
  restorePassword: 'Құпия сөзді қалпына келтіріңіз',
  policy: 'Құпиялылық саясаты',
  rules: 'Акция Ережелері',
  getPrize: 'Сыйлық алыңыз',
}

export default links
