import axios from 'axios'

import { isObject } from './middleware/helpers'

export const PROMOBUILDING_VALIDATION_ERROR = 'PROMOBUILDING_VALIDATION_ERROR'

export interface PromobuildingValidationError {
  type: typeof PROMOBUILDING_VALIDATION_ERROR
  errors: Record<string, any>
}

export function isPromobuildingValidationError(
  obj: unknown,
): obj is PromobuildingValidationError {
  if (
    isObject<PromobuildingValidationError>(obj) &&
    obj.type === PROMOBUILDING_VALIDATION_ERROR &&
    isObject(obj.errors)
  ) {
    return true
  }

  return false
}

export function wrapPlainValidationError(
  err: unknown,
): PromobuildingValidationError | undefined {
  if (isObject<Record<string, any>>(err)) {
    return {
      type: PROMOBUILDING_VALIDATION_ERROR,
      errors: err,
    }
  }
}

export function wrapAxiosValidationError(
  err: unknown,
): PromobuildingValidationError | undefined {
  if (axios.isAxiosError(err) && err.response?.data) {
    return wrapPlainValidationError(err.response.data)
  }
}

export function extractValidationErrors(err: unknown, throwOnFail = true) {
  if (axios.isAxiosError(err)) {
    const data = err.response?.data
    if (isObject<any>(data)) {
      return data
    }
  } else if (isPromobuildingValidationError(err)) {
    return err.errors
  }

  if (throwOnFail) {
    throw err
  }
}
