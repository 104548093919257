import { PropsWithChildren, useMemo } from 'react'

import { useSelector } from 'react-redux'

import errorsID from '$constants/common/errorsID/errorsID'

import { useAppSelector } from '$store/hooks'
import { RootState } from '$store/store'

import ErrorManagement from '$components/errors/ErrorsManagement'

import MetaTags from '$/other/meta-tags/MetaTags'

export default function PromoEndTrap({ children }: PropsWithChildren<unknown>) {
  const promoStatus = useAppSelector((state) => state.config.promo.status)

  const name = useSelector((state: RootState) => state.config.promo.name)

  const promoEndConfig = useMemo(
    () => ({
      id: errorsID.PROMO_IS_END,
      title: 'К сожалению, проект уже закончился :(',
      text: 'Но не отчаивайтесь, сейчас проходят десятки других проектов, в которых тоже можно поучаствовать!',
      btnName: 'Найти подходящий',
      goTo: `https://promobills.ru/?utm_source=promobuilding&utm_medium=end&utm_campaign=${name}`,
      pbStyle: true,
    }),
    [name],
  )

  if (promoStatus === 'after-end') {
    return (
      <>
        <MetaTags title="Главная" />
        <ErrorManagement errorConfig={promoEndConfig} />
      </>
    )
  }

  return <>{children}</>
}
