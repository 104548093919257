enum FeedbackFieldName {
  email = 'email',
  message = 'message',
  promo_rules = 'promo_rules',
  name = 'name',
  file = 'file',
  subject = 'subject_active',
  receiving_promotional_mailing = 'receiving_promotional_mailing',
}

export default FeedbackFieldName
