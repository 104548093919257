const button = {
  signUp: 'Тіркеу',
  signIn: 'Кіру',
  logOut: 'Шығу',
  account: 'Жеке кабинет',
  checkUpload: 'Чекті жүктеп',
  codeUpload: 'Промокодты жүктеу',
  photoUpload: 'Чектің суретін жүктеу',
  handInsert: 'Чектерді қолмен жүктеу',
  qrScan: 'QR сканерлеу',
  sendReview: 'Пікір қалдыру',
  send: 'Жіберу',
  upload: 'Жүктеу',
  changeFile: 'Файлды таңдаңыз',
  change: 'Өзгерту',
  restore: 'Қалпына келтіру',
  changePassword: 'Құпия сөзді өзгерту',
  confirmPhoneNumber: 'Телефон нөмірін растаңыз',
  more: 'Толығырақ',
  showMore: 'Көбірек көрсету',
  readNews: 'Мақаланы оқыңыз',
  subscribe: 'Қол қою',
  fix: 'Түзету',
  confirm: 'Растау',
  accept: 'Қабылдау',
  retry: 'Қайтадан байқап көріңіз',
  signed: 'Қол қою',
}

export default button
