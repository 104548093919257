import React, { ButtonHTMLAttributes } from 'react'

import classNames from 'classnames'

import classes from './CloseIcon.module.scss'

const ClearIcon: React.FC<ButtonHTMLAttributes<unknown>> = ({
  onClick,
  disabled,
  className,
  ...props
}) => (
  <div
    {...props}
    onClick={onClick}
    className={classNames(
      classes['icon'],
      disabled && classes['icon_disabled'],
      className,
    )}
  >
    {/* <svg
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M6.24885 10.5H10.914M9.49771 2.7093L10.2059 3.41755M10.5599 0.938692C10.6994 1.07819 10.8101 1.24381 10.8856 1.42609C10.9611 1.60838 11 1.80375 11 2.00106C11 2.19837 10.9611 2.39374 10.8856 2.57603C10.8101 2.75831 10.6994 2.92393 10.5599 3.06343L3.83257 9.79175L1 10.5L1.70814 7.70668L8.43833 0.941525C8.7036 0.674922 9.05941 0.517827 9.43511 0.501427C9.81082 0.485026 10.179 0.61052 10.4664 0.852994L10.5599 0.938692Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg> */}

    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.856 0.895202L17.963 0.992495C18.5144 1.54382 18.5468 2.41755 18.0603 3.00681L17.963 3.11382L11.5987 9.47738L17.9853 15.864C18.5366 16.4153 18.569 17.289 18.0826 17.8783L17.9853 17.9853C17.434 18.5366 16.5602 18.5691 15.971 18.0826L15.864 17.9853L9.47737 11.5987L3.1138 17.9631C2.56248 18.5144 1.68875 18.5468 1.09949 18.0604L0.992483 17.9631C0.441155 17.4117 0.408723 16.538 0.89519 15.9487L0.992483 15.8417L7.35605 9.47738L1.01472 3.13605C0.46339 2.58472 0.43096 1.71099 0.917426 1.12173L1.01472 1.01473C1.56605 0.463402 2.43978 0.430971 3.02904 0.917437L3.13604 1.01473L9.47737 7.35606L15.8417 0.992495C16.3931 0.441167 17.2668 0.408736 17.856 0.895202L17.963 0.992495L17.856 0.895202Z"
      />
    </svg>
  </div>
)

export default ClearIcon
