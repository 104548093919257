function initializeValue<T extends string>(
  fieldList: T[],
): () => Record<T, string>

function initializeValue<TFields extends string, TObject extends object>(
  fieldList: TFields[],
  extendsField: TObject,
): () => Record<TFields, string> & TObject

function initializeValue<T extends string, B extends string = any>(
  fieldList: Array<T>,
  extendsField?: Record<B, any>,
) {
  return () => {
    const initialLocalValues = fieldList.reduce((acc, v) => {
      acc[v] = ''
      return acc
    }, {} as Record<T, string>)
    const extendsInitialType = {
      ...initialLocalValues,
      ...(extendsField ?? {}),
    }

    return extendsInitialType
  }
}

export default initializeValue
