import { useAppSelector } from '$store/hooks'

import DICTIONARY from '$/dictionary/main'

export type dictionary = typeof DICTIONARY.ru
let key: keyof dictionary
const mokeDictionary = {} as any

for (key in DICTIONARY.ru) {
  if (typeof DICTIONARY.ru[key] === 'object') {
    // eslint-disable-next-line
    for (const key2 in DICTIONARY.ru[key]) {
      mokeDictionary[key] = {
        ...mokeDictionary[key],
        [key2]: 'NULL',
      }
    }
  }
}

const useLanguageDictionary = () => {
  const isMulti = useAppSelector((store) => store.config.site?.multilang)
  const { language } = useAppSelector((store) => store.language)
  return isMulti ? DICTIONARY[language] ?? mokeDictionary : DICTIONARY.ru
}

export type DictionaryType = typeof DICTIONARY.ru

export default useLanguageDictionary
