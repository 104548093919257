import React from 'react'

import CanvasDraw, { CanvasDrawProps } from 'react-canvas-draw'

interface Props extends CanvasDrawProps {}

// eslint-disable-next-line
const DrawCanvas = React.forwardRef((props: Props, ref: any) => (
  <div>
    <CanvasDraw {...props} ref={ref} />
  </div>
))

export default DrawCanvas
