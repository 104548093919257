import { createAsyncThunk } from '@reduxjs/toolkit'

import pbApi from '$api/promobuilding/pbApi'

import { MainConfigType } from '../types'

export const getFullConfig = createAsyncThunk(
  'user/getFullConfig',
  async () => (await pbApi.getFullConfig()).data.data,
)

export const setFullConfig = createAsyncThunk(
  'user/setFullConfig',
  async (config: MainConfigType) => config,
)
