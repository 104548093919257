import { PropsWithChildren } from 'react'

import classNames from 'classnames'

import MainContainer from '$components/container/MainContainer/MainContainer'

import s from './BaseTostLayout.module.scss'

const BaseTostLayout: React.FC<
  PropsWithChildren<{
    visible: boolean
  }>
> = ({ children, visible }) => (
  <div
    className={classNames(s['tost-wrapper'], {
      [s['visible']]: visible,
      [s['hidden']]: !visible,
    })}
  >
    <MainContainer>
      <div className={s['tost-content']}>{children}</div>
    </MainContainer>
  </div>
)

export default BaseTostLayout
