import React, { useEffect, useRef, ReactNode } from 'react'

import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'
// import SimpleBar from 'simplebar-react'

import SimpleBar from 'simplebar-react'

import { PopupConfig } from '$store/slices/popup/types'

import CloseIcon from '../../icons/close/CloseIcon'

export interface BasePopupLayoutProps {
  hidePopup: () => void
  className?: string
  config: PopupConfig
  children: ReactNode
  isVisible: boolean
}

const BasePopupLayout = ({
  hidePopup,
  className,
  config,
  children,
  isVisible,
}: BasePopupLayoutProps) => {
  const contentRef = useRef<HTMLDivElement>(null)

  const disableScroll = () => {
    // document.body.style.overflow = 'hidden'
  }

  const enableScroll = () => {
    // document.body.style.overflow = ''
  }

  useEffect(() => {
    if (isVisible) {
      disableScroll()
    }
    if (!isVisible) {
      setTimeout(() => {
        enableScroll()
      }, 200)
    }
  }, [isVisible])

  useEffect(() => {
    setTimeout(() => contentRef.current?.focus())
  }, [])

  return (
    <CSSTransition
      in={isVisible}
      unmountOnExit
      mountOnEnter
      classNames="popup-transition"
      addEndListener={(node, listener) =>
        node.addEventListener('transitionend', listener)
      }
    >
      <div
        onMouseDown={hidePopup}
        className={classNames('popup-wrapper', className)}
      >
        <SimpleBar
          onMouseDown={(e) => {
            e.stopPropagation()
          }}
          className={`popup popup_${config.type}`}
        >
          <div className="popup__close-icon">
            <CloseIcon onClick={hidePopup} />
          </div>
          <div ref={contentRef} tabIndex={1} className="popup-content">
            {children}
          </div>
        </SimpleBar>
      </div>
    </CSSTransition>
  )
}

export default BasePopupLayout
