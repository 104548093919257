import React from 'react'

import classNames from 'classnames'

import classes from './Label.module.scss'

const Label: React.FC<{ label: string; className?: string }> = ({
  label,
  className,
}) => <span className={classNames(classes['label'], className)}>{label}</span>

export default Label
