import withFingerprint from "$api/fingerprint/index";

export default function withClientJs() {
  withFingerprint(() => new Promise((resolve, reject) => {
    try {
      // eslint-disable-next-line global-require
      const { ClientJS } = require('clientjs');
      const client = new ClientJS();
      const fingerprint = client.getFingerprint();

      resolve(fingerprint);
    } catch (error) {
      reject(error)
    }
  }), 'cj');
}
