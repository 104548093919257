import { InputActionMeta } from 'react-select'
import { Subject } from 'rxjs'

const searchHandlerWrapper =
  (subj: Subject<unknown>) =>
  (value: string, { action, prevInputValue }: InputActionMeta) => {
    if (action === 'input-change') {
      subj.next(value)
    }
    if (
      (action === 'menu-close' || action === 'set-value') &&
      value !== prevInputValue
    ) {
      subj.next('')
    }
  }

export default searchHandlerWrapper
