import { FormikHelpers } from 'formik'
import { useDispatch } from 'react-redux'

import { showPopup } from '$store/slices/popup'
import { PopupConfig } from '$store/slices/popup/types'

import { SubmitHandlerType } from '../../components/form/types/types'
import fieldErrorMiddleware from '../../utils/middleware/fieldErrorMiddleware'
import createPopupErrorMiddleware from '../../utils/middleware/popupErrorMiddleware'

type FieldsValueType = Record<string, string | number | boolean | null>

type ValueType<T> = FieldsValueType & T

type MiddlewareType<T> = (
  value: ValueType<T>,
  helper: FormikHelpers<T>,
) => ValueType<T> | undefined

type MiddlewaresArrayType<T> = MiddlewareType<T>[]

const middlewareHandler = async <T extends FieldsValueType>(
  middleware: MiddlewaresArrayType<T>,
  value: T,
  helper: FormikHelpers<T>,
) => {
  let middleValue
  for (const func of middleware) {
    const result = func(middleValue || value, helper)
    if (result) {
      middleValue = result
    } else {
      throw 'Error'
    }
  }
  return middleValue || value
}

const useFormSubmitHandler = <T extends FieldsValueType>(
  middleware: MiddlewaresArrayType<T>,
  func: (value: T, helper: FormikHelpers<T>) => Promise<any>,
) => {
  const dispatch = useDispatch()
  const dispatchShowPopup = (config: PopupConfig) => {
    dispatch(showPopup(config))
  }
  const SubmitHandlerCreator = (field: MiddlewareType<T>) => {
    const SubmitHandler: SubmitHandlerType<T> = async (values, helper) => {
      const { setFieldError, setSubmitting } = helper
      setSubmitting(false)
      const computedValue = await middlewareHandler(
        [...middleware, field],
        values,
        helper,
      )
      setSubmitting(true)
      await func(computedValue, helper)
        .catch(fieldErrorMiddleware(setFieldError))
        .catch(createPopupErrorMiddleware(dispatchShowPopup))
        .catch((e) => console.log(e))
        .finally(() => {
          setSubmitting(false)
        })
    }

    return SubmitHandler
  }

  return SubmitHandlerCreator
}

export default useFormSubmitHandler
