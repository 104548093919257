function getCookie(name: string, cookie: string | undefined) {
  if (cookie) {
    const matches = cookie.match(
      new RegExp(
        `(?:^|; )${name.replace(
          /([\.$?*|{}\(\)\[\]\\\/\+^])/g,
          '\\$1',
        )}=([^;]*)`,
      ),
    )
    return matches ? decodeURIComponent(matches[1]) : undefined
  }
}

export default getCookie
