import { DictionaryType } from '$hooks/useLanguageDictionary/useLanguageDictionary'

const isValidMiddleName =
  (dictionary: DictionaryType) => (name: string) => (value: string) => {
    if (value) {
      // if (!/^[а-яёА-ЯЁ]+-?[а-яёА-ЯЁ]+$/g.test(value)) {
      if (!/^[а-яёА-ЯЁ\s]+$/g.test(value)) {
        return `${dictionary.errors.isValidName[0]} ${name} ${dictionary.errors.isValidName[2]}`
      }
    }
  }

export default isValidMiddleName
