import { ReactNode } from 'react'

import ConfigErrorTrap from './ConfigErrorTrap'
import PromoEndTrap from './PromoEndTrap'
import PromoStubTrap from './PromoStubTrap'

export default function PromoTrap({ children }: { children: ReactNode }) {
  return (
    <ConfigErrorTrap>
      <PromoStubTrap>
        <PromoEndTrap>{children}</PromoEndTrap>
      </PromoStubTrap>
    </ConfigErrorTrap>
  )
}
