import { decode } from 'html-entities'
import Head from 'next/head'
import { useRouter } from 'next/router'

import { useAppSelector } from '$store/hooks'

import usePromoNameTemplate from '$hooks/usePromoNameTemplate'

export interface IPreviewImage {
  url: string
  width?: number
  height?: number
}

export interface MetaTagsProps {
  title: string
  preview?: string | IPreviewImage | null
  description?: string | null
  keywords?: string | null
  logoAsFallback?: boolean
  rawTitle?: boolean
}

export default function MetaTags({
  title,
  preview,
  description,
  keywords,
  logoAsFallback,
  rawTitle,
}: MetaTagsProps) {
  const router = useRouter()

  const promoNameTitle = usePromoNameTemplate(title)
  const pageTitle = rawTitle ? title : promoNameTitle

  const referalSharingLink = useAppSelector(
    (state) => state.config.referral_program?.sharingLink,
  )

  const inviteSharingLink = useAppSelector(
    (state) => state.config.motivational_program?.sharingLink,
  )

  const referralQuery = referalSharingLink?.queryParamName
    ? referalSharingLink?.queryParamName
    : 'referral_invite_code'

  const inviteQuery = inviteSharingLink?.queryParamName
    ? inviteSharingLink?.queryParamName
    : 'invite'
  const { [referralQuery]: referral, [inviteQuery]: invite } = router.query

  const name = useAppSelector((state) => state.config.promo.name_readable)
  const defaultPreview = useAppSelector(
    (state) => state.config.promo.content.preview,
  )
  const logo = useAppSelector((state) => state.config.promo.content.logo)

  const {
    url: previewImage,
    width: previewWidth,
    height: previewHeight,
  } = (
    typeof preview === 'string'
      ? { url: preview }
      : preview ??
        (logoAsFallback
          ? { url: logo }
          : { url: defaultPreview, width: 1200, height: 630 })
  ) as IPreviewImage

  const usingLogo = previewImage === logo

  const currentPageTitle =
    referral && referalSharingLink?.title
      ? referalSharingLink.title
      : invite && inviteSharingLink?.title
      ? inviteSharingLink.title
      : pageTitle

  const currentPageDescription =
    referral && referalSharingLink?.description
      ? referalSharingLink.description
      : invite && inviteSharingLink?.description
      ? inviteSharingLink.description
      : description

  const currentPreview =
    referral && referalSharingLink?.preview
      ? referalSharingLink.preview
      : invite && inviteSharingLink?.preview
      ? inviteSharingLink.preview
      : previewImage

  return (
    <Head>
      <meta
        name="twitter:card"
        content={usingLogo ? 'summary' : 'summary_large_image'}
      />
      <>
        <title>{decode(currentPageTitle)}</title>
        <meta property="og:title" content={decode(currentPageTitle)} />
        <meta property="og:type" content="promotion" />
        <meta name="twitter:title" content={decode(currentPageTitle)} />
      </>
      {!!currentPreview && (
        <>
          <meta property="og:image" content={currentPreview} />
          <meta property="vk:image" content={currentPreview} />
          <meta property="twitter:image" content={currentPreview} />
        </>
      )}
      {previewWidth !== undefined && previewHeight !== undefined && (
        <>
          <meta property="og:image:width" content={String(previewWidth)} />
          <meta property="og:image:height" content={String(previewHeight)} />
        </>
      )}
      {!!currentPageDescription && (
        <>
          <meta name="description" content={decode(currentPageDescription)} />
          <meta
            property="og:description"
            content={decode(currentPageDescription)}
          />
          <meta
            property="twitter:description"
            content={decode(currentPageDescription)}
          />
        </>
      )}
      {!!name && <meta property="og:site_name" content={decode(name)} />}
      <meta property="og:url" content={`/${router.asPath}`} />
      {!!keywords && <meta name="keywords" content={keywords} />}
    </Head>
  )
}
