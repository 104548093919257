import React, { useEffect, useState } from 'react'

// TYPE
import classNames from 'classnames'
import { useDispatch } from 'react-redux'

import { showPopup } from '$store/slices/popup'

import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'

import ManualUploadCheck from '$components/form/formik/forms/uploadCheck/ManualUploadForm/ManualUploadCheckForm'

import classes from './ManualUploadCheckPopup.module.scss'
import s from '../checkUploadCommon.module.scss'

const ManualUploadCheckPopup: React.FC = () => {
  const [isNotHaveMediaDevice, setNoMediaDevice] = useState(true)

  useEffect(() => {
    try {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          stream.getTracks().forEach((track) => track.stop())
          setNoMediaDevice(false)
        })
        .catch(() => setNoMediaDevice(true))
    } catch (error) {
      setNoMediaDevice(true)
    }
  }, [])

  const dispatch = useDispatch()
  const dictionary = useLanguageDictionary()

  const toPhoto = () =>
    dispatch(
      showPopup({
        popupId: 'upload_photo_check',
      }),
    )

  const toQr = async () => {
    dispatch(
      showPopup({
        popupId: 'upload_qr_check',
      }),
    )
  }
  return (
    <div className={classNames('form-popup', classes['wrapper'])}>
      <h2 className="popup__title popup__title_md">
        {dictionary.header.handInsertCheck}
      </h2>
      <ManualUploadCheck />
      <button className={s['buttonLink']} onClick={toPhoto}>
        Загрузка фото
      </button>
      <button
        className={s['buttonLink']}
        disabled={isNotHaveMediaDevice}
        onClick={toQr}
      >
        Сканирование QR-кода
      </button>
    </div>
  )
}

export default ManualUploadCheckPopup
