import parse from 'html-react-parser'

const ParseInsertHeadHtml = (siteHtml: string) => {
  const parseCode = parse(siteHtml, {
    replace: (domNode: any) => {
      if (domNode.name === 'noscript') {
        return (
          <noscript>
            Пожалуйста, включите исполнение скриптов в вашем браузере
          </noscript>
        )
      }
      return domNode
    },
  })
  return parseCode
}

export default ParseInsertHeadHtml
