import { DictionaryType } from '$hooks/useLanguageDictionary/useLanguageDictionary'

const notAllowFutureTime = (dictionary: DictionaryType) => (value: string) => {
  const fillDate = new Date(Date.parse(value))
  const nowDate = new Date()

  if (fillDate.getTime() >= nowDate.getTime()) {
    return dictionary.errors.notAllowFutureTime
  }
}

export default notAllowFutureTime
