import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

import { setFullConfig } from '$store/slices/config/thunks/config'
import {
  ChecksType,
  PersonalUserInfoType,
  UserType,
  PromocodesType,
} from '$store/slices/config/types/user/userType'
import { signIn, logOut } from '$store/slices/user/thunks/user'

export type UserState = UserType | null

const initialState = null as UserState

/* eslint no-param-reassign: "error" */
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setPersonalData: (
      state,
      { payload }: PayloadAction<PersonalUserInfoType>,
    ) => {
      if (!state) {
        return
      }

      state.personalInfo = payload
    },
    setEditableData: (state, { payload }: PayloadAction<any>) => {
      if (state) {
        return {
          ...state,
          ...payload,
        }
      }
      return state
    },
    setUploadChecks: (state, { payload }: PayloadAction<ChecksType[]>) => {
      if (!state) {
        return
      }

      state.checks = payload
    },
    setUploadPromocodes: (
      state,
      { payload }: PayloadAction<PromocodesType[]>,
    ) => {
      if (!state) {
        return
      }

      state.promocodes = payload
    },
    confirmUserPhone: (state) => ({
      ...state,
      phoneIsVerified: true,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, (_, { payload }) => payload)
    builder.addCase(logOut.fulfilled, () => null)

    builder.addCase(setFullConfig.fulfilled, (_state, { payload }) => {
      const { user } = payload
      return user
    })

    builder.addCase(HYDRATE, (state, action) => {
      const {
        payload: { user },
      } = action as PayloadAction<any>

      if (!user) {
        return null
      }

      return {
        ...state,
        ...user,
      }
    })
  },
})

export const {
  setPersonalData,
  setUploadChecks,
  setUploadPromocodes,
  setEditableData,
  confirmUserPhone,
} = userSlice.actions

export const { name } = userSlice

export const slice = userSlice

export default userSlice.reducer
