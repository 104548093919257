import React, { useCallback } from 'react'

import { useFormikContext } from 'formik'

import RootInput from '$ui/fields/RootInput'

import { TextFormikFieldInterface } from '../../../fields/formikField/type/FormikFieldType'
import FieldWrapperConstructor from '../../constructor/FieldWrapperConstructor/FieldWrapperConstructor'

const TextInputField: React.FC<TextFormikFieldInterface> = (props) => {
  const { name } = props
  const { setFieldValue, setFieldTouched, values } = useFormikContext()
  const value = values as any
  const onChange = useCallback(
    (value: string | number) => {
      setFieldTouched(name)
      setFieldValue(name, value)
    },
    [name, setFieldValue],
  )

  return (
    <FieldWrapperConstructor
      component={RootInput}
      {...{ onChange, value: value[name], ...props }}
    />
  )
}

export default TextInputField
