import { FeedbackFieldsType } from '$store/slices/config/types/feedback/feedbackFields'

import { FieldConfigType } from '$/types/formTypes/field/fieldConfigTypes'
import feedbackFieldName from './feedbackFieldName'

import placeholders from '../placeholders/placeholders'

const feedbackFieldCollection: Record<
  FeedbackFieldsType,
  FieldConfigType<FeedbackFieldsType>
> = {
  email: {
    name: feedbackFieldName.email,
    fieldInputType: 'common',
    placeholder: placeholders['email'],
  },
  name: {
    name: feedbackFieldName.name,
    fieldInputType: 'common',
    placeholder: placeholders['first_name'],
  },
  subject_active: {
    name: feedbackFieldName.subject,
    fieldInputType: 'commonSelect',
    placeholder: placeholders['subject'],
  },
  file: {
    name: feedbackFieldName.file,
    fieldInputType: 'common',
    placeholder: '',
  },
  message: {
    name: feedbackFieldName.message,
    fieldInputType: 'textarea',
    placeholder: placeholders['message'],
  },
  promo_rules: {
    name: feedbackFieldName.promo_rules,
    fieldInputType: 'checkbox',
  },
  receiving_promotional_mailing: {
    name: feedbackFieldName.receiving_promotional_mailing,
    fieldInputType: 'checkbox',
  },
}

export default feedbackFieldCollection
