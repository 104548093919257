import { DictionaryType } from '$hooks/useLanguageDictionary/useLanguageDictionary'

const fileFormat =
  (dictionary: DictionaryType) =>
  (fileFormats: string[]) =>
  (value: FileList) => {
    for (let i = 0; i < value.length; i++) {
      if (!value[i].name) {
        return
      }
      const uploadfileFormat = value[i]?.name.split('.').splice(-1, 1)[0]
      const isAllowFormat = fileFormats.reduce(
        (allow, item) => allow || uploadfileFormat === item,
        false,
      )

      if (!isAllowFormat) {
        return `${dictionary.errors.fileFormat[0]} ${uploadfileFormat} ${dictionary.errors.fileFormat[1]}.`
      }
    }
  }

export default fileFormat
