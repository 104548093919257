import React, { useCallback, useEffect } from 'react'

import { useRouter } from 'next/router'
import { connect, ConnectedProps, useStore } from 'react-redux'

import { hidePopup, showPopup } from '$store/slices/popup'
import { AppDispatch, RootState } from '$store/store'

import useScroll from '$hooks/useScroll/useScroll'
import useUnAuthRedirect from '$hooks/useUnAuthRedirect/useUnAuthRedirect'

import { isPromoEndedFromRootState } from '$utils/promo/isPromoEnded'

import ConfirmEmailPopup from './auth/ConfirmEmail'
import ConfirmPhonePopup from './auth/ConfirmPhone/ConfirmPhonePopup'
import NewPasswordPopup from './auth/NewPassword/NewPassword'
import RestorePassword from './auth/RestorePassword/RestorePassword'
import SignInPopup from './auth/SignIn/SignInPopup'
import SignUpPopup from './auth/SignUp/SignInPopup'
import BasePopupLayout from './BasePopupLayouts/BasePopupLayouts'
import ManualUploadCheckPopup from './checkUpload/ManualUploadCheckPopup/ManualUploadCheckPopup'
import PhotoUploadCheckPopup from './checkUpload/PhotoUploadCheckPopup/PhotoUploadCheckPopup'
import QRUploadCheckPopup from './checkUpload/QRUploadCheckPopup/QRUploadCheckPopup'
import UploadCheckPopup from './checkUpload/UploadCheckPopup/UploadCheckPopup'
import CustomFormPopup from './customFormPopup/CustomFormPopup'
import DefaultPopup from './Default/DefaultPopup'
import FeedbackPopup from './feedback/feedbackPopup'
import ReviewPopup from './review/reviewPopup'

import getParameters from '../../constants/common/getParameters/getParameters'
import PopupsID, { PopupType } from '../../constants/common/popupsID/popupsID'
import useGetParameter from '../../hooks/useGetParameter/useGetParameter'
import { PopupConfig } from '../../store/slices/popup/types'
import CropPassportPopup from './CropPassport'
import ReceiptPopup from './consent/ReceiptPopup'
import TaxConsentPopup from './consent/TaxConsentPopup'

const popups: Record<PopupType, React.FC<any>> = {
  [PopupsID.sign_in]: SignInPopup,
  [PopupsID.sign_up]: SignUpPopup,
  [PopupsID.confirm_phone]: ConfirmPhonePopup,
  [PopupsID.feedback]: FeedbackPopup,
  [PopupsID.default]: DefaultPopup,
  [PopupsID.upload_check]: UploadCheckPopup,
  [PopupsID.upload_manual_check]: ManualUploadCheckPopup,
  [PopupsID.restore_password]: RestorePassword,
  [PopupsID.upload_photo_check]: PhotoUploadCheckPopup,
  [PopupsID.upload_qr_check]: QRUploadCheckPopup,
  [PopupsID.send_review]: ReviewPopup,
  [PopupsID.custom_form]: CustomFormPopup,
  [PopupsID.confirm_email]: ConfirmEmailPopup,
  [PopupsID.new_password]: NewPasswordPopup,
  [PopupsID.crop_passport]: CropPassportPopup,
  [PopupsID.receipt]: ReceiptPopup,
  [PopupsID.consent]: TaxConsentPopup,
}

const PopupManagement = ({
  popupState,
  showPopupAction,
  hidePopupAction,
}: Props) => {
  const popupName = useGetParameter(getParameters.POPUP_ID) as PopupType
  const store = useStore<RootState>()
  const redirect = useUnAuthRedirect()
  const router = useRouter()
  useScroll(popupState.activeState)
  // IF URL GET PARAM CHANGE
  useEffect(() => {
    if (popupName) {
      if (isPromoEndedFromRootState(store.getState())) {
        const { popupid, ...routerQuery } = router.query
        router.replace(
          {
            query: routerQuery,
          },
          undefined,
          { shallow: true },
        )
        return
      }

      const customFormPopup = store
        .getState()
        .config.custom_forms?.forms.find(
          ({ slug }: { slug: any }) => slug === popupName,
        )

      if (customFormPopup) {
        if (customFormPopup.template === 'modal') {
          if (customFormPopup.for_auth_users && !store.getState().user) {
            redirect()
          } else {
            showPopupAction({
              popupId: 'custom_form',
              urlParam: true,
              popupData: customFormPopup,
            })
          }
          return
        }
      }

      // eslint-disable-next-line
      switch (popupName) {
        case PopupsID.sign_in:
        case PopupsID.sign_up:
          if (store.getState().config.auth?.viewTemplate !== 'modal') {
            return
          }
          break
        case PopupsID.feedback:
          if (store.getState().config.feedback?.viewTemplate !== 'modal') {
            return
          }
          break
      }

      if (!popups[popupName]) {
        router.replace('/', undefined, { shallow: true })
        return
      }

      showPopupAction({
        popupId: popupName as PopupType,
        urlParam: true,
      })
    }
  }, [popupName, showPopupAction, store])

  const Popup = popupState.popupConfig.popupId
    ? popups[popupState.popupConfig.popupId] || popups[PopupsID.default]
    : popups[PopupsID.default]

  const hideLocationPopup = useCallback(() => {
    hidePopupAction()

    const [path, rest = ''] = router.asPath.split('?')
    const [search, hash] = rest.split('#')

    const params = new URLSearchParams(search)
    params.delete(getParameters.POPUP_ID)
    const newSearch = params.toString()

    const newPath = `${path}${newSearch ? `?${newSearch}` : ''}${
      hash ? `#${hash}` : ''
    }`

    router.replace(newPath, undefined, {
      shallow: true,
      scroll: false,
    })
  }, [hidePopupAction, router])

  return (
    <BasePopupLayout
      hidePopup={hideLocationPopup}
      isVisible={popupState.activeState}
      config={popupState.popupConfig}
    >
      <Popup config={popupState.popupConfig} />
    </BasePopupLayout>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({
    popupState: state.popup,
  }),
  (dispatch: AppDispatch) => ({
    showPopupAction: (config: PopupConfig) => {
      dispatch(showPopup(config))
    },
    hidePopupAction: () => {
      dispatch(hidePopup())
    },
  }),
)

export default connector(PopupManagement)
