import { DictionaryType } from '$hooks/useLanguageDictionary/useLanguageDictionary'

const isValidEmail = (dictionary: DictionaryType) => (value: string) => {
  if (value) {
    const emailRegExp =
      /^([a-zA-Z0-9_\.-]+)@([a-zA-Z0-9_\.-]+)\.([a-zA-Z\.]{2,6})$/
    // /^\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\s*$/
    if (!emailRegExp.test(value)) {
      return dictionary.errors.isValidEmail
    }
  }
}

export default isValidEmail
