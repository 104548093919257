import { FC, PropsWithChildren, ReactElement, useEffect } from 'react'

import classNames from 'classnames'
// import { useRouter } from 'next/router'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { useDispatch } from 'react-redux'
// import SimpleBar from 'simplebar-react'

import tostID from '$constants/common/tostID'

import { useAppSelector } from '$store/hooks'
import { showTost } from '$store/slices/tost'

import Footer from '$components/layoutParts/Footer/Footer'
import Header from '$components/layoutParts/Header/Header'
import TostManagement from '$components/tosts/TostManagement'

import classes from './DefaultLayout.module.scss'

const DefaultLayout: FC<PropsWithChildren<unknown>> = ({ children }) => {
  // TODO: should I use `asPath` instead of `pathname`?
  // const { pathname } = useRouter()
  const recaptcha = useAppSelector((store) => store.config.site?.recaptcha)
  const headerFixed = useAppSelector((store) => store.config.site?.header_fixed)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!localStorage.getItem(tostID.COOKIE_POLICY)) {
      dispatch(
        showTost({
          id: tostID.COOKIE_POLICY,
        }),
      )
    }
  }, [])

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={
        recaptcha?.version === 'v3' ? recaptcha?.site_key ?? '' : ''
      }
    >
      <TostManagement />
      <main
        id="main-root"
        style={{
          minHeight: '100vh',
          overflowX: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Header />
        <div
          className={classNames(classes['contentContainer'], {
            [classes['isFixedHeader']]: headerFixed,
          })}
          style={{ flexGrow: 1 }}
        >
          {children}
        </div>
        <Footer />
      </main>
    </GoogleReCaptchaProvider>
  )
}

export const getDefaultLayout = (page: ReactElement) => (
  <DefaultLayout>{page}</DefaultLayout>
)

export default DefaultLayout
