import React, { useEffect } from 'react'

import classNames from 'classnames'
import { useRouter } from 'next/router'

import pbApi from '$api/promobuilding/pbApi'

import onlyStringParam from '$utils/onlyStringParam'

import successIcon from '../../Default/icon/succes.svg'

const iconWrapperClasses = classNames('icon-wrapper')
const successIconClasses = classNames('success-icon')

const ConfirmEmailPopup = () => {
  const router = useRouter()

  useEffect(() => {
    const token = onlyStringParam(router.query['token']) ?? ''
    let timeout: number | undefined
    let destroyed = false

    pbApi.confirmEmail(token).then(() => {
      if (!destroyed) {
        timeout = window.setTimeout(() => router.replace('/account/room'), 2500)
      }
    })

    return () => {
      destroyed = true
      window.clearTimeout(timeout)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="popup_success">
      <div className={`${iconWrapperClasses} ${successIconClasses}`}>
        <img src={successIcon} alt="success-icon" />
      </div>
      <h3 className="popup__title popup__title-md">Ваш Email подтвержден</h3>
    </div>
  )
}

export default ConfirmEmailPopup
