import MinusIcon from './_icons/minus.svg?component'
import PlusIcon from './_icons/plus.svg?component'

export const MinusRangeButton: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => (
  <button onClick={onClick}>
    <MinusIcon />
  </button>
)

export const PlusRangeButton: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => (
  <button onClick={onClick}>
    <PlusIcon />
  </button>
)
