import { DictionaryType } from '$hooks/useLanguageDictionary/useLanguageDictionary'

const maxLength =
  (dictionary: DictionaryType) =>
  (max: number) =>
  (value: string = '') => {
    if (value.length > max) {
      return `${dictionary.errors.maxLength} ${max}`
    }
  }

export default maxLength
