import React, { useCallback, useState, useEffect } from 'react'

import { useFormikContext } from 'formik'
import { SingleValue } from 'react-select'

import { SyncSelectFormikFieldInterface } from '$components/form/types/FormikFieldType'
import RootSelect from '$components/ui/selects/RootSelect'

import FieldWrapperConstructor from '../../constructor/FieldWrapperConstructor/FieldWrapperConstructor'

const SyncSelectField: React.FC<SyncSelectFormikFieldInterface> = ({
  ...props
}) => {
  const { name } = props
  const { setFieldValue, values } = useFormikContext()
  const [selectValue, setSelectValue] = useState<{
    label: string
    value: string
  } | null>(null)
  const onChange = useCallback(
    (option: SingleValue<{ label: string; value: string }>) => {
      setFieldValue(name, option?.value)
      setSelectValue(option)
    },
    [name, setFieldValue],
  )

  useEffect(() => {
    if (values) {
      // @ts-ignore
      const formikContextValue = values[name]
      if (!formikContextValue) {
        setSelectValue(formikContextValue)
      }
    }
  }, [values, name])

  return (
    <FieldWrapperConstructor
      component={RootSelect}
      {...{
        onChange,
        value: selectValue,
        isSearchable: false,
        isMulti: false,
        isColor: true,
        ...props,
      }}
    />
  )
}

export default SyncSelectField
