import React from 'react'

import classNames from 'classnames'
import Image from 'next/image'
import { connect, ConnectedProps, useDispatch } from 'react-redux'

import pbApi from '$api/promobuilding/pbApi'

import { useAppSelector } from '$store/hooks'
import { setConfig } from '$store/slices/config'
import { RootState } from '$store/store'

import useTimer from '$hooks/useTimer/useTimer'

import pluralForm from '$utils/dayCaseParser/dayCaseParser'

import MetaTags from '$/other/meta-tags/MetaTags'

import line from './images/line.png'
import logo from './images/logo.svg'
import persons from './images/persons.png'
import present from './images/present.svg'
import sausage from './images/sausage.svg'
import sausages from './images/sausages.svg'
import sun from './images/sun.svg'

import classes from './PlugTimer.module.scss'

const PlugTimer: React.FC<Props> = ({ promoStart }) => {
  const dateStart = new Date(promoStart)
  const dateNow = new Date()
  const datePlug = dateStart.getTime() - dateNow.getTime()
  const [time] = useTimer(Math.floor(datePlug / 1000))

  const dispatch = useDispatch()

  if (time === 0) {
    setTimeout(() => {
      pbApi.getFullConfig().then(({ data: { data } }) => {
        dispatch(setConfig(data))
      })
    }, 1000)
  }

  const day = Math.floor(time / (60 * 60 * 24))
  const hour = Math.floor((time - day * (60 * 60 * 24)) / (60 * 60))
  const minute = Math.floor(
    (time - day * (60 * 60 * 24) - hour * (60 * 60)) / 60,
  )
  const seconds = `0${Math.floor(
    time - day * (60 * 60 * 24) - hour * (60 * 60) - minute * 60,
  )}`.slice(-2)

  const dayLable = pluralForm(day, 'день', 'дня', 'дней')
  const hourLable = pluralForm(hour, 'чаc', 'часа', 'часов')
  const minuteLable = pluralForm(minute, 'минута', 'минуты', 'минут')
  const secondLable = pluralForm(time, 'секунда', 'секунды', 'секунд')

  const description = useAppSelector((state) => state.config.promo.description)

  const minuteRow = `0${minute}`.slice(-2)
  return (
    <>
      <MetaTags title="Главная" description={description} />
      <div className={classes['page']}>
        <div className={classes['wrap']}>
          <div className={classes['content']}>
            <div className={classes['logo']}>
              <Image
                className={classNames(classes['logo-img'], classes['img'])}
                src={logo}
                alt=""
                width={249}
                height={138}
              />
            </div>
            <div className={classes['info']}>
              <h1 className={classes['title']}>
                Кунгурский&nbsp;покупай&nbsp;— призы&nbsp;получай
              </h1>
              <div className={classes['line']}>
                <Image
                  className={classNames(classes['line-img'], classes['img'])}
                  src={line}
                  alt=""
                  width={534}
                  height={23}
                />
              </div>
              <div className={classes['plug']}>
                <p className={classes['head']}>До старта акции осталось:</p>
                <div className={classes['clock']}>
                  <div className={classes['numb']}>
                    <span className={classes['digit']}>{day}</span>
                    <span className={classes['word']}>{dayLable}</span>
                  </div>

                  <div className={classes['numb']}>
                    <span className={classes['digit']}>{hour}</span>
                    <span className={classes['word']}>{hourLable}</span>
                  </div>

                  <div className={classes['numb']}>
                    <span className={classes['digit']}>{minuteRow}</span>
                    <span className={classes['word']}>{minuteLable}</span>
                  </div>

                  <div className={classes['numb']}>
                    <span className={classes['digit']}>{seconds}</span>
                    <span className={classes['word']}>{secondLable}</span>
                  </div>
                </div>
              </div>
              <div className={classes['prize-info']}>
                <p className={classes['prize-title']}>
                  Разыгрываем 3&nbsp;главных приза&nbsp;по:
                </p>
                <p className={classes['prize-sum']}>100 000 ₽</p>
                <p className={classes['prize-text']}>на приятные покупки</p>
              </div>
            </div>
            <div className={classes['images']}>
              <div className={classes['persons']}>
                <div className={classes['present']}>
                  <Image
                    className={classNames(
                      classes['present-img'],
                      classes['img'],
                    )}
                    src={present}
                    alt=""
                    width={143}
                    height={162}
                  />
                </div>
                <Image
                  className={classNames(classes['persons-img'], classes['img'])}
                  src={persons}
                  alt=""
                  width={1076}
                  height={874}
                />
              </div>
              <div className={classes['sun']}>
                <Image
                  className={classNames(classes['sun-img'], classes['img'])}
                  src={sun}
                  alt=""
                  width={321}
                  height={282}
                />
              </div>

              <div className={classes['sausage']}>
                <Image
                  className={classNames(classes['sausage-img'], classes['img'])}
                  src={sausage}
                  alt=""
                  width={83}
                  height={85}
                />
              </div>
              <div className={classes['sausages']}>
                <Image
                  className={classNames(
                    classes['sausages-img'],
                    classes['img'],
                  )}
                  src={sausages}
                  alt=""
                  width={388}
                  height={162}
                />
              </div>
            </div>
          </div>
          <div className={classes['copy-wrap']}>
            <div className={classes['copy']}>
              <p className={classes['copy-text']}>
                Акция проводится с&nbsp;01.05.2024 по&nbsp;30.06.2024. Полная
                информация об&nbsp;организаторе акции, правилах
                ее&nbsp;проведения, количестве призов, сроках, месте
                и&nbsp;порядке их&nbsp;получения находится на&nbsp;сайте
                kungur-promo.ru
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect((state: RootState) => ({
  promoStart: state.config.promo.period.promo_start,
}))

export default connector(PlugTimer)
