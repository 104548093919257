import React from 'react'

import { useAppSelector } from '$store/hooks'

const AgreementRules: React.FC<{
  withConfidentialPolicy?: boolean
}> = ({ withConfidentialPolicy = false }) => {
  const promoRules = useAppSelector((store) => store.config.promo.rules)
  const language = useAppSelector((store) => store.language.language)
  const confidentialPolicy = useAppSelector(
    (store) => store.config.promo.confidentiality_agreement,
  )

  if (language === 'ru') {
    return (
      <div>
        {withConfidentialPolicy ? (
          <>
            Я{`\u00A0`}подтверждаю согласие с{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={promoRules ?? '/rules.pdf'}
            >
              Правилами проекта
            </a>
            , а также{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={confidentialPolicy ?? '/policy.pdf'}
            >
              Политикой конфиденциальности.
            </a>
          </>
        ) : (
          <>
            Я{`\u00A0`}подтверждаю, что{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={promoRules ?? '/rules.pdf'}
            >
              Правила проекта
            </a>{' '}
            мне понятны, и&nbsp;принимаю&nbsp;их.
          </>
        )}
      </div>
    )
  }

  if (language === 'kz') {
    return (
      <div>
        {withConfidentialPolicy ? (
          <>
            Мен{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={promoRules ?? '/rules.pdf'}
            >
              науқан ережелерімен
            </a>
            , сондай-ақ{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={confidentialPolicy ?? '/policy.pdf'}
            >
              құпиялылық саясатымен
            </a>{' '}
            келісетінімді растаймын
          </>
        ) : (
          <>
            Мен{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={promoRules ?? '/rules.pdf'}
            >
              акция ережелерімен
            </a>{' '}
            маған түсінікті екенін растаймын және оларды.
          </>
        )}
      </div>
    )
  }

  return <></>
}

export default AgreementRules
