import { useEffect, useState } from 'react'

import { useFormikContext } from 'formik'
import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'

import pbApi from '$api/promobuilding/pbApi'

import { useAppSelector } from '$store/hooks'
import { setConfirmInviteCode } from '$store/slices/config'

import useFormSubmitHandler from '$hooks/useFormSubmitHandler/useFormSubmitHandler'
import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'
import useValidators from '$hooks/useValidators/useValidators'

import FormikConstructor from '$components/form/formik/constructor/FormConstructor/formikConstructor'
import TextInputField from '$components/form/formik/fields/TextInputField'
import validatorCombiner from '$components/form/validators/validatorCombiner'
import Preloader from '$components/layoutParts/Preloader/Preloader'

const InviteCodeForms = () => {
  const dictionary = useLanguageDictionary()
  const dispatch = useDispatch()
  const router = useRouter()

  const inviteSharingLink = useAppSelector(
    (state) => state.config.motivational_program?.sharingLink,
  )

  const inviteQuery = inviteSharingLink?.queryParamName
    ? inviteSharingLink?.queryParamName
    : 'invite'
  const { [inviteQuery]: invite } = router.query

  const inviteCode = invite

  const initialValues = {
    motivational_invite_code: typeof inviteCode === 'string' ? inviteCode : '',
  }
  const [isFirstSubmit, setIsFirstSubmit] = useState(true)

  const onSubmit = useFormSubmitHandler<typeof initialValues>([], (value) =>
    pbApi
      .confirmInviteCode(value)
      .then(() => {
        dispatch(setConfirmInviteCode(value.motivational_invite_code))
      })
      .finally(() => {
        setIsFirstSubmit(false)
      }),
  )

  const { isRequired, latinAndDigit } = useValidators()

  const InviteFormChildren = () => {
    const { submitForm } = useFormikContext()

    useEffect(() => {
      if (inviteCode) {
        submitForm()
      }
    }, [inviteCode])

    return (
      <TextInputField
        placeholder={dictionary.fields.inviteCode}
        validate={validatorCombiner([isRequired, latinAndDigit])}
        name="motivational_invite_code"
      />
    )
  }

  return (
    <>
      {invite && isFirstSubmit && <Preloader />}
      <div
        style={{
          overflow: 'hidden',
          height: invite && isFirstSubmit ? '0' : 'auto',
        }}
      >
        <FormikConstructor
          submitButtonName={dictionary.button.confirm}
          initialValues={initialValues}
          onSubmit={onSubmit}
          name="inviteCode"
        >
          <InviteFormChildren />
        </FormikConstructor>
      </div>
    </>
  )
}

export default InviteCodeForms
