import { dictionary } from '$hooks/useLanguageDictionary/useLanguageDictionary'

const latinAndDigits = (dictionary: dictionary) => (value: string) => {
  if (value) {
    if (!/^[A-Za-z0-9-]+$/g.test(value)) {
      return `${dictionary.errors.wrongFormat}`
    }
  }
}

export default latinAndDigits
