import { useDispatch } from 'react-redux'

import tostID from '$constants/common/tostID'

import { hideTost } from '$store/slices/tost'

import Button from '$components/ui/buttons/RootButton/Button'

import s from './CookiePolicy.module.scss'

const CookiePolicy = () => {
  const dispatch = useDispatch()

  return (
    <div>
      <p className={s['text']}>
        Сайт использует cookie, что позволяет получать информацию о&nbsp;вас.
        Это нужно, чтобы улучшать сайт. Продолжая пользоваться сайтом,
        вы&nbsp;соглашаетесь с&nbsp;использованием cookie и&nbsp;предоставления
        их&nbsp;сторонним партнерам. Вам нужно принять это либо покинуть сайт.
      </p>
      <div className={s['btn-container']}>
        <Button
          onClick={() => {
            localStorage.setItem(tostID.COOKIE_POLICY, 'true')
            dispatch(hideTost())
          }}
        >
          Принять
        </Button>
      </div>
      <div style={{ display: 'flex' }}>
        <a
          target="_blank"
          rel="noreferrer"
          style={{
            margin: '15px auto 0',
            textAlign: 'center',
          }}
          href="/cookie-policy.pdf"
        >
          Политика cookie
        </a>
      </div>
    </div>
  )
}

export default CookiePolicy
