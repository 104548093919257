const button = {
  signUp: 'Зарегистрироваться',
  signIn: 'Войти',
  logOut: 'Выйти',
  account: 'Личный\u00A0кабинет',
  checkUpload: 'Загрузить чек',
  codeUpload: 'Загрузить код',
  photoUpload: 'Загрузить фото',
  handInsert: 'Ручной ввод',
  qrScan: 'Сканировать QR',
  sendReview: 'Оставить отзыв',
  send: 'Отправить',
  upload: 'Загрузить',
  changeFile: 'Выбрать файл',
  change: 'Изменить',
  restore: 'Восстановить пароль',
  changePassword: 'Изменить пароль',
  confirmPhoneNumber: 'Подтвердить номер',
  more: 'Подробнее',
  showMore: 'Показать еще',
  readNews: 'Читать статью',
  subscribe: 'Подписать',
  fix: 'Исправить',
  confirm: 'Подтвердить',
  accept: 'Принять',
  retry: 'Попробовать еще',
  signed: 'Подписать',
}

export default button
