import React, {
  FC,
  useCallback,
  useContext,
  useMemo,
  useState,
  useRef,
  PropsWithChildren,
} from 'react'

import classNames from 'classnames'
import Select, {
  CSSObjectWithLabel,
  MultiValue,
  OptionProps,
  SelectInstance,
  ValueContainerProps,
} from 'react-select'
import SimpleBar from 'simplebar-react'

import { ViewportContext } from '$services/ViewportServices/ViewportServices'

import { ClearIcon } from './icon/ClearIcon'
import { DropDownIcon } from './icon/DropDownIcon'
import { DropDownIconBig } from './icon/DropDownIconBig'
import {
  controlStaticStyle,
  inputStaticStyle,
  menuListStaticStyle,
  menuStaticStyle,
  multiValueRemoveStaticStyle,
  multiValueStaticStyle,
  optionStaticStyle,
  singleValueStaticStyle,
  valueContainerStaticStyle,
} from './styles'
import { RootSelectInterface, SelectOptionType } from './types'

import s from './RootSelect.module.scss'

const RenderScrollbar: FC<PropsWithChildren> = ({ children }) => (
  <SimpleBar scrollbarMaxSize={145} style={{ maxHeight: 204 }}>
    {children}
  </SimpleBar>
)

const color = {
  gray: '#E4E8EE',
  gray_light: '#E8ECF3',
  gray_dark: '#9DA6BA',
}

const RootSelect: FC<
  RootSelectInterface & {
    isColored?: boolean
  }
> = ({
  stickyBottomSide = false,
  stickyTopSide = false,
  stickyLeftSide = false,
  stickyRightSide = false,
  label,
  placeholder,
  size = 'auto',
  isColored = false,
  isColor,
  ...props
}) => {
  const { isMobile } = useContext(ViewportContext)
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [placeholderShown, setPlaceholderShown] = useState(true)

  const onTouch = (e: string) => {
    if (!isDirty) {
      setIsDirty(true)
    }

    if (e.length > 0) {
      setPlaceholderShown(false)
    } else {
      setPlaceholderShown(true)
    }
  }
  const height = useMemo(
    () =>
      (size === 'large' && 60) ||
      (size === 'default' && (isMobile ? 45 : 50)) ||
      (size === 'default' && (isMobile ? 45 : 50)) ||
      (size === 'middle' && 48) ||
      (size === 'small' && 35) ||
      (size === 'auto' && 50) ||
      (size === 'auto' && 50) ||
      '100%',
    [size, isMobile],
  )

  const containerStyle = useCallback(
    (baseStyle: CSSObjectWithLabel) => ({
      ...baseStyle,
      height,
    }),
    [height],
  )

  const controlStyle: any = useCallback(
    (baseStyle: CSSObjectWithLabel, state: any) => ({
      ...baseStyle,
      ...controlStaticStyle,
      backgroundColor: props.isDisabled ? 'white' : isColor ? 'white' : 'white',
      padding:
        (props.isMulti || size === 'large') && !isMobile
          ? '0 30px'
          : '0 9px 0 15px',
      borderTopLeftRadius: stickyLeftSide || stickyTopSide ? 0 : 8,
      borderTopRightRadius: stickyRightSide || stickyTopSide ? 0 : 8,
      borderBottomRightRadius:
        stickyRightSide || stickyBottomSide || state.menuIsOpen ? 0 : 8,
      borderBottomLeftRadius:
        stickyLeftSide || stickyBottomSide || state.menuIsOpen ? 0 : 8,
      fontSize: size === 'small' ? 12 : 14,
      color,
      border: `1px solid rgba(188, 149, 92, 1) !important`,
      borderBottom: state.menuIsOpen
        ? 'none !important'
        : isColored
        ? '2px solid rgba(170, 24, 44, 1) !important'
        : `1px solid rgba(188, 149, 92, 1) !important`,
      height,
      ...(isColored
        ? {
            backgroundColor: 'rgba(255, 224, 76, 1)',
            border: '2px solid rgba(170, 24, 44, 1)',
            borderBottom: state.menuIsOpen
              ? 'none !important'
              : '2px solid rgba(170, 24, 44, 1) !important',
            ':hover': {
              borderColor: 'rgba(170, 24, 44, 1)',
            },
            height: isMobile ? '50px' : '50px',
            maxWidth: 280,
          }
        : {}),
    }),
    [
      props.isDisabled,
      props.isMulti,
      isColor,
      size,
      isMobile,
      stickyLeftSide,
      stickyTopSide,
      stickyRightSide,
      stickyBottomSide,
      height,
      isColored,
    ],
  )

  const valueContainerStyle = useCallback(
    (
      baseStyle: CSSObjectWithLabel,
      state: ValueContainerProps<SelectOptionType, true, any>,
    ) => {
      const value = state.selectProps.value as MultiValue<SelectOptionType>
      const input = state.selectProps.inputValue
      // const isOpen = state.selectProps.menuIsOpen
      const dynamicStyleWithLabel =
        (!!label && value) || !placeholder
          ? {
              paddingTop: 2,
            }
          : {}

      return {
        ...baseStyle,
        ...valueContainerStaticStyle,
        ...dynamicStyleWithLabel,
        paddingTop: '16px',
        // opacity: 0.5,
        '::before': {
          content:
            value && value.length > 10 && !input
              ? `Выбрано (${value ? value.length : ''})`
              : 'none',
          ...valueContainerStaticStyle['::before'],
        },
      }
    },
    [label, placeholder],
  )

  const multiValueStyle = useCallback(
    (baseStyle: CSSObjectWithLabel, state: any) => {
      const value = state.selectProps.value as MultiValue<SelectOptionType>
      return {
        ...baseStyle,
        ...multiValueStaticStyle,
        display: value.length > 1 ? 'none' : 'flex',
      }
    },
    [],
  )

  const multiValueRemoveStyle = useCallback(
    (baseStyle: CSSObjectWithLabel) => ({
      ...baseStyle,
      ...multiValueRemoveStaticStyle,
      path: {
        fill: `${color.gray}`,
        ...multiValueRemoveStaticStyle.path,
      },
      ':hover': {
        path: {
          fill: `${color.gray}`,
        },
      },
      ':active': {
        path: {
          fill: `${color.gray_light}`,
        },
      },
    }),
    [],
  )

  const dropdownIndicatorStyle = useCallback(
    (baseStyle: CSSObjectWithLabel, state: any) => ({
      ...baseStyle,
      transform: `rotate(${state.isFocused ? '180deg' : '0deg'})`,
    }),
    [],
  )

  const clearIndicatorStyle = useCallback(
    (baseStyle: CSSObjectWithLabel) => ({
      ...baseStyle,
      backgroundColor: 'transparent',
      path: {
        fill: color.gray,
      },
      ':hover': {
        path: {
          fill: color.gray,
        },
      },
    }),
    [],
  )

  const singleValueStyle = useCallback(
    (baseStyle: CSSObjectWithLabel) => ({
      ...baseStyle,
      ...singleValueStaticStyle,
      color: isColored ? 'rgba(170, 24, 44, 1)' : 'black',
    }),
    [],
  )

  const placeholderStyle = useCallback(
    () => ({
      display: 'none',
    }),
    [],
  )

  const inputStyle = useCallback(
    (baseStyle: CSSObjectWithLabel, state: any) => {
      const isValue = state.getValue().length > 0

      return {
        ...baseStyle,
        ...inputStaticStyle,
        padding: 0,
        margin: 0,
        caretColor: 'black',
        opacity: 1,
        marginBottom: props.isSearchable ? (isValue ? 0 : 8) : 0,
      }
    },
    [props.isSearchable],
  )

  const menuStyle = useCallback(
    (baseStyle: CSSObjectWithLabel) => ({
      ...baseStyle,
      boxShadow: 'none',
      // border: `0px solid ${color.gray}`,
      ...menuStaticStyle,
      border: isColored
        ? '2px solid rgba(170, 24, 44, 1)'
        : `1px solid rgba(188, 149, 92, 1)`,
      borderTop: 'none !important',
      maxHeight: 204,
    }),
    [],
  )

  const menuListStyle = useCallback(
    (baseStyle: CSSObjectWithLabel) => ({
      ...baseStyle,
      ...menuListStaticStyle,
      backgroundColor: 'white',
      '::-webkit-scrollbar-thumb': {
        ...menuListStaticStyle['::-webkit-scrollbar-thumb'],
      },
      border: `1px solid rgba(188, 149, 92, 1)`,
      borderTop: 'none',
      maxHeight: 204,
    }),
    [],
  )

  const optionStyle = useCallback(
    (
      baseStyle: CSSObjectWithLabel,
      state: OptionProps<SelectOptionType, true, any>,
    ) =>
      // const defaultBgColor = isColor ? color.gray : 'white'
      ({
        ...baseStyle,
        ...optionStaticStyle,
        fontSize: 14,
        padding: `16.6px 15px`,
        borderRight: isColored ? 'none' : '1px solid #e4e8ee',
        color: isColored ? 'rgba(170, 24, 44, 1)' : 'black',
        backgroundColor: isColored ? 'rgba(255, 224, 76, 1)' : 'white',
        marginTop: '0 !important',
        borderTop: isColored ? '2px solid rgba(170, 24, 44, 1)' : 'none',
        ':active': {
          backgroundColor: color.gray_dark,
        },
        '::before': {
          ...optionStaticStyle['::before'],
          content: props.isMulti ? "''" : 'none',
          borderColor: 'rgba(177, 177, 177, 1)',
          backgroundColor: state.isSelected ? '#56BCF4' : 'white',
          backgroundImage: state.isSelected
            ? `url("data:image/svg+xml,%3Csvg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.34133 0.223211C8.64849 0.520475 8.64807 1.00204 8.34041 1.29881L3.69713 5.77768C3.38983 6.07411 2.89213 6.07411 2.58483 5.77768L0.23102 3.50721C-0.0766452 3.21044 -0.0770591 2.72888 0.230095 2.43162C0.53725 2.13435 1.03566 2.13395 1.34332 2.43072L3.14098 4.16473L7.2281 0.222317C7.53577 -0.0744542 8.03418 -0.0740544 8.34133 0.223211Z' fill='white'/%3E%3C/svg%3E");`
            : 'none',
        },
        ':hover': {
          backgroundColor: isColored
            ? 'rgba(170, 24, 44, 1)'
            : 'rgba(188, 149, 92, 1)',
          color: 'white',
        },
      }),
    [isColor, props.isMulti],
  )

  const ref = useRef<SelectInstance>(null)
  const [, /* isMenuOpen */ setIsMenuOpen] = useState(false)

  const isHaveValue =
    Number(ref.current?.getValue().length) > 0 || (isColored && props.value)
  const isCursorActive = props.isSearchable && ref.current?.state.isFocused
  const isSearchInputHaveValue = ref.current?.inputRef?.value

  const placeholderClasses = classNames(
    isColored && s['placeholder-colored'],
    s['placeholder'],
    !label &&
      /* isMenuOpen ||  */ (isHaveValue || isCursorActive
        ? s['top']
        : s['center']),
    label && s['center'],
    label &&
      (isHaveValue || !placeholderShown || isSearchInputHaveValue) &&
      s['hide'],
  )

  return (
    <div className={classNames(s['wrapper'], isColored && s['colored'])}>
      <div className={placeholderClasses}>{placeholder}</div>
      <Select
        // @ts-ignore
        ref={ref}
        classNamePrefix="promo"
        closeMenuOnSelect={!props.isMulti}
        hideSelectedOptions={false}
        loadingMessage={() => 'Поиск..'}
        // https://github.com/JedWatson/react-select/issues/4455
        blurInputOnSelect={false}
        noOptionsMessage={() => 'Не найдено'}
        isClearable={props.isMulti}
        placeholder=""
        instanceId={props.name}
        components={{
          IndicatorSeparator: null,
          DropdownIndicator: isColored ? DropDownIconBig : DropDownIcon,
          ClearIndicator: ClearIcon,
          MenuList: RenderScrollbar,
        }}
        styles={{
          container: containerStyle,
          control: controlStyle,
          valueContainer: valueContainerStyle,
          multiValue: multiValueStyle,
          multiValueRemove: multiValueRemoveStyle,
          dropdownIndicator: dropdownIndicatorStyle,
          clearIndicator: clearIndicatorStyle,
          singleValue: singleValueStyle,
          input: inputStyle,
          menu: menuStyle,
          menuList: menuListStyle,
          option: optionStyle,
          placeholder: placeholderStyle,
        }}
        onInputChange={onTouch}
        onMenuOpen={() => {
          setIsMenuOpen(true)
        }}
        onMenuClose={() => {
          setIsMenuOpen(false)
        }}
        openMenuOnFocus
        menuShouldScrollIntoView={false}
        {...props}
        // menuIsOpen
      />
    </div>
  )
}

export default RootSelect
