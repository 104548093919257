import React, { useMemo } from 'react'

import { connect, ConnectedProps } from 'react-redux'

import pbApi from '$api/promobuilding/pbApi'

import feedbackFieldCollection from '$constants/common/forms/feedback/feedbackFieldConstant'
import feedbackFieldName from '$constants/common/forms/feedback/feedbackFieldName'

import { showPopup } from '$store/slices/popup'
import { PopupConfig } from '$store/slices/popup/types'
import { AppDispatch, RootState } from '$store/store'

import useFormSubmitHandler from '$hooks/useFormSubmitHandler/useFormSubmitHandler'
import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'
import useValidators from '$hooks/useValidators/useValidators'

import PromotionalMailing from '$components/form/fields/other/confirmation/PromotionalMailing'
import FormikConstructor from '$components/form/formik/constructor/FormConstructor/formikConstructor'
import CheckBoxField from '$components/form/formik/fields/CheckBoxField'
import SyncSelectField from '$components/form/formik/fields/SynchSelectField/SynchSelectField'
import TextInputField from '$components/form/formik/fields/TextInputField'
import initializeValue from '$components/form/formik/utils/InitializeFormikValues'
import validatorCombiner from '$components/form/validators/validatorCombiner'

import ImageUploadFileField from '$form/fields/formikField/field/ImageUploadField/ImageUploadField'
import TextareaField from '$form/fields/formikField/field/TextareaField/TextareaField'
import AgreementRules from '$form/fields/other/confirmation/AgreementRules'

const FeedbackForm: React.FC<Props> = ({
  feedbackConfig,
  helpFeedback,
  user,
  showPopupAction,
}) => {
  const {
    isRequired,
    minLength,
    maxLength,
    isValidEmail,
    isValidName,
    isRequiredCheckbox,
  } = useValidators()
  const dictionary = useLanguageDictionary()
  const { feedbackFields, feedbackSubjects } = feedbackConfig ?? helpFeedback
  const feedbackName = useMemo(
    () =>
      feedbackFields.find((el) => el === feedbackFieldName.name)
        ? { [feedbackFieldName.name]: user?.first_name ? user.first_name : '' }
        : {},
    [feedbackFields, user],
  )
  const initialValues = useMemo(
    () =>
      initializeValue(
        [
          feedbackFieldName.email,
          feedbackFieldName.message,
          feedbackFieldName.promo_rules,
          ...feedbackFields,
        ],
        {
          [feedbackFieldName.email]: user?.email ? user.email : '',
          ...feedbackName,
        },
      )(),
    [feedbackFields, feedbackName, user?.email],
  )

  const sendFeedback = useFormSubmitHandler<typeof initialValues>(
    [],
    (value, { resetForm }) => {
      const formData: FormData = new FormData()
      Object.entries(value).forEach(([k, v]) => {
        if (k === feedbackFieldName.file) {
          for (let i = 0; i < v.length; i++) {
            formData.append(`${k}[]`, v[i])
          }
          return
        }
        formData.append(k, v)
      })

      return pbApi.sendFeedBack(formData).then(() => {
        resetForm()
        showPopupAction({
          type: 'success',
          title: dictionary.messages.sendFeedBack,
        })
      })
    },
  )

  const subjOptions = useMemo(
    () =>
      Object.keys(feedbackSubjects).map((item) => ({
        label: item,
        value: item,
      })),
    [feedbackSubjects],
  )

  const isRequiredPromotionMailing = initialValues.hasOwnProperty(
    'receiving_promotional_mailing_acceptance_is_required',
  )
    ? isRequiredCheckbox(dictionary.errors.isRequiredCheckbox.media)
    : undefined

  return (
    <FormikConstructor
      name="feedback"
      onSubmit={sendFeedback}
      initialValues={initialValues}
      submitButtonName={dictionary.button.send}
      dataTest="send-feedback-button"
    >
      <TextInputField
        name={feedbackFieldCollection.email.name}
        placeholder={dictionary.fields.email}
        type={feedbackFieldCollection.email.type}
        validate={validatorCombiner([isRequired, isValidEmail, maxLength(255)])}
        data-test="email-input"
      />
      {initialValues.hasOwnProperty(feedbackFieldCollection.name.name) && (
        <TextInputField
          name={feedbackFieldCollection.name.name}
          placeholder={dictionary.fields.first_name}
          validate={validatorCombiner([
            isRequired,
            minLength(2),
            maxLength(25),
            isValidName('имя'),
          ])}
          data-test="first-name-input"
        />
      )}
      {initialValues.hasOwnProperty('subject_active') && (
        <SyncSelectField
          name={feedbackFieldCollection.subject_active.name}
          options={subjOptions}
          placeholder={dictionary.fields.subject}
          validate={validatorCombiner([isRequired])}
        />
      )}
      <TextareaField
        name={feedbackFieldCollection.message.name}
        placeholder={dictionary.fields.message}
        validate={validatorCombiner([isRequired])}
        data-test="text-area-input"
      />
      {initialValues.hasOwnProperty('receiving_promotional_mailing') && (
        <CheckBoxField
          name="receiving_promotional_mailing"
          data-test="rules-promo"
          validate={validatorCombiner([isRequiredPromotionMailing])}
        >
          <PromotionalMailing />
        </CheckBoxField>
      )}
      <CheckBoxField
        name={feedbackFieldCollection.promo_rules.name}
        validate={validatorCombiner([
          isRequiredCheckbox(dictionary.errors.isRequiredCheckbox.rules),
        ])}
        data-test="rules-input"
      >
        <AgreementRules />
      </CheckBoxField>
      {initialValues.hasOwnProperty(feedbackFieldCollection.file.name) && (
        <ImageUploadFileField format="button" name="file" />
      )}
    </FormikConstructor>
  )
}

type Props = ConnectedProps<typeof connector>

const connector = connect(
  (state: RootState) => ({
    feedbackConfig: state.config.feedback!,
    helpFeedback: state.config.help?.feedback!,
    user: state.user,
  }),
  (dispatch: AppDispatch) => ({
    showPopupAction: (config: PopupConfig) => {
      dispatch(showPopup(config))
    },
  }),
)

export default connector(FeedbackForm)
