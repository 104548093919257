import React from 'react'

import classNames from 'classnames'

import classes from './Clock.module.scss'

const ClockIcon: React.FC<{ disabled?: boolean }> = ({ disabled }) => (
  <div
    className={classNames(
      classes['icon'],
      disabled && classes['icon_disabled'],
    )}
  >
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
          stroke="black"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.1 10.7998L8.4102 9.11C8.14763 8.84752 8.00008 8.49148 8 8.1202V3.7998"
          stroke="black"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  </div>
)

export default ClockIcon
