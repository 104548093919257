import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

import { setFullConfig } from '$store/slices/config/thunks/config'

import { MetaState } from './types'

export const initialState: MetaState = {
  domain: process.env['NEXT_PUBLIC_DOMAIN'] ?? '',
  config: null,
  data: {},
}

const metaSlice = createSlice({
  name: 'meta',
  initialState,
  reducers: {
    /* eslint no-param-reassign: "error" */
    changeDomain: (state, { payload }: PayloadAction<string>) => {
      state.domain = payload
    },
    setConfigStatus: (
      state,
      { payload }: PayloadAction<{ state: any; data?: any }>,
    ) => ({
      ...state,
      config: payload.state,
      data: payload.data,
    }),
  },
  extraReducers(builder) {
    builder
      .addCase(setFullConfig.pending, (state) => {
        state.config = 'loading'
      })
      .addCase(setFullConfig.fulfilled, (state) => {
        state.config = 'loaded'
      })
      .addCase(setFullConfig.rejected, (state) => {
        state.config = 'failed'
      })

    builder.addCase(HYDRATE, (state, action) => {
      const {
        payload: { meta },
      } = action as any

      return {
        ...state,
        ...meta,
      }
    })
  },
})

export const { changeDomain, setConfigStatus } = metaSlice.actions

export const { name } = metaSlice

export const slice = metaSlice

export default metaSlice.reducer
