import { DictionaryType } from '$hooks/useLanguageDictionary/useLanguageDictionary'

const minLength =
  (dictionary: DictionaryType) =>
  (min: number, reg: RegExp | string = '', allowMinValue: number = 0) =>
  (value: string = '') => {
    if (value.length > allowMinValue) {
      if (reg) {
        const newValue = value.replace(reg, '')
        if (
          newValue.length > allowMinValue &&
          newValue.replace(reg, '').length < min
        ) {
          return `${dictionary.errors.minLength} ${min}`
        }
      } else if (value.length < min) {
        return `${dictionary.errors.minLength} ${min}`
      }
    }
  }

export default minLength
