import React from 'react'

import Image from 'next/image'

import sadSmile from '$assets/img/smiles/sad/sadSmile.svg'

import classes from './PromoIsEndError.module.scss'

const PromoIsEndError: React.FC = () => (
  <div className={classes['smiles']}>
    <Image width={168} height={158} src={sadSmile} alt="" />
  </div>
)

export default PromoIsEndError
