import { AuthFieldType } from '$store/slices/config/types/auth/authFields'
import { FeedbackFieldsType } from '$store/slices/config/types/feedback/feedbackFields'

type PlaceholdersType = {
  [key in AuthFieldType | FeedbackFieldsType | string]: string
}

const placeholders: PlaceholdersType = {
  email: 'Email',
  login: 'Login',
  city: 'Город проживания',
  region: 'Регион проживания',
  confirm_age: 'Подтверждение совершеннолетия',
  promo_rules: 'Подтверждение правил проекта',
  first_name: 'Имя',
  last_name: 'Фамилия',
  middle_name: 'Отчество',
  password: 'Пароль',
  phone: 'Номер телефона',
  upload_checks: 'Загрузка чека',
  subject: 'Выберите тему',
  message: 'Текст сообщения',
} as const

export default placeholders
