import React, {ReactElement, ReactNode} from 'react'

import ProgressBar from '@badrap/bar-of-progress'
import axios from 'axios'
import {NextPage} from 'next'
import App, {AppProps} from 'next/app'
import Head from 'next/head'
import Router from 'next/router'

import 'simplebar-react/dist/simplebar.min.css'

import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'
import 'react-time-picker/dist/TimePicker.css'
import 'react-clock/dist/Clock.css'
import 'react-input-range/lib/css/index.css'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import '../src/nullable.css'

import '$style/global.scss'

import '../src/components/form/fields/basicInput/DateTimePicker/DateTimePicker.scss'
import '../src/components/form/fields/basicInput/FileInput/FileInput.scss'
import '../src/components/form/fields/basicInput/SingleValueInput/SingleInput.scss'
import '../src/components/form/fields/basicInput/TextAreaInput/TextAreaInput.scss'
import '../src/components/layoutParts/Header/Header.scss'
import '../src/components/popups/BasePopupLayouts/popup.scss'
import '../src/components/popups/BasePopupLayouts/popup-wrapper.scss'
import '../src/components/button/ChooseGridButton/ChooseGridButton.scss'
import '../src/components/services/FAQ/FaqItem.scss'
import '../src/components/services/PromoProducts/ProductCard/ProductCard.scss'
import '../src/components/layoutParts/DefaultSlider/DefaultSlider.scss'
import '../src/components/services/NewsBlog/NewsContentCards/Cards/TileCard/ContentCard.scss'
import '../src/style/pages/NewsBlogPages/NewsBlogArticleCard/NewsBlogArticleCard.scss'
import '../src/components/blocks/PersonalPageBlock/PersonalPageBlock.scss'
import '../src/components/blocks/PersonalDataBlock/PersonalDataBlock.scss'
import '../public/fonts/index.css'

import '../src/style/_title.scss'

import '../src/style/_ui.scss'

import '../src/style/_scrollbar.scss'

import pbAxios from '$api/promobuilding/instance'
import pbApi from '$api/promobuilding/pbApi'

import {setFullConfig} from '$store/slices/config/thunks/config'
import {setLanguage} from '$store/slices/language'
import {LanguagesType} from '$store/slices/language/types'
import {changeDomain, setConfigStatus} from '$store/slices/meta'
import wrapper from '$store/store'

import getCookie from '$utils/cookie/getCookie'
import {isDevBuildMode} from '$utils/env'

import {getDefaultLayout} from '$/layouts/DefaultLayout'
import MainApp from "$/MainApp";

const progress = new ProgressBar({
  size: 3,
  color: '#068aa7',
  className: 'bar-of-progress',
  delay: 100,
})

Router.events.on('routeChangeStart', progress.start)
Router.events.on('routeChangeComplete', progress.finish)
Router.events.on('routeChangeError', progress.finish)

const PUBLIC_URL = process.env['NEXT_PUBLIC_URL'] ?? ''

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

class PromoApp extends App<AppPropsWithLayout> {
  public static override getInitialProps = wrapper.getInitialAppProps(
    (store) => async (context) => {
      if (typeof window === 'undefined') {
        const {req, res} = context.ctx

        const {host, cookie} = req?.headers ?? {}
        if (!isDevBuildMode() && host) {
          store.dispatch(changeDomain(host))
          pbAxios.defaults.baseURL = `https://${host}/api`
        }

        let REMOTE_CLIENT_ADDR = req!.headers['remote_addr']

        REMOTE_CLIENT_ADDR = Array.isArray(REMOTE_CLIENT_ADDR)
          ? REMOTE_CLIENT_ADDR[1]
          : REMOTE_CLIENT_ADDR

        // console.log(REMOTE_CLIENT_ADDR)

        pbAxios.defaults.headers.common['REMOTEADDR'] = REMOTE_CLIENT_ADDR ?? ''
        pbAxios.defaults.headers.common['remote-client-addr'] =
          REMOTE_CLIENT_ADDR ?? ''

        const languageCookie = getCookie('language', cookie) as
          | LanguagesType
          | undefined
        const language =
          languageCookie ??
          (process.env['NEXT_PUBLIC_DEFAULT_LANGUAGE'] as LanguagesType) ??
          'ru'

        await store.dispatch(setLanguage(language))

        if (cookie) {
          pbAxios.defaults.headers.common['cookie'] = cookie
        } else {
          pbAxios.defaults.headers.common['cookie'] = ''
        }

        try {
          const response = await pbApi.getFullConfig()

          const {
            data: {data},
            headers,
          } = response

          await store.dispatch(setFullConfig(data))
          let setCookie = [`language=${language};Max-Age=259200;Path=/`]
          if (headers['set-cookie']) {
            setCookie = setCookie.concat(headers['set-cookie'])
          }
          if (setCookie) {
            res?.setHeader('set-cookie', setCookie)
          }
        } catch (err) {
          console.error(err)
          if (axios.isAxiosError(err)) {
            store.dispatch(
              setConfigStatus({
                state: err.response?.data.error || 'failed',
                data: err.response?.data ?? null,
              }),
            )
          }
        }
      }

      return {
        ...(await App.getInitialProps(context)).pageProps,
      }
    },
  )

  public override render() {
    const {Component, pageProps} = this.props

    const getLayout = Component.getLayout ?? getDefaultLayout

    return (
      <>
        <Head>
          <meta charSet="utf-8"/>
          <meta name="made-with" content="https://promobuilding.ru"/>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, user-scalable=no"
          />
          <meta name="theme-color" content="#000000"/>
          <link rel="apple-touch-icon" href={`${PUBLIC_URL}/logo192.png`}/>
          <link rel="manifest" href={`${PUBLIC_URL}/manifest.json`}/>
        </Head>
        <MainApp>
          {getLayout(<Component {...pageProps} />)}
        </MainApp>
      </>
    )
  }
}

export default wrapper.withRedux(PromoApp)
