import React from 'react'

import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'

import RestorePasswordForm from '$form/formik/forms/auth/RestorePassword/RestorePasswordForm'

const RestorePassword = () => {
  const dictionary = useLanguageDictionary()
  return (
    <div className="form-popup">
      <h2 className="popup__title popup__title_md">
        {dictionary.header.restorePassword}
      </h2>
      <RestorePasswordForm />
    </div>
  )
}

export default RestorePassword
