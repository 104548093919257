import BaseAuthBlock from '$components/blocks/BaseAuthBlock/BaseAuthBlock'
import MainContainer from '$components/container/MainContainer/MainContainer'
import PageWrapper from '$components/PageWrapper/PageWrapper'

import s from './BaseAuthLayout.module.scss'

const BaseAuthLayout = () => (
  <div className={s['layout']}>
    <MainContainer>
      <PageWrapper>
        <BaseAuthBlock />
      </PageWrapper>
    </MainContainer>
  </div>
)

export default BaseAuthLayout
