import React, { useMemo } from 'react'

import { connect, ConnectedProps } from 'react-redux'

import pbApi from '$api/promobuilding/pbApi'

import feedbackFieldCollection from '$constants/common/forms/feedback/feedbackFieldConstant'
import ReviewsFieldName from '$constants/common/forms/reviews/reviewsFieldName'

import { showPopup } from '$store/slices/popup'
import { PopupConfig } from '$store/slices/popup/types'
import { AppDispatch, RootState } from '$store/store'

import useFormSubmitHandler from '$hooks/useFormSubmitHandler/useFormSubmitHandler'
import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'
import useValidators from '$hooks/useValidators/useValidators'

import FormikConstructor from '$components/form/formik/constructor/FormConstructor/formikConstructor'
import CheckBoxField from '$components/form/formik/fields/CheckBoxField'
import SyncSelectField from '$components/form/formik/fields/SynchSelectField/SynchSelectField'
import TextInputField from '$components/form/formik/fields/TextInputField'
import validatorCombiner from '$components/form/validators/validatorCombiner'

import ImageUploadFileField from '$form/fields/formikField/field/ImageUploadField/ImageUploadField'
import TextareaField from '$form/fields/formikField/field/TextareaField/TextareaField'
import { SelectOption } from '$form/fields/formikField/type/FormikFieldType'
import AgreementRules from '$form/fields/other/confirmation/AgreementRules'
import initializeValue from '$form/formik/utils/InitializeFormikValues'

const ReviewForm: React.FC<Props> = ({
  reviewConfig,
  user,
  showPopupAction,
}) => {
  const { reviewFields, reviewOnLists } = reviewConfig
  const dictionary = useLanguageDictionary()
  const {
    isRequired,
    isValidEmail,
    minLength,
    maxLength,
    isValidName,
    isRequiredCheckbox,
  } = useValidators()

  const initialValues = useMemo(
    () =>
      initializeValue(
        [
          ReviewsFieldName.email,
          ReviewsFieldName.text,
          ReviewsFieldName.agreement,
          ...reviewFields,
        ],
        {
          [ReviewsFieldName.email]: user?.email ? user.email : '',
          [ReviewsFieldName.name]: user?.first_name ? user.first_name : '',
        },
      )(),
    [reviewFields, user],
  )

  const sendReview = useFormSubmitHandler<typeof initialValues>(
    [],
    (value, { resetForm }) => {
      const formData: FormData = new FormData()
      Object.entries(value).forEach(([k, v]) => {
        if (k === ReviewsFieldName.file) {
          for (let i = 0; i < v.length; i++) {
            formData.append(`${k}[]`, v[i])
          }
          return
        }
        formData.append(k, v)
      })

      return pbApi.sendReviewMessage(formData).then(() => {
        resetForm()
        showPopupAction({
          type: 'success',
          title: dictionary.messages.reviewIsUpload,
          message: dictionary.messages.successReview,
        })
      })
    },
  )

  const subjOptions = useMemo(() => {
    const subjOptionsTmp: SelectOption = []
    // eslint-disable-next-line
    for (const key in reviewOnLists) {
      subjOptionsTmp.push({
        label: key,
        value: key,
      })
    }
    return subjOptionsTmp
  }, [reviewOnLists])

  return (
    <FormikConstructor
      name="review"
      onSubmit={sendReview}
      initialValues={initialValues}
      submitButtonName={dictionary.button.send}
      dataTest="send-review-button"
    >
      <TextInputField
        name={feedbackFieldCollection.email.name}
        placeholder={dictionary.fields.email}
        type={feedbackFieldCollection.email.type}
        validate={validatorCombiner([isRequired, isValidEmail, maxLength(255)])}
        data-test="email-input"
      />
      {initialValues.hasOwnProperty(ReviewsFieldName.name) && (
        <TextInputField
          name={feedbackFieldCollection.name.name}
          placeholder={dictionary.fields.first_name}
          validate={validatorCombiner([
            isRequired,
            minLength(2),
            maxLength(25),
            isValidName('имя'),
          ])}
          data-test="first-name-input"
        />
      )}
      {initialValues.hasOwnProperty(ReviewsFieldName.subject) && (
        <SyncSelectField
          name={ReviewsFieldName.subject}
          options={subjOptions}
          placeholder={dictionary.fields.reviewBy}
        />
      )}
      <TextareaField
        name="text"
        placeholder={dictionary.fields.message}
        validate={validatorCombiner([isRequired])}
        data-test="text-area-input"
      />
      {initialValues.hasOwnProperty(ReviewsFieldName.file) && (
        <ImageUploadFileField format="button" name="file" />
      )}
      <CheckBoxField
        name="agreement"
        validate={validatorCombiner([
          isRequiredCheckbox(dictionary.errors.isRequiredCheckbox.rules),
        ])}
        data-test="rules-input"
      >
        <AgreementRules />
      </CheckBoxField>
    </FormikConstructor>
  )
}

type Props = ConnectedProps<typeof connector>

const connector = connect(
  (state: RootState) => ({
    reviewConfig: state.config.reviews!,
    helpFeedback: state.config.help?.feedback!,
    user: state.user,
  }),
  (dispatch: AppDispatch) => ({
    showPopupAction: (config: PopupConfig) => {
      dispatch(showPopup(config))
    },
  }),
)

export default connector(ReviewForm)
