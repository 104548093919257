enum PopupId {
  sign_in = 'sign_in',
  sign_up = 'sign_up',
  restore_password = 'restore_password',
  confirm_phone = 'confirm_phone',
  upload_check = 'upload_check',
  upload_manual_check = 'upload_manual_check',
  upload_photo_check = 'upload_photo_check',
  upload_qr_check = 'upload_qr_check',
  feedback = 'feedback',
  send_review = 'send_review',
  default = 'default',
  custom_form = 'custom_form',
  confirm_email = 'confirm_email',
  new_password = 'new_password',
  receipt = 'receipt',
  crop_passport = 'crop_passport',
  consent = 'consent',
}

export type PopupType = keyof typeof PopupId

export default PopupId
