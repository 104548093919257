import { configureStore } from '@reduxjs/toolkit'
import { createWrapper } from 'next-redux-wrapper'

// REDUCERS
import config from './slices/config'
import error from './slices/error'
import language from './slices/language'
import meta from './slices/meta'
import popup from './slices/popup'
import site from './slices/site'
import tost from './slices/tost'
import user from './slices/user'

const makeStore = () =>
  configureStore({
    reducer: {
      config,
      popup,
      error,
      user,
      meta,
      tost,
      language,
      site,
    },
    devTools: process.env['NEXT_PUBLIC_API_TOKEN'] !== 'PROD',
  })

export type Store = ReturnType<typeof makeStore>

export type RootState = ReturnType<Store['getState']>
export type AppDispatch = Store['dispatch']

const wrapper = createWrapper(makeStore)

export default wrapper
