import { useDispatch } from 'react-redux'

import pbApi from '$api/promobuilding/pbApi'

import { useAppSelector } from '$store/hooks'
import { setFullConfig } from '$store/slices/config/thunks/config'
import { showPopup } from '$store/slices/popup'

import AdultForm from '$components/form/adult/AdultForm'
import FormWrapper from '$components/formWrapper/FormWrapper'
import Button from '$components/ui/buttons/RootButton/Button'

import s from './AdultBlock.module.scss'
import setCookie from '$utils/cookie/setCookie'
import { isDevBuildMode } from '$utils/env'

const AgreementBlock = () => {
  const dispatch = useDispatch()
  return (
    <div className={s['agreement-block']}>
      <h1 className="title title-lg">Вам уже исполнилось 18 лет?</h1>
      <div className={s['button-container']}>
        <Button
          onClick={() => {
            pbApi.setUserAdult({ is_adult: true }).then(() => {
              pbApi.getFullConfig().then(({ data: { data } }) => {
                if (isDevBuildMode()) {
                  setCookie('is_adult', '1', null)
                }
                dispatch(setFullConfig(data))
              })
            })
          }}
        >
          Да
        </Button>
        <Button
          onClick={() => {
            dispatch(
              showPopup({
                type: 'error',
                message:
                  'Спасибо за честный ответ! Мы заботимся о здоровье будущего поколения, поэтому не демонстрируем контент расположенный на этом сайте лицам младше 18 лет.',
              }),
            )
          }}
        >
          Нет
        </Button>
      </div>
    </div>
  )
}

const AdultBlock = () => {
  const data = useAppSelector((store) => store.meta.data)

  const Component = data.request_birthday ? (
    <FormWrapper formName="Укажите Вашу дату рождения">
      <AdultForm />
    </FormWrapper>
  ) : (
    <AgreementBlock />
  )

  return <div>{Component}</div>
}

export default AdultBlock
