const fields = {
  email: 'Email',
  first_name: 'Аты',
  last_name: 'Тегі',
  middle_name: 'Әкесінің аты',
  phone: 'Телефон нөмірі',
  region: 'Тұрғылықты жері',
  city: 'Тұрғылықты қаласы',
  password: 'Құпия сөз',
  repeat_password: 'Құпия сөзді қайталаңыз',
  birth_date: 'Туған Күні',
  passport_series_number: 'Төлқұжаттың сериясы, нөмірі',
  passport_issue_date: 'Төлқұжаттың берілген күні',
  passport_issued_by: 'Кіммен берілді',
  passport_department_code: 'Бөлімше коды',
  post_index: 'Пошта индексі',
  street: 'Тіркелу көшесі',
  house: 'Тіркелген үйі',
  apartment: 'Тіркелген пәтері',
  inn: 'ЖСН',
  card_number: 'Карта нөмірі',
  passport_copy_photo_file:
    'Төлқұжаттың суреті немесе көшірмсі (фотосуреті көрсетілген)',
  passport_copy_address_file:
    'Төлқұжаттың суреті немесе көшірмсі (қолданыстағы фотосуреті көрсетілген)',
  passport_copy_inn_file: 'ЖСН-нің суреті немесе көшірмсі',
  subject: 'Тақырыпты таңдаңыз',
  FD: 'ФД',
  FP: 'ФБ',
  FN: 'ФЖ',
  dateTime: 'Күні, Уақыты',
  sum: 'Сомасы',
  promo_code: 'Промокоды',
  changePassword: 'Жаңа құпия сөз',
  new_password: 'Жаңа құпия сөз',
  message: 'Хабарлама мәтіні',
  block: 'Корпус',
  reviewBy: 'Үшін шолу',
  date: 'Күні',
  time: 'Уақыт',
  inviteCode: 'Шақыру коды',
  referral_code: 'Жолдама коды',
}

export default fields
