export interface CombineUrlOptions {
  /**
   * @default false
   */
  fallbackBaseHash?: boolean
  /**
   * @default true
   */
  mergeSearchParams?: boolean
}

export function combineUrl(
  base: string,
  target: string,
  options?: CombineUrlOptions,
) {
  if (target) {
    const baseUrl = new URL(base, 'https://example.com')
    const targetUrl = new URL(target, baseUrl)

    if (options?.mergeSearchParams !== false) {
      baseUrl.searchParams.forEach((value, key) => {
        if (!targetUrl.searchParams.has(key)) {
          targetUrl.searchParams.set(key, value)
        }
      })
    }

    const { pathname, search, hash } = targetUrl

    return `${pathname}${search}${
      options?.fallbackBaseHash ? hash || baseUrl.hash : hash
    }`
  }

  return ''
}
