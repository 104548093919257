import React from 'react'

import classNames from 'classnames'
import { useDispatch } from 'react-redux'

import { useAppSelector } from '$store/hooks'
import { showPopup } from '$store/slices/popup'

import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'

import QRScannerUploadCheckForm from '$components/form/formik/forms/uploadCheck/QrScannerUploadCheck/QRScannerUploadCheckForm'

import classes from './QRUploadCheckPopup.module.scss'
import s from '../checkUploadCommon.module.scss'

const QRUploadCheckPopup: React.FC = () => {
  const popupState = useAppSelector((state) => state.popup.activeState)
  const dictionary = useLanguageDictionary()
  const dispatch = useDispatch()

  const toManual = () =>
    dispatch(
      showPopup({
        popupId: 'upload_manual_check',
      }),
    )

  const toPhoto = async () => {
    dispatch(
      showPopup({
        popupId: 'upload_photo_check',
      }),
    )
  }
  return (
    <div className="form-popup">
      <h2
        className={classNames('popup__title popup__title_md', classes['title'])}
      >
        {dictionary.header.qrUploadCheck}
      </h2>
      {popupState && <QRScannerUploadCheckForm />}
      <button className={s['buttonLink']} onClick={toPhoto}>
        Загрузка фото
      </button>
      <button className={s['buttonLink']} onClick={toManual}>
        Ручной ввод данных чека
      </button>
    </div>
  )
}

export default QRUploadCheckPopup
