const errors = {
  fileFormat: {
    0: 'Формат файла',
    1: 'не допустим для загрузки',
  },
  fileSize: 'Максимальный размер файла - ',
  isRequired: 'Поле обязательно для заполнения',
  isRequiredCheckbox: {
    rules: 'Необходимо согласие с правилами проекта',
    majority: 'Необходимо подтверждение совершеннолетия',
    media: 'Необходимо согласие с информационными рассылками',
    opd: 'Необходимо согласие с политикой обработки персональных данных',
  },
  isValidEmail: 'Неверный формат',
  isValidName: {
    0: 'Введите',
    1: { name: 'Имя', lastName: 'Фамилию', middleName: 'Отчество' },
    2: 'корректно',
  },
  maxLength: 'Максимальное кол-во символов',
  minLength: 'Минимальное кол-во символов',
  maxVal: 'Максимальное значение',
  minVal: 'Минимальное значение',
  notAllowFutureTime: 'Поле не может содержать дату в будущем',
  onlyCyrillic: 'Текст может содержать только русские символы',
  onlyCyrillicAndOther: 'Введите текст на русском',
  passwordsNotMatch: 'Пароли не совпадают',
  wrongFormat: 'Недопустимый формат',
  isInteger: 'Только целочисленное значение',
}

export default errors
