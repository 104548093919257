import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'

import withFingerprint from "$api/fingerprint/index";

type FingerprintJsProConfig = {
  apiKey: string
}

export default function withFingerprintJsPro({ apiKey }: FingerprintJsProConfig) {
  withFingerprint(() => {
    const getFingerprint = async () => {
      const fp = await FingerprintJS.load({
        apiKey,
      })

      return fp.get()
    }

    return new Promise((resolve, reject) => {
      getFingerprint()
        .then((res) => {
          const fingerprint = res.visitorId;

          resolve(fingerprint);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }, 'fp');
}
