import { createAsyncThunk } from '@reduxjs/toolkit'
import Cookie from 'js-cookie'

import pbApi from '$api/promobuilding/pbApi'

import { isDevBuildMode } from '$utils/env'
import { wrapAxiosValidationError } from '$utils/validationError'

export const signIn = createAsyncThunk(
  'user/signIn',
  async (value: any, { rejectWithValue }) => {
    try {
      const {
        data: { user, token },
      } = await pbApi.signIn(value)

      if (isDevBuildMode()) {
        Cookie.set('token', token)
      }

      return user
    } catch (e) {
      return rejectWithValue(wrapAxiosValidationError(e))
    }
  },
)

export const logOut = createAsyncThunk('user/logOut', async () => {
  await pbApi.logout()
  if (isDevBuildMode()) {
    Cookie.remove('token')
  }
})
