import FormikWrapper from '../../../constructor/FormConstructor/formikConstructor'
import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'
import TextInputField from '$components/form/formik/fields/TextInputField'
import authFieldCollection from '$constants/common/forms/auth/fields/AuthFieldsConstant'
import validatorCombiner from '$components/form/validators/validatorCombiner'
import useValidators from '$hooks/useValidators/useValidators'
import useFormSubmitHandler from '$hooks/useFormSubmitHandler/useFormSubmitHandler'
import pbApi from '$api/promobuilding/pbApi'
import { useAppDispatch } from '$store/hooks'
import { setFullConfig } from '$store/slices/config/thunks/config'
import setCookie from '$utils/cookie/setCookie'
import { isDevBuildMode } from '$utils/env'

const BaseAuthForm = () => {
  const initialValues = {
    login: '',
    password: '',
  }
  const dictionary = useLanguageDictionary()
  const dispatch = useAppDispatch()
  const { isRequired, isValidEmail, maxLength, minLength } = useValidators()

  const onLoginWithAdult = useFormSubmitHandler<typeof initialValues>(
    [],
    (values) => {
      const loginRequests = [
        pbApi.setUserAdult({ is_adult: true, birthday: '01/01/1990' }),
        pbApi.checkBaseAuth({ login: values.login, password: values.password }),
      ]
      return Promise.all(loginRequests).then(() => {
        pbApi.getFullConfig().then(({ data: { data } }) => {
          if (isDevBuildMode()) {
            setCookie('is_adult', '1', null)
            setCookie('basic_auth', '1', null)
          }
          dispatch(setFullConfig(data))
        })
      })
    },
  )

  return (
    <FormikWrapper
      name="base-auth"
      onSubmit={onLoginWithAdult}
      initialValues={initialValues}
      submitButtonName={dictionary.button.signIn}
      dataTest="base-auth"
    >
      <TextInputField
        name={authFieldCollection.login.name}
        placeholder={dictionary.fields.email}
        validate={validatorCombiner([isRequired, isValidEmail, maxLength(255)])}
        inputMode="email"
        data-test="email-input"
      />
      <TextInputField
        name={authFieldCollection.password.name}
        placeholder={dictionary.fields.password}
        type={authFieldCollection.password.type}
        validate={validatorCombiner([isRequired, minLength(8)])}
        data-test="pass-input"
      />
    </FormikWrapper>
  )
}

export default BaseAuthForm
