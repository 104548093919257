import React, { ButtonHTMLAttributes, PropsWithChildren } from 'react'

import classNames from 'classnames'
// STYLE

import classes from './Button.module.scss'

interface ButtonsProps
  extends PropsWithChildren<
    Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'className'>
  > {
  colorTheme?: string
  outline?: boolean
  size?: 'default' | 'middle' | 'small'
  wrapperClassName?: string
  buttonClassName?: string
  dataTest?: string
  /**
   * @deprecated use {@link buttonClassName} instead
   */
  className?: string
}

const Button: React.FC<ButtonsProps> = ({
  children,
  colorTheme = 'def',
  outline = false,
  size = 'default',
  wrapperClassName,
  buttonClassName,
  className,
  dataTest,
  ...props
}) => (
  <div className={classNames(classes['wrapper'], wrapperClassName)}>
    <button
      {...props}
      data-test={dataTest}
      className={classNames(
        classes[colorTheme],
        classes['button'],
        classes[size],
        outline && classes['button_outline'],
        buttonClassName,
        className,
      )}
    >
      {children}
    </button>
  </div>
)

export default Button
