import { PropsWithChildren } from 'react'

import { useAppSelector } from '$store/hooks'

import PlugTimer from '$/layouts/BaseLayout/PlugTimer/PlugTimer'

export default function PromoStubTrap({ children }: PropsWithChildren<{}>) {
  const promoStatus = useAppSelector((state) => state.config.promo.status)

  if (promoStatus === 'placeholder') {
    return <PlugTimer />
  }

  return <>{children}</>
}
