import { dictionary } from '$hooks/useLanguageDictionary/useLanguageDictionary'

const isTestRegular =
  (dictionary: dictionary) =>
  (regExp: RegExp) =>
  (value: any = '') => {
    if (value) {
      if (!regExp.test(value)) {
        return dictionary.errors.wrongFormat
      }
    }
  }

export default isTestRegular
