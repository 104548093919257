/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'

import classNames from 'classnames'
import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'

import pbApi from '$api/promobuilding/pbApi'

import { useAppSelector } from '$store/hooks'
import { UserType } from '$store/slices/config/types/user/userType'
import { showPopup } from '$store/slices/popup'
import { confirmUserPhone } from '$store/slices/user'

import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'
import useTimer from '$hooks/useTimer/useTimer'

import ConfirmPhoneForm from '$components/form/formik/forms/auth/ConfirmPhoneForms/ConfirmPhoneForm'
import Button from '$components/ui/buttons/RootButton/Button'
import ClipSpinner from '$components/ui/spinners/ClipSpinner/ClipSpinner'

import s from './ConfirmPhonePopup.module.scss'

const BaseConfirm: React.FC = () => {
  const dictionary = useLanguageDictionary()
  const verifyMethod = useAppSelector(
    (state) => state.config.auth?.phoneConfirmConfig.verify_method,
  )
  return (
    <>
      <h2 className="popup__title popup__title_md">
        {dictionary.header.confirmPhone}
      </h2>
      <p className="popup__text">
        {verifyMethod === 'password-call' &&
          'На указанный номер поступит звонок. Введите код, который продиктует оператор или последние 4 цифры номера.'}
        {verifyMethod === 'call' &&
          'На указанный номер поступит звонок, введите последние 4 цифры номера входящего звонка.'}
        {verifyMethod === 'sms' &&
          'Вам отправлен код для подтверждения. Пожалуйста, введите его в поле ниже.'}
        {verifyMethod === 'voicecode' &&
          'На указанный номер поступит звонок. Введите код, который продиктует оператор.'}
      </p>
      <div>
        <h4 className="popup__title popup__title_sm">
          {verifyMethod === 'call' && 'Последние 4 цифры'}
          {verifyMethod === 'sms' && 'Код из СМС'}
          {verifyMethod === 'voicecode' && 'Код'}
        </h4>
        <ConfirmPhoneForm />
      </div>
    </>
  )
}

const AlternativeForm: React.FC = () => {
  const router = useRouter()
  const dictionary = useLanguageDictionary()
  const [number, setNumber] = useState<string | null>(null)
  const [qr, setQr] = useState<string | null>(null)

  const dispatch = useDispatch()

  const phone = useAppSelector(
    (store) => store.popup.popupConfig.popupData?.phone,
  )

  const [remaining, reset] = useTimer(120)

  const user = useAppSelector((store) => store.user) as UserType
  const isEmailVerified = useAppSelector((store) => store.user.emailIsVerified)

  useEffect(() => {
    pbApi
      .sendConfirmPhoneWithAlternate(phone)
      .then(({ data }) => {
        setNumber(data.confirmation_number)
        setQr(data.qr_code)
      })
      .catch(({ response: { data = {} } = {} }) => {
        const err = data.errors?.[0] ?? 'Непредвиденная ошибка'
        dispatch(
          showPopup({
            title: err,
            type: 'error',
          }),
        )
      })
  }, [])

  useEffect(() => {
    if (user.phoneIsVerified) {
      router.push('/account/room')
      dispatch(
        showPopup({
          title: dictionary.messages.thanks,
          message: isEmailVerified
            ? 'Номер успешно подтвержден'
            : dictionary.messages.successRegistration,
          type: 'success',
        }),
      )

      return
    }

    const checkVerified = setInterval(() => {
      pbApi
        .confirmPhoneAlter(phone)
        .then(({ data }) => {
          if (data.verified) {
            dispatch(confirmUserPhone())
            clearInterval(checkVerified)
          }
        })
        .catch((e) => console.log(e))
    }, 3500)

    return () => {
      clearInterval(checkVerified)
    }
  }, [user.phoneIsVerified])

  return (
    <>
      {remaining > 0 ? (
        <>
          <h2
            className={classNames('popup__title popup__title_md', s['title'])}
          >
            {dictionary.header.confirmPhone}
          </h2>
          <p
            // style={{ maxWidth: 500, margin: '0 auto' }}
            className={classNames('popup__text', s['text'])}
          >
            Совершите бесплатный звонок по номеру <br />
          </p>
          <div
            style={{
              margin: '10px 0px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            {number ? (
              <>
                <a
                  className={s['number']}
                  style={{
                    color: 'black',
                    textDecoration: 'none',
                    margin: '10px auto',
                  }}
                  href={`tel:+${number}`}
                >
                  +{number}
                </a>
                {qr && (
                  <>
                    <span
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        textAlign: 'center',
                      }}
                    >
                      или отсканируйте QR код для&nbsp;звонка
                    </span>
                    <span
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '5px',
                        textAlign: 'center',
                      }}
                    >
                      Номер телефона генерируется на&nbsp;120&nbsp;секунд
                      (2&nbsp;минуты), после становится недоступен. Закройте
                      и&nbsp;заново откройте данную страницу, чтобы позвонить
                      по&nbsp;номеру.
                    </span>
                    <img
                      style={{ margin: '20px auto -5px' }}
                      width={230}
                      height={230}
                      src={qr}
                      alt=""
                    />
                  </>
                )}
              </>
            ) : (
              <ClipSpinner size={70} />
            )}
          </div>
        </>
      ) : (
        <>
          <h2 className="popup__title popup__title_md">
            {dictionary.header.confirmPhone}
          </h2>
          <Button
            wrapperClassName={s['button']}
            onClick={() => {
              pbApi
                .sendConfirmPhoneWithAlternate(phone)
                .then(({ data }) => {
                  setNumber(data.confirmation_number)
                  setQr(data.qr_code)
                })
                .catch(({ response: { data = {} } = {} }) => {
                  const err = data.errors?.[0] ?? 'Непредвиденная ошибка'
                  dispatch(
                    showPopup({
                      title: err,
                      type: 'error',
                    }),
                  )
                })
              reset()
            }}
          >
            Попробовать еще
          </Button>
        </>
      )}
    </>
  )
}

const ConfirmPhonePopup = () => (
  // const [state, setState] = useState<'base' | 'choose' | 'alternative'>(
  //   'choose',
  // )
  // const dictionary = useLanguageDictionary()

  <div>
    <AlternativeForm />
    {/* {state === 'choose' && (
        <>
          <h2 className="popup__title popup__title_md">
            {dictionary.header.confirmPhone}
          </h2>
          <h4
            className="popup__text"
            style={{ textAlign: 'center', maxWidth: 550, margin: '0 auto' }}
          >
            В&nbsp;настоящий момент наблюдаются проблемы с&nbsp;доставкой
            вызовов у&nbsp;операторов Билайн и&nbsp;Мегафон
          </h4>
          <div
            style={{
              margin: '20px 0',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <div
              style={{
                maxWidth: 320,
                width: '100%',
                marginBottom: 15,
                height: 45,
              }}
            >
              <Button
                onClick={() => {
                  setState('base')
                }}
              >
                У&nbsp;меня другой оператор
              </Button>
            </div>
            <div style={{ maxWidth: 320, width: '100%', height: 45 }}>
              <Button
                onClick={() => {
                  setState('alternative')
                }}
              >
                У&nbsp;меня Билайн или Мегафон
              </Button>
            </div>
          </div>
        </>
      )}
      {state === 'base' && <BaseConfirm />}
      {state === 'alternative' && <AlternativeForm />} */}
  </div>
)

export default ConfirmPhonePopup
