const messages = {
  passwordSuccessChanged: 'Пароль успешно изменен!',
  successRegistration:
    'Для завершения регистрации, перейдите по ссылке из письма, отправленного на вашу почту',
  restorePassword:
    'Для восстановления пароля, перейдите по ссылке из письма, отправленного на вашу почту.',
  sendFeedBack: 'Спасибо, Ваше сообщение отправлено',
  successSendData: 'Данные успешно отправлены',
  needVerifiedEmail: 'Подтвердите почту',
  promocodeIsUpload: 'Промокод загружен',
  reviewIsUpload: 'Ваш отзыв отправлен на модерацию!',
  successReview: 'О результате его проверки вы получите сообщение на почту',
  checkIsUpload: 'Ваш чек успешно зарегистрирован и\u00A0отправлен на проверку',
  successCheck:
    'О\u00A0результате проверки Вам сообщат на\u00A0адрес электронной почты, указанный при\u00A0регистрации',
  thanks: 'Спасибо!',
}

export default messages
