export default function MadeWithPromoBuilding() {
  return (
    <span
      className="made-with-promobuilding"
      style={{ display: 'none' }}
      dangerouslySetInnerHTML={{
        __html: '<!-- made with https://promobuilding.ru -->',
      }}
    />
  )
}
