import { FC, PropsWithChildren } from 'react'

// STYLE
import classes from './MainContainer.module.scss'

const MainContainer: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <div className={classes['mainContainer']}>{children}</div>
)

export default MainContainer
