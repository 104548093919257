import pbAxios from "$api/promobuilding/instance";

import getCookie from "$utils/cookie/getCookie";
import setCookie from "$utils/cookie/setCookie";

const cookiesConfig = {
  'Max-Age': 60 * 60 * 24 * 365 * 10,
  'Path': '/'
};

type VisitorType = 'fp' | 'cj';

export default function withFingerprint(fingerprintFunction: () => Promise<string | number>, visitorType: VisitorType) {
  const visitorIdName = `visitor_${visitorType}_id`;

  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    const visitorIdFromCookies = getCookie(visitorIdName, document.cookie);

    if (!visitorIdFromCookies) {
      fingerprintFunction()
        .then((visitorId) => {
          setCookie(visitorIdName, `${visitorId}`, cookiesConfig);

          pbAxios.defaults.headers.common[visitorIdName] = visitorId;

          if (visitorType === 'cj') {
            pbAxios
              .get('/fingerprint')
              .catch((error) => {
                console.error('get fingerprint request', error);
              });
          }
        })
        .catch((error) => {
          console.error('fingerprint', error);
        });
    } else {
      pbAxios.defaults.headers.common[visitorIdName] = visitorIdFromCookies!;
    }
  }
}