import React, { useCallback, useContext, useMemo } from 'react'

import { useRouter } from 'next/router'
import { connect, ConnectedProps } from 'react-redux'

import popupsID from '$constants/common/popupsID/popupsID'
import routePath from '$constants/common/routePath/routePath'
import viewTemplate from '$constants/config/viewTemplate/viewTemplate'

import { logOut } from '$store/slices/user/thunks/user'
import { AppDispatch, RootState } from '$store/store'

import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'
import usePromoStatus from '$hooks/usePromoStatus/usePromoStatus'

import { HeaderContext } from '$services/HeaderMenuService/HeaderMenuService'

import Button from '$components/ui/buttons/RootButton/Button'

import { popupLink } from '$utils/link'
import { combineUrl } from '$utils/routing'

const NavBtn: React.FC<{
  onClick: () => void
  placeholder: string
  outline?: boolean
  dataTest?: string
}> = ({ onClick, placeholder, outline, dataTest }) => (
  <Button dataTest={dataTest} outline={outline} onClick={onClick}>
    {placeholder}
  </Button>
)

const HeaderMenu: React.FC<Props> = ({
  configState,
  isUserUpload,
  check,
  logout,
}) => {
  const TemplateConfig = useMemo(
    () => ({
      registration: {
        [viewTemplate.MODAL]: popupLink(popupsID.sign_up),
        [viewTemplate.PAGE]: routePath.reg,
      },
      authorization: {
        [viewTemplate.MODAL]: popupLink(popupsID.sign_in),
        [viewTemplate.PAGE]: routePath.auth,
      },
    }),
    [],
  )

  const dictionary = useLanguageDictionary()

  const { setOpenMenuState } = useContext(HeaderContext)
  const router = useRouter()

  const REGISTRATION = configState.auth?.viewTemplate
  const onPersonalPage = useMemo(
    (): boolean => router.pathname.includes('/account'),
    [router.pathname],
  )

  const redirectHandler = useCallback(
    (to: string) => () => {
      router.push(combineUrl(router.asPath, to), undefined, {
        shallow: true,
      })

      setOpenMenuState(false)
    },
    [router, setOpenMenuState],
  )

  const logoutHandler = useCallback(async () => {
    await router.push('/')
    await logout()
    setOpenMenuState(false)
  }, [logout, router, setOpenMenuState])

  const promoStatus = configState.promo.status

  const postPromoStatusReg = usePromoStatus(true, {
    title: 'Регистрация закрыта',
  })
  const postPromoStatusCheck = usePromoStatus(true, {
    title: 'Прием чеков окончен',
  })

  const postPromo = useCallback(() => {
    postPromoStatusReg()
  }, [postPromoStatusReg])

  const postPromoCheck = useCallback(() => {
    postPromoStatusCheck()
  }, [postPromoStatusCheck])

  if (!REGISTRATION || REGISTRATION === viewTemplate.HIDDEN) {
    return null
  }

  return (
    <div className="header-menu__item sign-in">
      <div className="nav-btn__wrapper">
        {isUserUpload ? (
          onPersonalPage ? (
            check &&
            (promoStatus === 'postpromo' ? (
              <NavBtn
                onClick={postPromoCheck}
                placeholder={dictionary.button.checkUpload}
              />
            ) : (
              <NavBtn
                onClick={redirectHandler(popupLink(popupsID.upload_check))}
                dataTest="header-upload-check"
                placeholder={dictionary.button.checkUpload}
              />
            ))
          ) : (
            <NavBtn
              onClick={redirectHandler('/account/room')}
              placeholder={dictionary.button.account}
            />
          )
        ) : promoStatus === 'postpromo' ? (
          <NavBtn
            onClick={postPromo}
            placeholder={dictionary.button.signUp}
            dataTest="button-registration"
          />
        ) : (
          <NavBtn
            onClick={redirectHandler(TemplateConfig.registration[REGISTRATION])}
            placeholder={dictionary.button.signUp}
            dataTest="button-registration"
          />
        )}
      </div>
      {/*  */}
      <div className="nav-btn__wrapper">
        <NavBtn
          onClick={
            isUserUpload
              ? logoutHandler
              : redirectHandler(TemplateConfig.authorization[REGISTRATION])
          }
          placeholder={
            isUserUpload ? dictionary.button.logOut : dictionary.button.signIn
          }
          dataTest={isUserUpload ? 'button-logout' : 'button-sign-in'}
          outline
        />
      </div>
    </div>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({
    configState: state.config,
    isUserUpload: !!state.user,
    check: state.config.check,
  }),
  (dispatch: AppDispatch) => ({
    logout: () => dispatch(logOut()),
  }),
)

export default connector(HeaderMenu)
