const other = {
  serviceFor:
    'Сервис предназначен для автоматизации процесса проведения промо-кампаний',
  createdBy: {
    0: 'Разработано на базе',
    1: 'PromoBuilding',
    2: '',
  },
  sections: 'Разделы',
  tags: 'Теги',
  majority: 'Подтверждаю, что достиг возраста совершеннолетия (18 лет).',
  media: 'Согласен получать информационные рассылки',
  codeUploadManual:
    'Ищите коды в акционных упаковках. Они расположены с обратной стороны защитной плёнки и состоят из 5 символов.',
  retryCall: 'Повторить вызов',
  retrySend: 'Повторить отправку',
  retryAllow: 'Повторная отправка возможна через',
  chooseCategory: 'Выберите категорию',

  reviewDefaultDescription: (
    date: string | null | undefined,
    project: string,
  ) =>
    `Данный отзыв был оставлен${
      date ? ` ${date}` : ''
    } в рамках проекта ${project}. Повышайте уровень доверия к вашей компании.`,
}

export default other
