import { ClearIndicatorProps } from 'react-select'

import { SelectOptionType } from '../types'

import s from './style.module.scss'

export const ClearIcon: React.FC<
  ClearIndicatorProps<SelectOptionType, true, any>
> = ({ clearValue }) => (
  <button
    className={s['clear-value']}
    onClick={clearValue}
    onTouchEnd={clearValue}
  >
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.69617 0.807432C8.28631 0.397575 7.62383 0.397575 7.21398 0.807432L4.50352 3.51793L1.79303 0.807393C1.38317 0.397536 0.72069 0.397536 0.310833 0.807393C-0.0990236 1.21725 -0.0990236 1.87973 0.310833 2.28959L3.02133 5.00012L0.310811 7.71068C-0.0990463 8.12053 -0.0990463 8.78301 0.310811 9.19287C0.515215 9.39727 0.783561 9.5 1.05191 9.5C1.32025 9.5 1.5886 9.39727 1.793 9.19287L4.50352 6.48231L7.21398 9.19287C7.41838 9.39727 7.68673 9.5 7.95507 9.5C8.22342 9.5 8.49177 9.39727 8.69617 9.19287C9.10603 8.78301 9.10603 8.12053 8.69617 7.71068L5.98571 5.00012L8.69617 2.28963C9.10603 1.87977 9.10603 1.21729 8.69617 0.807432Z"
        fill="#9DA6BA"
      />
    </svg>
  </button>
)
