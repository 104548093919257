import { dictionary } from '$hooks/useLanguageDictionary/useLanguageDictionary'

const minNumbValue =
  (dictionary: dictionary) =>
  (min: number) =>
  (value: string = '') => {
    if (value) {
      if (Number(value) < min) {
        return `${dictionary.errors.minVal} ${min}`
      }
    }
  }

export default minNumbValue
