import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import PopupId from '$constants/common/popupsID/popupsID'

import { PopupConfig } from './types'

export interface PopupState {
  activeState: boolean
  popupConfig: PopupConfig
}

const initialState: PopupState = {
  activeState: false,
  popupConfig: {
    type: 'common',
    title: 'title',
    message: 'message',
  },
}

/* eslint no-param-reassign: "error" */
const popupSlice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    showPopup: (state, { payload: config }: PayloadAction<PopupConfig>) => {
      const {
        popupId = PopupId.default,
        type = 'common',
        title = '',
        message = '',
        urlParam = false,
        popupData,
      } = config

      state.activeState = true
      state.popupConfig = {
        popupId,
        type,
        title,
        message,
        urlParam,
        popupData,
      }
    },

    hidePopup: (state) => {
      state.activeState = false
    },
  },
})

export const { showPopup, hidePopup } = popupSlice.actions
export const { name } = popupSlice
export const slice = popupSlice
export default popupSlice.reducer
