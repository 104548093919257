import React from 'react'

import classNames from 'classnames'
import ReCAPTCHA from 'react-google-recaptcha'

import { RecaptchaType } from '$store/slices/config/types/site/site'

import classes from './V2Recaptcha.module.scss'

// FIXME: React.forwardRef?????????????????????????????????
const V2Recaptcha: React.FC<
  {
    recaptchaRef: React.MutableRefObject<any>
  } & Omit<RecaptchaType, 'forms'>
> = ({ recaptchaRef, v2_type = 'normal', site_key = '' }) => {
  const recaptchaClassNames = classNames(
    classes['recaptchaV2'],
    v2_type === 'normal' && classes['isVisible'],
  )

  if (site_key) {
    return (
      <div className={recaptchaClassNames}>
        <ReCAPTCHA
          badge="bottomleft"
          ref={recaptchaRef}
          size={v2_type}
          sitekey={site_key}
        />
      </div>
    )
  }

  return null
}

export default V2Recaptcha
