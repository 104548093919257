import { dictionary } from '$hooks/useLanguageDictionary/useLanguageDictionary'

const isInteger =
  (dictionary: dictionary) =>
  (value: string = '') => {
    if (!Number.isInteger(Number(value))) {
      return `${dictionary.errors.isInteger}`
    }
  }

export default isInteger
