import { DictionaryType } from '$hooks/useLanguageDictionary/useLanguageDictionary'

const fileSize =
  (dictionary: DictionaryType) =>
  (fileSizeNumber: number) =>
  (value: FileList) => {
    const byteSize = 1000000 * fileSizeNumber
    for (let i = 0; i < value.length; i++) {
      if (!value[i].name) {
        return
      }

      if (value[i].size > byteSize) {
        return `${dictionary.errors.fileSize} ${fileSizeNumber}Мб`
      }
    }
  }

export default fileSize
