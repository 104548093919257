import React, { useMemo, useState } from 'react'

import { ErrorMessage } from 'formik'
import { useRouter } from 'next/router'
import { connect, ConnectedProps } from 'react-redux'

import pbApi from '$api/promobuilding/pbApi'

import { useAppSelector } from '$store/hooks'
import { showPopup } from '$store/slices/popup'
import { PopupConfig } from '$store/slices/popup/types'
import { setEditableData } from '$store/slices/user'
import { AppDispatch, RootState } from '$store/store'

import useFormSubmitHandler from '$hooks/useFormSubmitHandler/useFormSubmitHandler'
import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'
import useTimer from '$hooks/useTimer/useTimer'
import useValidators from '$hooks/useValidators/useValidators'

import FormikConstructor from '$components/form/formik/constructor/FormConstructor/formikConstructor'
import validatorCombiner from '$components/form/validators/validatorCombiner'

import SingleSymbolField from '$form/fields/formikField/field/SingleSymbolField/SingleSymbolField'
import initializeValue from '$form/formik/utils/InitializeFormikValues'

import numWord from '$utils/numWord/numWord'

import classes from './ConfirmPhoneForm.module.scss'

const countConfirmField = ['num1', 'num2', 'num3', 'num4'] as const
const ConfirmPhoneForm: React.FC<Props> = ({
  showPopupAction,
  setEditableDataAction,
  popupData,
  verifyPhoneMethod,
}) => {
  const [indexFocusElem, setIndexFocusElem] = useState(-1)
  const [remaining, reset] = useTimer(60)
  const initialValues = useMemo(
    () => initializeValue([...countConfirmField, 'code'])(),
    [],
  )
  const router = useRouter()
  const { isRequired } = useValidators()
  const dictionary = useLanguageDictionary()
  const isEmailVerified = useAppSelector((store) => store.user.emailIsVerified)

  const confirmPhone = useFormSubmitHandler<typeof initialValues>(
    [
      (values) => {
        const code = countConfirmField.reduce((acc, v) => acc + values[v], '')
        return { ...values, code }
      },
    ],
    (values, { setFieldError }) =>
      pbApi
        .confirmPhone(values.code!)
        .then(() => {
          router.push('/account/room')
          showPopupAction({
            title: dictionary.messages.thanks,
            message: isEmailVerified
              ? 'Номер успешно подтвержден'
              : dictionary.messages.successRegistration,
            type: 'success',
          })
          setEditableDataAction()
        })
        .catch(() => {
          countConfirmField.forEach((item) => {
            setFieldError(item, 'error')
          })
        }),
  )

  return (
    <FormikConstructor
      name="confirmphone"
      initialValues={initialValues}
      onSubmit={confirmPhone}
      submitButtonName={dictionary.button.confirmPhoneNumber}
    >
      <div className={classes['codeBlock']}>
        <div className={classes['fieldRow']}>
          {countConfirmField.map((item, index) => (
            <SingleSymbolField
              key={index}
              validate={validatorCombiner([isRequired])}
              activeIndex={indexFocusElem}
              index={index}
              name={item}
              setActiveIndex={setIndexFocusElem}
              type="number"
              disableError
            />
          ))}
        </div>
        <div className={classes['errorCode']}>
          <ErrorMessage name="code" />
        </div>
      </div>
      <div className={classes['repeatSendCode']}>
        {remaining === 0 ? (
          <div>
            <span
              onClick={() => {
                pbApi
                  .getPhoneCode(popupData.phone)
                  .then(() => {
                    reset()
                  })
                  .catch(({ response: { data = {} } = {} }) => {
                    const err = data.errors?.[0] ?? 'Непредвиденная ошибка'
                    showPopupAction({
                      title: err,
                      type: 'error',
                    })
                  })
              }}
              className={classes['sendCode']}
            >
              {verifyPhoneMethod === 'call'
                ? dictionary.other.retryCall
                : dictionary.other.retrySend}
            </span>
          </div>
        ) : (
          <div className="wait-code">
            {dictionary.other.retryAllow} <br /> {remaining}{' '}
            {numWord(remaining, ['секунду', 'секунды', 'секунд'])}
          </div>
        )}
      </div>
    </FormikConstructor>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({
    popupData: state.popup.popupConfig.popupData,
    verifyPhoneMethod: state.config.auth?.phoneConfirmConfig.verify_method,
  }),
  (dispatch: AppDispatch) => ({
    showPopupAction: (config: PopupConfig) => {
      dispatch(showPopup(config))
    },
    setEditableDataAction: () => {
      dispatch(
        setEditableData({
          phoneIsVerified: true,
        }),
      )
    },
  }),
)

export default connector(ConfirmPhoneForm)
