import React, { KeyboardEvent, useCallback, useEffect, useRef } from 'react'

import classNames from 'classnames'
import { FieldInputProps } from 'formik'

import { SingleSymbolFieldInterface } from '../../formikField/type/FormikFieldType'

const singleInputWrapperClasses = classNames('single-input-wrapper')
const singleInputWrapperFieldClasses = classNames('single-input-wrapper__field')
const singleInputWrapperInputClasses = classNames('single-input-wrapper__input')

const SingleInput: React.FC<
  FieldInputProps<SingleSymbolFieldInterface> & SingleSymbolFieldInterface
> = ({ index, activeIndex, value, name, onBlur, setActiveIndex, ...props }) => {
  const ref = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (ref.current) {
      if (index === activeIndex) {
        ref.current.focus()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex])

  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.code === 'Backspace') {
        const target = e.currentTarget
        if (target.value.length > 0) {
          target.value = ''
          return
        }
        if (index !== 0) {
          setActiveIndex(index - 1)
        }
      }
    },
    [setActiveIndex, index],
  )

  const onInput = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      const target = e.currentTarget
      if (target.value.length > 1) {
        // eslint-disable-next-line prefer-destructuring
        target.value = e.currentTarget.value[0]
      }
      if (target.value.length > 0) {
        setActiveIndex(index + 1)
      }
    },
    [index, setActiveIndex],
  )

  return (
    <div className="field-wrapper">
      <div className={singleInputWrapperClasses}>
        <div className={singleInputWrapperFieldClasses}>
          <input
            name={name}
            ref={ref}
            min="0"
            max="9"
            className={singleInputWrapperInputClasses}
            onInput={onInput}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            {...props}
          />
        </div>
      </div>
    </div>
  )
}

export default SingleInput
