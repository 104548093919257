import React, { ReactNode, useEffect, useMemo, useState } from 'react'

export type ViewportServiceInstanceType = {
  isMobile: boolean
  isTablet: boolean
  isLaptop: boolean
}

const ViewportContext = React.createContext<ViewportServiceInstanceType>({
  isMobile: false,
  isTablet: false,
  isLaptop: false,
})

const ViewportService: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isMobile, setViewportMobileMode] = useState(false)
  const [isTablet, setViewportTabletMode] = useState(false)
  const [isLaptop, setViewportLaptopMode] = useState(false)

  const viewportServiceInstance: ViewportServiceInstanceType = useMemo(
    () => ({
      isMobile,
      isTablet,
      isLaptop,
    }),
    [isMobile, isTablet, isLaptop],
  )

  useEffect(() => {
    setViewportMobileMode(window.innerWidth < 768)
    setViewportTabletMode(window.innerWidth < 1024)
    setViewportLaptopMode(window.innerWidth < 1280)
  }, [])

  useEffect(() => {
    window.onresize = () => {
      requestAnimationFrame(() => {
        const modeMobile = window.innerWidth < 768
        const modeTablet = window.innerWidth < 1024
        const modeLaptop = window.innerWidth < 1280
        if (modeMobile !== isMobile) {
          setViewportMobileMode(modeMobile)
        }
        if (modeTablet !== isTablet) {
          setViewportTabletMode(modeTablet)
        }
        if (modeLaptop !== isLaptop) {
          setViewportLaptopMode(modeLaptop)
        }
      })
    }
  }, [isMobile, isTablet, isLaptop])

  return (
    <ViewportContext.Provider value={viewportServiceInstance}>
      {children}
    </ViewportContext.Provider>
  )
}

export { ViewportContext }
export default ViewportService
