import { useMemo } from 'react'

import { useAppSelector } from '$store/hooks'

export default function BodyHtml() {
  const html = useAppSelector((state) => state.config.site?.html_in_body)

  const body = useMemo(() => html?.map(({ code }) => code).join(), [html])

  if (!body) {
    return null
  }

  return (
    <div
      className="insert-body-html"
      style={{ display: 'none' }}
      dangerouslySetInnerHTML={{ __html: body }}
    />
  )
}
