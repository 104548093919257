import React, { useCallback, useState, useMemo, useEffect } from 'react'

import { connect, ConnectedProps } from 'react-redux'

import popupsID, { PopupType } from '$constants/common/popupsID/popupsID'

import { showPopup } from '$store/slices/popup'
import { PopupConfig } from '$store/slices/popup/types'
import { AppDispatch, RootState } from '$store/store'

import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'
import usePromoStatus from '$hooks/usePromoStatus/usePromoStatus'

import Button from '$components/ui/buttons/RootButton/Button'

import classes from './UploadCheckPopup.module.scss'

const UploadCheckPopup: React.FC<Props> = ({
  showPopupAction,
  uploadMethods,
}) => {
  const dictionary = useLanguageDictionary()

  const uploadMethodsForUse = [
    uploadMethods[1],
    uploadMethods[0],
    uploadMethods[2],
  ]

  const uploadMethodsLabel = useMemo(
    () => ({
      manual: {
        name: 'Ручной ввод',
        popup: popupsID.upload_manual_check,
        datatest: 'manual-button',
      },
      photo: {
        name: 'Загрузка фото',
        popup: popupsID.upload_photo_check,
        datatest: 'photo-button',
      },
      qr: {
        name: 'Сканирование qr-кода',
        popup: popupsID.upload_qr_check,
        datatest: 'qr-button',
      },
    }),
    [dictionary],
  )

  const [isNotHaveMediaDevice, setNoMediaDevice] = useState(false)

  useEffect(() => {
    try {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          stream.getTracks().forEach((track) => track.stop())
          setNoMediaDevice(false)
        })
        .catch(() => setNoMediaDevice(true))
    } catch (error) {
      setNoMediaDevice(true)
    }
  }, [])

  const isNotEmptyUploadMethods = !!uploadMethods.length
  const openUploadCheck = useCallback(
    (popupId: PopupType) => async () => {
      showPopupAction({
        popupId,
      })
    },
    [showPopupAction],
  )

  const promoStatusHandler = usePromoStatus(true, {
    title: 'Прием чеков окончен',
  })
  if (promoStatusHandler()) {
    return null
  }

  const Buttons = uploadMethodsForUse.map((item, index) => (
    <div key={`upload-methods-${index}`} className={classes['block']}>
      <Button
        disabled={item === 'qr' ? isNotHaveMediaDevice : false}
        dataTest={uploadMethodsLabel[`${item}`].datatest}
        onClick={openUploadCheck(
          uploadMethodsLabel[`${item}`].popup /* , item */,
        )}
      >
        {uploadMethodsLabel[`${item}`].name}
      </Button>
    </div>
  ))

  return (
    <div className="form-popup">
      <div>
        {isNotEmptyUploadMethods ? (
          <>
            <h2 className="popup__title popup__title_md">
              {dictionary.header.uploadMethod}
            </h2>
            <p className={classes['text']}>Выберите способ загрузки</p>
            {Buttons}
          </>
        ) : (
          <div style={{ textAlign: 'center' }}>
            Не включен ни один из возможных способов загрузки чеков.
          </div>
        )}
      </div>
    </div>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({
    uploadMethods: state.config.check?.checkUploadMethods!,
  }),
  (dispatch: AppDispatch) => ({
    showPopupAction: (config: PopupConfig) => {
      dispatch(showPopup(config))
    },
  }),
)

export default connector(UploadCheckPopup)
