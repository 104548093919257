import Head from 'next/head'

import { useAppSelector } from '$store/hooks'

export default function Favicon() {
  const favicon = useAppSelector((state) => state.config.promo.favicon)

  return (
    <Head>
      <link
        rel="icon"
        href={favicon ?? `${process.env['NEXT_PUBLIC_URL'] ?? ''}/favicon.ico`}
      />
    </Head>
  )
}
