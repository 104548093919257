import { PropsWithChildren, useEffect } from 'react'

import useFingerprint from '$hooks/useFingerprint'

import ViewportService from '$services/ViewportServices/ViewportServices'

import Favicon from '$components/Favicon'
import BodyHtml from '$components/helpers/html/BodyHtml'
import HeadHtml from '$components/helpers/html/HeadHtml'
import MadeWithPromoBuilding from '$components/helpers/html/MadeWithPromobuilding'
import PromoTrap from '$components/helpers/trap/PromoTrap'
import PopupManagement from '$components/popups/PopupManagement'

import MetricsWrapper from '$/other/metrix/MetricsWrapper'
import VKPixel from '$/other/VKPixel/VKPixel'

type MainAppProps = PropsWithChildren

export default function MainApp({ children }: MainAppProps) {
  const setFingerprint = useFingerprint()

  useEffect(() => {
    setFingerprint()
  }, [setFingerprint])

  const catchResizeObserverError = (e: unknown) => {
    if (
      e instanceof Error &&
      e.message !== 'ResizeObserver loop limit exceeded'
    ) {
      throw e
    }
  }

  useEffect(() => {
    window.addEventListener('error', catchResizeObserverError)

    return () => window.removeEventListener('error', catchResizeObserverError)
  }, [])

  return (
    <>
      <HeadHtml />
      <Favicon />
      <PopupManagement />
      <PromoTrap>
        <ViewportService>{children}</ViewportService>
      </PromoTrap>
      <BodyHtml />
      <MadeWithPromoBuilding />
      <MetricsWrapper />
      <VKPixel />
    </>
  )
}
