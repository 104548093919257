const controlStaticStyle = {
  boxShadow: 'none',
  fontWeight: 400,
  border: '1px solid rgba(188, 149, 92, 1)',
  '::before': {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: 15,
    height: 15,
    marginRight: 5,
  },
}

const valueContainerStaticStyle = {
  flexWrap: 'nowrap' as any,
  maxWidth: '100%',
  padding: '11px 0 0',
  overflow: 'visible',
  position: 'relative' as any,
  '::before': {
    color: 'black',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    position: 'absolute' as any,
    width: '100%',
    height: 18,
  },
}

const multiValueStaticStyle = {
  marginRight: 2,
  flexGrow: 1,
  alignItems: 'start',
  overflow: 'hidden',
  color: 'black',
}

const multiValueRemoveStaticStyle = {
  margin: '2px',
  marginLeft: 3,
  cursor: 'pointer',
  borderRadius: 2,
  path: {
    transition: 'fill .3s',
  },
}

const singleValueStaticStyle = {
  fontWeight: 400,
  color: '#000000',
  margin: 0,
}

const inputStaticStyle = {
  fontWeight: 400,
  color: 'black',
}

const menuListStaticStyle = {
  padding: 0,
  backgroundColor: '#e8ecf3',
  '::-webkit-scrollbar': {
    width: 9,
    height: 3,
    backgroundColor: '#e8ecf3',
  },
  '::-webkit-scrollbar-thumb': {
    borderLeft: '2px solid #e8ecf3',
    borderRight: '2px solid #e8ecf3',
    borderRadius: 8,
    backgroundColor: '#e8ecf3',
    width: 5,
  },
}

const menuStaticStyle = {
  width: '100%',
  backgroundColor: 'white',
  borderRadius: 0,
  marginTop: 0,
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
  overflow: 'hidden',
  zIndex: '2',
}

const placeholderStaticStyle = {
  transition: 'inherit',
  position: 'absolute' as any,
  fontSize: 12,
  color: '#000000',
  opacity: 0.5,
  top: -18,
  margin: 0,
}

const optionStaticStyle = {
  position: 'relative' as any,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  width: '100%',
  '.option--is-selected': {
    background: 'black',
  },
  '::before': {
    display: 'flex',
    minWidth: 19,
    minHeight: 19,
    border: '1px solid',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderRadius: 6,
    marginRight: 10,
  },
  ':not(:first-of-type)': {
    marginTop: 1,
  },
}

export {
  controlStaticStyle,
  inputStaticStyle,
  menuListStaticStyle,
  menuStaticStyle,
  multiValueRemoveStaticStyle,
  multiValueStaticStyle,
  optionStaticStyle,
  singleValueStaticStyle,
  valueContainerStaticStyle,
  placeholderStaticStyle,
}
