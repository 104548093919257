import { DictionaryType } from '$hooks/useLanguageDictionary/useLanguageDictionary'

const isRequired = (dictionary: DictionaryType) => (value: any) => {
  if (
    value === '' ||
    value === false ||
    value === null ||
    value === undefined
  ) {
    return dictionary.errors.isRequired
  }
}

export default isRequired
