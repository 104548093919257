import React, { useEffect, useMemo, useRef } from 'react'


import classNames from 'classnames'
import { useField } from 'formik'

import FileInput from '$form/fields/basicInput/FileInput/FileInput'
import { FileFieldInterface } from '$form/fields/formikField/type/FormikFieldType'
import Error from '$form/fields/other/errors/Error'

import scrollToErrorField from '$utils/scrollToErrorField/scrollToErrorField'

import s from './imageUploadField.module.scss'

const ImageUploadFileField: React.FC<FileFieldInterface> = ({ ...props }) => {
  const [field, { error, touched }] = useField<FileFieldInterface>(props)
  const isError = useMemo((): boolean => touched && !!error, [touched, error])
  const inputWrapperClasses = classNames('input-wrapper', { error: isError }, s["wrapper"])

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    scrollToErrorField(isError, ref)
  })

  return (
    <div ref={ref} className={inputWrapperClasses}>
      <FileInput {...field} {...props} />
      {isError && <Error error={error ?? ''} />}
    </div>
  )
}

export default ImageUploadFileField
