import React, { useState } from 'react'

import { QrScanner } from '@yudiel/react-qr-scanner'

import pbApi from '$api/promobuilding/pbApi'

import { useAppDispatch } from '$store/hooks'
import { showPopup } from '$store/slices/popup'
import { setUploadChecks } from '$store/slices/user'

import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'

import Preloader from '$components/layoutParts/Preloader/Preloader'

import Error from '$form/fields/other/errors/Error'

import createPopupErrorMiddleware from '$utils/middleware/popupErrorMiddleware'
import { extractValidationErrors } from '$utils/validationError'

import classes from './qrScanner.module.scss'

const QrScannerUploadCheck: React.FC = () => {
  const dictionary = useLanguageDictionary()
  const dispatch = useAppDispatch()

  const [isUpload, setIsUpload] = useState(false)
  const [error, setError] = useState('')

  return (
    <div className={classes['wrapper']}>
      {isUpload ? (
        <div className={classes['scanPreloader']}>
          <Preloader />
        </div>
      ) : (
        <>
          <QrScanner
            constraints={{
              facingMode: 'environment',
            }}
            onError={(error) => {
              dispatch(
                showPopup({
                  type: 'error',
                  title: 'Ошибка',
                  message: error?.message,
                }),
              )
            }}
            onDecode={(code: any) => {
              // console.log(code)
              if (code) {
                pbApi
                  .qrUploadCheck(code)
                  .then((res) => {
                    dispatch(
                      showPopup({
                        type: 'success',
                        title: dictionary.messages.checkIsUpload,
                        message: dictionary.messages.successCheck,
                      }),
                    )
                    dispatch(setUploadChecks(res.data.checks))
                    setError('')
                  })
                  .catch(createPopupErrorMiddleware(showPopup))
                  .catch((err) => {
                    const data = extractValidationErrors(err, false)
                    if (!data) {
                      return
                    }
                    // eslint-disable-next-line
                    for (const key in data) {
                      setError(data[key])
                    }
                  })
                  .finally(() => {
                    setIsUpload(false)
                  })
                setIsUpload(true)
              }
            }}
            containerStyle={{ width: '100%' }}
            videoStyle={{ width: '100%', height: '100%' }}
          />
          <div className={classes['qrScannerFinder']} />
        </>
      )}
      {!!error && <Error error={error} />}
    </div>
  )
}

export default QrScannerUploadCheck
