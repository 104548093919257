import React, { useCallback, ChangeEvent, PropsWithChildren } from 'react'

import { useFormikContext } from 'formik'

import RootCheckBox from '$ui/fields/RootCheckBox/index'

import { CheckBoxFormikFieldInterface } from '$components/form/types/FormikFieldType'

import FieldWrapperConstructor from '../../constructor/FieldWrapperConstructor/FieldWrapperConstructor'

import s from './CheckBoxField.module.scss'

const CheckBoxInputField: React.FC<
  PropsWithChildren<CheckBoxFormikFieldInterface>
> = (props) => {
  const { name } = props
  const { setFieldValue } = useFormikContext()
  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFieldValue(name, e.target.checked)
    },
    [name, setFieldValue],
  )

  return (
    <FieldWrapperConstructor
      className={s['small-space']}
      component={RootCheckBox}
      {...{ onChange, ...props }}
    />
  )
}

export default CheckBoxInputField
