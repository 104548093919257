const messages = {
  passwordSuccessChanged: 'Құпия сөз сәтті өзгертілді!',
  successRegistration:
    'Тіркеуді аяқтау үшін поштаңызға жіберілген хаттың сілтемесіне өтіңіз.',
  restorePassword:
    'Құпия сөзді қалпына келтіру үшін поштаңызға жіберілген Электрондық поштаның сілтемесіне өтіңіз.',
  sendFeedBack: 'Рахмет, сіздің хабарламаңыз жіберілді',
  successSendData: 'Деректер сәтті жіберілді',
  needVerifiedEmail: 'Поштаны растаңыз',
  promocodeIsUpload: 'Жарнамалық код жіберілді',
  reviewIsUpload: 'Сіздің пікіріңіз модерацияға жіберілді!',
  successReview: 'Оны Тексеру нәтижесі туралы сіз поштаға хабарлама аласыз',
  checkIsUpload: 'Чек жүктелді және модерацияға жіберілді',
  successCheck:
    'Тексеру нәтижелері бойынша Сіз E‑mail тіркеу кезінде көрсетілген хабарлама аласыз',
  thanks: 'Рахмет!',
}

export default messages
