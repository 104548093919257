// KZ
import buttonKZ from './KZ/button'
import errorsKZ from './KZ/errors'
import fieldsKZ from './KZ/fields'
import headersKZ from './KZ/headers'
import linksKZ from './KZ/links'
import messagesKZ from './KZ/messages'
import otherKZ from './KZ/other'

// RU
import buttonRU from './RU/button'
import errorsRU from './RU/errors'
import fieldsRU from './RU/fields'
import headersRU from './RU/headers'
import linksRU from './RU/links'
import messagesRU from './RU/messages'
import otherRU from './RU/other'

const DICTIONARY = {
  kz: {
    button: buttonKZ,
    header: headersKZ,
    fields: fieldsKZ,
    links: linksKZ,
    other: otherKZ,
    messages: messagesKZ,
    errors: errorsKZ,
  },
  ru: {
    button: buttonRU,
    header: headersRU,
    fields: fieldsRU,
    links: linksRU,
    other: otherRU,
    messages: messagesRU,
    errors: errorsRU,
  },
}

export default DICTIONARY
