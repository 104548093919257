import React, { ReactNode } from 'react'

import UploadCheckHint from '$components/hint/QuestionsHint/QuestionsHint'

import HintWrapper from '../HintWrapper/HintWrapper'

import datetime from '$assets/img/hint/datetime.png'
import fd from '$assets/img/hint/fd.png'
import fn from '$assets/img/hint/fn.png'
import fp from '$assets/img/hint/fp.png'
import sum from '$assets/img/hint/total.png'

interface ChecksFieldHintInterface {
  type: 'fd' | 'fp' | 'fn' | 'datetime' | 'sum'
  children: ReactNode
}

const hint = {
  fd,
  fp,
  fn,
  datetime,
  sum,
}

const ChecksFieldHint: React.FC<ChecksFieldHintInterface> = ({
  type,
  children,
}) => (
  <HintWrapper
    hint={UploadCheckHint}
    preview={{
      type: 'image',
      content: hint[type].src,
    }}
  >
    {children}
  </HintWrapper>
)

export default ChecksFieldHint
