import { PropsWithChildren, useMemo } from 'react'

import { useSelector } from 'react-redux'

import AdultFormLayout from '$layouts/AdultFormLayout'

import { RootState } from '$store/store'

import ErrorManagement from '$components/errors/ErrorsManagement'
import BaseAuthLayout from '$layouts/BaseAuthLayout'

export type ConfigErrorHelperProps = PropsWithChildren<{}>

export default function ConfigErrorTrap({ children }: ConfigErrorHelperProps) {
  const promoConfigErrorConfig = useMemo(
    () => ({
      title: 'Упс!',
      text: 'Не удалось получить конфиг',
      code: '401',
      btnName: 'Попробовать еще',
      external: true,
    }),
    [],
  )

  const adultRequired = useSelector(
    (state: RootState) => state.meta.config === 'CONFIRM_OF_ADULT_IS_REQUIRED',
  )

  const baseAuthRequired = useSelector(
    (state: RootState) => state.meta.config === 'BASIC_AUTH_IS_REQUIRED',
  )

  const error = useSelector(
    (state: RootState) => state.meta.config !== 'loaded',
  )

  if (baseAuthRequired) {
    return <BaseAuthLayout />
  }

  if (adultRequired) {
    return <AdultFormLayout />
  }

  if (error) {
    return <ErrorManagement errorConfig={promoConfigErrorConfig} />
  }

  return <>{children}</>
}
