import axios from 'axios'

import { SelectOption } from '$components/form/fields/formikField/type/FormikFieldType'

const kladrAxios = axios.create({
  baseURL: process.env['NEXT_PUBLIC_KLADR_API'],
  // baseURL: 'https://kladr.promo-api.ru/api',
})

const kladrApi = {
  getRegion: (query: string = '') =>
    kladrAxios.get(`/search-regions?q=${query}`).then((response) => {
      const region: SelectOption = response.data.data.map(
        (item: { name: string }) => ({
          label: item.name,
          value: item.name,
        }),
      )
      return region
    }),
  getCity: (query: string = '') =>
    kladrAxios
      .get(`/search-cities?${query ? `q=${query}` : ''}`)
      .then((response) => {
        const city: SelectOption = response.data.data.map(
          (item: { name: string }) => ({
            label: item.name,
            value: item.name,
          }),
        )
        return city
      }),
}

export default kladrApi
