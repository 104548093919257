import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { TostConfigType, TostStoreType } from '$/types/tostType'

const initialState: TostStoreType = {
  activeState: false,
  tostConfig: {
    id: '',
  },
}

const tostSlice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    showTost: (state, { payload: config }: PayloadAction<TostConfigType>) => ({
      activeState: true,
      tostConfig: config,
    }),

    hideTost: (state) => {
      state.activeState = false
    },
  },
})

export const { hideTost, showTost } = tostSlice.actions

export const { name } = tostSlice

export const slice = tostSlice

export default tostSlice.reducer
