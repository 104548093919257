import React from 'react'

import { useSelector } from 'react-redux'

import { useAppSelector } from '$store/hooks'
import { RootState } from '$store/store'

import MenuNavLink from '../MenuNavLink'

const HeaderLogo: React.FC = () => {
  const logo = useSelector(
    (state: RootState) => state.config.promo.content.logo,
  )

  const logoLink = useAppSelector(
    (store) => store.config.site?.logo_target_link,
  )

  return (
    <div className="logo-container">
      {logo &&
        (logoLink ? (
          <a
            className="header-menu__link logo"
            href={logoLink}
            rel="noreferrer"
            target="_blank"
          >
            <img src={logo} alt="logo" className="logo-image" />
          </a>
        ) : (
          <MenuNavLink to="/" scroll isLogoLink>
            <img src={logo} alt="logo" className="logo-image" />
          </MenuNavLink>
        ))}
    </div>
  )
}

export default HeaderLogo
