import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

import errorsID from '$constants/common/errorsID/errorsID'

import type { ErrorConfigType } from './types'

export interface ErrorState {
  isError: boolean
  errorConfig: ErrorConfigType
}

export const initialState: ErrorState = {
  isError: false,
  errorConfig: {
    id: errorsID.PROMO_IS_END,
    title: 'К сожалению акция уже закончилась :(',
    text: 'Но не отчаивайтесь, сейчас проходят десятки других Акций, в которых тоже можно поучаствовать!',
    btnName: 'Найти подходящую',
    goTo: 'https://promobills.ru/?build',
  },
}

/* eslint no-param-reassign: "error" */
const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    showError: (state, { payload }: PayloadAction<ErrorConfigType>) => {
      state.isError = true
      state.errorConfig = payload
    },
  },
  extraReducers(builder) {
    builder.addCase(HYDRATE, (state, action) => {
      const {
        payload: { error },
      } = action as any

      return {
        ...state,
        ...error,
      }
    })
  },
})

export const { showError } = errorSlice.actions

export const { name } = errorSlice

export const slice = errorSlice

export default errorSlice.reducer
