import React, { createContext, ReactNode, useMemo, useState } from 'react'

interface HeaderContextType {
  isOpenMenu: boolean
  setOpenMenuState: React.Dispatch<React.SetStateAction<boolean>>
}

export const HeaderContext = createContext<HeaderContextType>({
  isOpenMenu: false,
  setOpenMenuState: () => {},
})

const HeaderMenuService: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isOpenMenu, setOpenMenuState] = useState(false)

  const value = useMemo(
    () => ({ isOpenMenu, setOpenMenuState }),
    [isOpenMenu, setOpenMenuState],
  )

  return (
    <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
  )
}

export default HeaderMenuService
