import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux'

import { useAppSelector } from '$store/hooks'
import { showPopup } from '$store/slices/popup'

import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'

import ReviewForms from '$components/form/formik/forms/review/ReviewForms'

const ReviewPopup = () => {
  const dictionary = useLanguageDictionary()
  const user = useAppSelector((store) => store.user)
  const allowedUser = useAppSelector(
    (store) => store.config.reviews?.allowedUsers,
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (allowedUser !== 'all' && !user?.can_post_reviews) {
      dispatch(
        showPopup({
          type: 'error',
          message: 'Вы не можете оставить отзыв',
        }),
      )
    }
  }, [user?.can_post_reviews])

  return (
    <div className="form-popup">
      <h2 className="popup__title popup__title_md">
        {dictionary.header.sendReview}
      </h2>
      <ReviewForms />
    </div>
  )
}

export default ReviewPopup
