import React from 'react'

import { useAppSelector } from '$store/hooks'

import MainContainer from '$components/container/MainContainer/MainContainer'

import MenuNavLink from '../MenuNavLink'

const HeaderMenu: React.FC = () => {
  const headerLinks = useAppSelector((state) => state.config.site?.header_links)
  const { language } = useAppSelector((store) => store.language)
  return (
    <MainContainer>
      <div className="header-menu__item navbar">
        {headerLinks?.map((link: any) => {
          const isPopup = link.uri[0] === '?'
          return (
            <MenuNavLink
              key={`link-${link.label}`}
              isPopup={isPopup}
              to={link.uri}
            >
              {language === 'ru'
                ? link.label
                : link.labels[`label_${language}`]}
            </MenuNavLink>
          )
        })}
      </div>
    </MainContainer>
  )
}

export default HeaderMenu
