import React from 'react'

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

const V3Recaptcha: React.FC<{
  recaptchaRef: React.MutableRefObject<
    () => Promise<{ recaptcha_response: string }>
  >
}> = ({ recaptchaRef }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  recaptchaRef.current = async () => {
    if (!executeRecaptcha) {
      return {
        recaptcha_response: '',
      }
    }
    const token = await executeRecaptcha('yourAction')
    return {
      recaptcha_response: token,
    }
  }

  return null
}

export default V3Recaptcha
