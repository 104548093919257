export type ValidatorType = ((a: any) => string | void) | undefined

type ValidatorCombinerType = (
  a: Array<ValidatorType | undefined>,
) => (values: string) => string | void

const validatorCombiner: ValidatorCombinerType =
  (validators: Array<ValidatorType>) => (values: string) => {
    for (const validator of validators) {
      if (!validator) {
        return
      }
      const error = validator(values)
      if (error) {
        return error
      }
    }
  }

export default validatorCombiner
