import { dictionary } from '$hooks/useLanguageDictionary/useLanguageDictionary'

const maxNumbValue =
  (dictionary: dictionary) =>
  (max: number) =>
  (value: string = '') => {
    if (value) {
      if (Number(value) > max) {
        return `${dictionary.errors.maxVal} ${max}`
      }
    }
  }

export default maxNumbValue
