export const DropDownIconBig: React.FC<any> = ({
  innerProps,
  selectProps: { menuIsOpen },
}) => (
  <div
    {...innerProps}
    style={{
      transform: `rotate(${menuIsOpen ? '180deg' : '0deg'})`,
      transition: 'all .3s',
      cursor: 'pointer',
      position: 'relative',
      ...(!menuIsOpen
        ? {
            left: -2,
            top: 2,
          }
        : {
            left: -2,
            top: -4,
          }),
    }}
  >
    <svg
      width="19"
      height="12"
      viewBox="0 0 19 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1.5L9.5 10.5L18 1.5"
        stroke="#AA182C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
)
