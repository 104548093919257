import { extractValidationErrors } from '$utils/validationError'

const fieldErrorMiddleware =
  (
    setFieldError: (field: string, message: string | undefined) => void,
    rethrow = true,
  ) =>
  (data: any) => {
    const errors = extractValidationErrors(data)
    if (errors) {
      Object.entries(errors).forEach(([key, value]) => {
        setFieldError(key, Array.isArray(value) ? value[0] : value)
      })
    } else {
      throw data
    }

    if (rethrow) {
      throw data
    }
  }

export default fieldErrorMiddleware
