import s from './BaseAuthBlock.module.scss'
import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'
import BaseAuthForm from '$components/form/formik/forms/auth/baseAuthForm/BaseAuthForm'

const BaseAuthBlock = () => {
  const dictionary = useLanguageDictionary()

  return (
    <div className={s['wrapper']}>
      <h2 className="popup__title popup__title_md">
        {dictionary.header.signIn}
      </h2>
      <BaseAuthForm />
    </div>
  )
}

export default BaseAuthBlock
