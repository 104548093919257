import React, { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'

import { showPopup } from '$store/slices/popup'

import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'

import PhotoUploadCheckForm from '$components/form/formik/forms/uploadCheck/PhotoUploadCheckForm/PhotoUploadCheckForm'

import s from '../checkUploadCommon.module.scss'

const PhotoUploadCheckPopup: React.FC = () => {
  const [isNotHaveMediaDevice, setNoMediaDevice] = useState(true)

  useEffect(() => {
    try {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          stream.getTracks().forEach((track) => track.stop())
          setNoMediaDevice(false)
        })
        .catch(() => setNoMediaDevice(true))
    } catch (error) {
      setNoMediaDevice(true)
    }
  }, [])

  const dictionary = useLanguageDictionary()
  const dispatch = useDispatch()

  const toManual = () =>
    dispatch(
      showPopup({
        popupId: 'upload_manual_check',
      }),
    )

  const toQr = async () => {
    dispatch(
      showPopup({
        popupId: 'upload_qr_check',
      }),
    )
  }

  return (
    <div className="form-popup">
      <h2 className="popup__title popup__title_md">
        {dictionary.header.photoUploadCheck}
      </h2>
      <a
        className={s['link']}
        target="_blank"
        href="https://kungur-promo.ru/uploadcheck.pdf"
      >
        Как сделать фото?
      </a>
      <PhotoUploadCheckForm />
      <button
        className={s['buttonLink']}
        disabled={isNotHaveMediaDevice}
        onClick={toQr}
      >
        Сканирование QR-кода
      </button>
      <button className={s['buttonLink']} onClick={toManual}>
        Ручной ввод данных чека
      </button>
    </div>
  )
}

export default PhotoUploadCheckPopup
