import React from 'react'

import TextAreaInput from '$form/fields/basicInput/TextAreaInput/TextAreaInput'

import FieldWrapperConstructor from '../../../../formik/constructor/FieldWrapperConstructor/FieldWrapperConstructor'
import { TextFormikFieldInterface } from '../../type/FormikFieldType'

const TextareaField: React.FC<TextFormikFieldInterface> = (props) => (
  <FieldWrapperConstructor component={TextAreaInput} {...props} />
)

export default TextareaField
