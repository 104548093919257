import React from 'react'

import SingleInput from '$form/fields/basicInput/SingleValueInput/SingleInput'

import FieldWrapperConstructor from '../../../../formik/constructor/FieldWrapperConstructor/FieldWrapperConstructor'
import { SingleSymbolFieldInterface } from '../../type/FormikFieldType'

const SingleSymbolField: React.FC<SingleSymbolFieldInterface> = (props) => (
  <FieldWrapperConstructor component={SingleInput} {...props} />
)

export default SingleSymbolField
