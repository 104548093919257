import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'

import fileFormat from '$components/form/validators/fileFormat'
import fileSize from '$components/form/validators/fileSize'
import isInteger from '$components/form/validators/isInteger'
import isRequired from '$components/form/validators/isRequired'
import isRequiredCheckbox from '$components/form/validators/isRequiredCheckBox'
import isTestRegular from '$components/form/validators/isTestRegular'
import isValidEmail from '$components/form/validators/isValidEmail'
import isValidMiddleName from '$components/form/validators/isValidMiddleName'
import isValidName from '$components/form/validators/isValidName'
import latinAndDigit from '$components/form/validators/latinAndDigits'
import maxLength from '$components/form/validators/maxLength'
import maxNumbValue from '$components/form/validators/maxNumbValue'
import minLength from '$components/form/validators/minLength'
import minNumbValue from '$components/form/validators/minNumbValue'
import notAllowFutureTime from '$components/form/validators/notAllowFutureTime'
import onlyCyrillic from '$components/form/validators/onlyCyrillic'
import onlyCyrillicAndOther from '$components/form/validators/onlyCyrillicAndOther'
import passportIssuedBy from '$components/form/validators/passportIssuedBy'

const useValidators = () => {
  const dictionary: any = useLanguageDictionary()

  const validators = {
    fileFormat,
    fileSize,
    isRequired,
    isRequiredCheckbox,
    isValidEmail,
    isValidName,
    isValidMiddleName,
    maxLength,
    minLength,
    notAllowFutureTime,
    onlyCyrillic,
    onlyCyrillicAndOther,
    isTestRegular,
    latinAndDigit,
    maxNumbValue,
    minNumbValue,
    isInteger,
    passportIssuedBy,
  }

  const collectionOfValidators = Object.entries(validators).reduce(
    (acc, item) => {
      const key = item[0] as keyof typeof validators
      const func = item[1]
      acc[key] = func(dictionary)
      return acc
    },
    // eslint-disable-next-line
    new Object() as Record<keyof typeof validators, any>,
  )

  return collectionOfValidators
}

export default useValidators
