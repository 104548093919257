import React, { useEffect } from 'react'

import ReactGA from 'react-ga4'
import { connect, ConnectedProps } from 'react-redux'
import { YMInitializer } from 'react-yandex-metrika'

import { RootState } from '$store/store'

const YandexMetrika: React.FC<{ YM: number }> = ({ YM }) => (
  <YMInitializer accounts={[YM]} />
)

const GoogleAnalytics = ({ GA }: { GA: string }) => {
  console.log('Google Analytics is working')
  ReactGA.initialize([
    {
      trackingId: GA,
    },
  ])
  ReactGA.send('pageview')
}

const MetricsWrapper: React.FC<Props> = ({ GA, YM }) => {
  useEffect(() => {
    // eslint-disable-next-line
    GA && GoogleAnalytics({ GA })
  }, [GA])

  return <div>{YM && <YandexMetrika YM={+YM} />}</div>
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({
    YM: state.config.promo?.yandex_metrika,
    GA: state.config.promo?.google_analytics,
    GTM: state.config.promo?.google_tag_manager,
  }),
  () => ({}),
)

export default connector(MetricsWrapper)
