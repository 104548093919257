const headers = {
  promoCodesUpload: 'Промокодты жүктеу',
  promoCheckUpload: 'Чекті жүктеу',
  promoProducts: 'Акциялық өнім',
  whereBuy: 'Қайдан сатып алуға болады',
  reviews: 'Пікірлер',
  help: 'Көмек',
  account: 'Жеке кабинет',
  businessChallenge: 'Бизнем-міндеттер',
  profile: 'Бейін',
  changePassword: 'Құпия сөзді өзгерту',
  faq: 'Жиі қойылатын сұрақтар',
  news: 'Жаңалықтар',
  personalData: 'Дербес деректер',
  signIn: 'Кіру',
  signUp: 'Тіркеу',
  restorePassword: 'Құпия сөзді қалпына келтіріңіз',
  feedback: 'Кері байланыс',
  newPassword: 'Құпия сөзді өзгерту',
  uploadMethod: 'Жүктеу әдісі',
  confirmPhone: 'Телефон нөмірін растау',
  handInsertCheck: 'Чекті қолмен енгізу',
  photoUploadCheck: 'Чек фотосуретін жүктеу',
  qrUploadCheck: 'QR кодын сканерлеңіз',
  sendReview: 'Пікір қалдыру',
  emailVerification: 'Сіздің Email расталды',
  main: 'Басты бет',
  products: 'Өнімдер',
  uploadFiles: 'Жүктелген файл',
  myCodes: 'Менің кодтарым',
  myChecks: 'Менің чектерім',
  code: 'Код',
  uploadDate: 'Жүктеу күні',
  uploadDateTime: 'Жүктеу күні мен уақыты',
  status: 'Мәртебесі',
  rejectReason: 'Ауытқу себебі',
  codeIsEmpty: 'Жүктелген кодтар жоқ',
  checkIsEmpty: 'Жүктелген чектер жоқ',
  consent: 'Қолхат',
  checkId: 'ID чека',

  review: (date: string | null | undefined) => `Шолу${date ? ` ${date}` : ''}`,
}

export default headers
