import React, { memo, useCallback, useContext, useMemo, useEffect } from 'react'

import classNames from 'classnames'

import { useAppSelector } from '$store/hooks'

import useScroll from '$hooks/useScroll/useScroll'

import HeaderMenuService, {
  HeaderContext,
} from '$services/HeaderMenuService/HeaderMenuService'
import { ViewportContext } from '$services/ViewportServices/ViewportServices'

import HeaderLanguage from './HeaderParts/HeaderLanguage'
import HeaderLogo from './HeaderParts/HeaderLogo'
import HeaderMenu from './HeaderParts/HeaderMenu'
import HeaderSign from './HeaderParts/HeaderSign'

const headerBlockClasses = classNames('header-container header__block')
const headerLogoClasses = classNames('header-logo-block')
const headerBurgerClasses = classNames('header-burger')

const Header: React.FC = () => {
  const fixedHeader = useAppSelector((store) => store.config.site?.header_fixed)

  const { isLaptop } = useContext(ViewportContext)
  const { isOpenMenu, setOpenMenuState } = useContext(HeaderContext)

  useScroll(isOpenMenu)

  const headerMenuClasses = useMemo(
    () =>
      classNames('header-menu', {
        'header-menu_open': isOpenMenu,
      }),
    [isOpenMenu],
  )

  const openMenu = useCallback(() => {
    setOpenMenuState((isOpen) => !isOpen)
  }, [setOpenMenuState])

  useEffect(() => {
    if (!isLaptop && isOpenMenu) {
      setOpenMenuState(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLaptop])

  return (
    <header className={classNames('header', { fixed: fixedHeader })}>
      <div className={headerBlockClasses}>
        <div className={headerLogoClasses}>
          <HeaderLogo />
        </div>
        <div className={headerMenuClasses}>
          <HeaderMenu />
          <HeaderSign />
          <HeaderLanguage />
        </div>
        {isLaptop && (
          <div className="burger-container">
            {isOpenMenu ? (
              <div onClick={openMenu} className="close-burger" />
            ) : (
              <div onClick={openMenu} className="burger-wrapper">
                <div className={headerBurgerClasses} />
              </div>
            )}
          </div>
        )}
      </div>
    </header>
  )
}

// eslint-disable-next-line react/display-name
const HeaderWithMenuContext = memo(() => (
  <HeaderMenuService>
    <Header />
  </HeaderMenuService>
))

export default HeaderWithMenuContext
