import React, { useMemo } from 'react'

import { connect, ConnectedProps } from 'react-redux'

import pbApi from '$api/promobuilding/pbApi'

import { ChecksType } from '$store/slices/config/types/user/userType'
import { showPopup } from '$store/slices/popup'
import { PopupConfig } from '$store/slices/popup/types'
import { setUploadChecks } from '$store/slices/user'
import { AppDispatch, RootState } from '$store/store'

import useFormSubmitHandler from '$hooks/useFormSubmitHandler/useFormSubmitHandler'
import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'

import FormikConstructor from '$components/form/formik/constructor/FormConstructor/formikConstructor'

import ImageUploadFileField from '$form/fields/formikField/field/ImageUploadField/ImageUploadField'
import initializeValue from '$form/formik/utils/InitializeFormikValues'

const PhotoUploadCheckForm: React.FC<Props> = ({
  showPopupAction,
  updateUserChecks,
  recaptcha,
}) => {
  const initialValues = useMemo(() => initializeValue(['photos'])(), [])
  const dictionary = useLanguageDictionary()
  const sendCheckPhotos = useFormSubmitHandler<typeof initialValues>(
    [],
    async (value) => {
      const formData: FormData = new FormData()
      Object.entries(value).forEach(([k, v]) => {
        if (k === 'photos') {
          for (let i = 0; i < value.photos!.length; i++) {
            formData.append(`photos[]`, value.photos![i])
          }
          return
        }
        formData.append(k, v)
      })

      await pbApi
        .photoUploadCheck(formData)
        .then((res) => {
          updateUserChecks(res.data.checks)
          showPopupAction({
            type: 'success',
            title: dictionary.messages.checkIsUpload,
            message: dictionary.messages.successCheck,
          })
        })
        .catch(() => undefined)
    },
  )

  return (
    <FormikConstructor
      onSubmit={sendCheckPhotos}
      name="checks/promocodes"
      initialValues={initialValues}
      // submitButtonName={dictionary.button.checkUpload}
      dataTest="upload-check-button"
      recaptcha={recaptcha}
    >
      <ImageUploadFileField
        place="checkUpload"
        format="droparea"
        name="photos"
      />
    </FormikConstructor>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({
    recaptcha: state.config.check?.recaptcha,
  }),
  (dispatch: AppDispatch) => ({
    updateUserChecks: (data: Array<ChecksType>) => {
      dispatch(setUploadChecks(data))
    },
    showPopupAction: (config: PopupConfig) => {
      dispatch(showPopup(config))
    },
  }),
)

export default connector(PhotoUploadCheckForm)
