import React from 'react'

import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'

import FeedbackForms from '$components/form/formik/forms/feedback/FeedbackForms'

const FeedbackPopup = () => {
  const dictionary = useLanguageDictionary()
  return (
    <div className="form-popup">
      <h2 className="popup__title popup__title_md">
        {dictionary.header.feedback}
      </h2>
      <FeedbackForms />
    </div>
  )
}

export default FeedbackPopup
