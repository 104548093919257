enum AuthFieldNames {
  email = 'email',
  login = 'login',
  first_name = 'first_name',
  last_name = 'last_name',
  middle_name = 'middle_name',
  password = 'password',
  region = 'region',
  city = 'city',
  phone = 'phone',
  promo_rules = 'promo_rules',
  upload_checks = 'upload_checks',
  custom_input = 'custom_input',
  confirmation_of_majority = 'confirmation_of_majority',
  confidentiality_agreement = 'confidentiality_agreement',
  receiving_promotional_mailing = 'receiving_promotional_mailing',
  receiving_promotional_mailing_acceptance_is_required = 'receiving_promotional_mailing_acceptance_is_required',
}

export default AuthFieldNames
