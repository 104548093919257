import axios from 'axios'

const pbConfig = {
  baseURL:
    // typeof window === 'undefined' ||
    process.env['NEXT_PUBLIC_BUILD_MODE'] === 'DEV'
      ? process.env['NEXT_PUBLIC_PB_API']
      : '/api',
  withCredentials: true,
  headers: {
    'promo-token': `${process.env['NEXT_PUBLIC_API_TOKEN']}`,
  },
}

const pbAxios = axios.create(pbConfig)

export default pbAxios
