import { DictionaryType } from '$hooks/useLanguageDictionary/useLanguageDictionary'

const onlyCyrillicAndOther =
  (dictionary: DictionaryType) => (value: string) => {
    if (value) {
      // if (!/[а-яёА-ЯЁ0-9.,\-]+$/.test(value)) {
      if (!/^[а-яёА-ЯЁ0-9.,\-\s]+$/g.test(value)) {
        return dictionary.errors.onlyCyrillicAndOther
      }
    }
  }

export default onlyCyrillicAndOther
