import { DictionaryType } from '$hooks/useLanguageDictionary/useLanguageDictionary'

const onlyCyrillic = (dictionary: DictionaryType) => (value: string) => {
  if (value) {
    if (!/[а-яёА-ЯЁ]+$/.test(value)) {
      return dictionary.errors.onlyCyrillic
    }
  }
}

export default onlyCyrillic
