import React from 'react'

// STYLE
import classes from './Preloader.module.scss'

const Preloader = () => (
  <div className={classes['wrapper']}>
    <div className={classes['preloader']} />
  </div>
)

export default Preloader
