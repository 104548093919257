import React from 'react'

import { useAppSelector } from '$store/hooks'

const PromotionalMailing: React.FC = () => {
  const language = useAppSelector((store) => store.language.language)

  if (language === 'ru') {
    return (
      <div>
        Я&nbsp;даю{' '}
        <a target="_blank" rel="noreferrer" href="/advertisement-agreement.pdf">
          согласие
        </a>{' '}
        на&nbsp;получение рекламной и&nbsp;информационной рассылки
      </div>
    )
  }

  if (language === 'kz') {
    return (
      <div>
        Мен жарнамалық және ақпараттық бюллетень алуға
        <a target="_blank" rel="noreferrer" href="/advertisement-agreement.pdf">
          келісім
        </a>{' '}
        беремін
      </div>
    )
  }

  return null
}

export default PromotionalMailing
