import { RefObject } from 'react'

const scrollToErrorField = (isError: boolean, ref: RefObject<HTMLElement>) => {
  if (isError && ref.current) {
    const elem = ref.current.querySelector<HTMLLabelElement>('.error')
    elem?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
    elem?.focus()
  }
}

export default scrollToErrorField
