import React, { useMemo } from 'react'

import classNames from 'classnames'

import { RootCheckBoxPropsInterface } from './types'

import s from './CheckBoxInput.module.scss'

const shadowInputClasses = classNames(s['checkbox-input-shadow'])
const checkboxClasses = classNames(s['checkbox__checked'])
const checkboxWrapperClasses = classNames(s['checkbox__wrpapper'])
const checkboxFlexLabelClasses = classNames(s['checkbox__flex-label'])

const RootCheckBox: React.FC<RootCheckBoxPropsInterface> = ({
  children,
  value,
  hiddenCheckBox,
  onChange = () => {},
  name,
  ...props
}) => {
  const inputWrapperClasses = useMemo(
    () =>
      classNames(s['input-wrapper__checkbox'], s['checkbox'], {
        [s['hidden']]: hiddenCheckBox,
      }),
    [hiddenCheckBox],
  )
  const labelClasses = useMemo(
    () =>
      classNames(s['input-wrapper__label-text'], s['checkbox-label'], {
        [s['non-click']]: hiddenCheckBox,
      }),
    [hiddenCheckBox],
  )

  return (
    <div className={s['input-wrapper']}>
      <div className={checkboxWrapperClasses}>
        {hiddenCheckBox && <input type="text" className={shadowInputClasses} />}
        <label className={checkboxFlexLabelClasses}>
          <input
            tabIndex={0}
            className={shadowInputClasses}
            onChange={onChange}
            checked={!!value}
            type="checkbox"
            {...props}
          />
          <div className={inputWrapperClasses}>
            <div className={checkboxClasses} />
          </div>
        </label>
        <div className={labelClasses}>{children}</div>
      </div>
    </div>
  )
}

export default RootCheckBox
