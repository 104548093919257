function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180
}

function rotateSize(width: number, height: number, rotation: number) {
  const rotRad = getRadianAngle(rotation)

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  }
}

const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous')
    image.src = url
  })

const getCroppedImage = async (
  cropperType: string,
  imageSrc: string,
  pixelCrop: any,
  rotation = 0,
) => {
  const image = (await createImage(imageSrc)) as HTMLImageElement
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  const horizontal = !!(rotation % 180)

  if (!ctx) {
    return null
  }

  const rotRad = getRadianAngle(rotation)
  canvas.width = horizontal ? image.height : image.width
  canvas.height = horizontal ? image.width : image.height

  const croppedCanvas = document.createElement('canvas')
  const croppedCtx = croppedCanvas.getContext('2d')

  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation,
  )

  ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
  ctx.rotate(rotRad)
  ctx.translate(-image.width / 2, -image.height / 2)
  ctx.drawImage(image, 0, 0)

  if (!croppedCtx) {
    return null
  }

  const cropperWidth = (pixelCrop.width / 100) * bBoxWidth
  const cropperHeight = (pixelCrop.height / 100) * bBoxHeight
  const offsetWidth = (pixelCrop.x / 100) * bBoxWidth
  const offsetHeight = (pixelCrop.y / 100) * bBoxHeight

  croppedCanvas.width = cropperWidth
  croppedCanvas.height = cropperHeight

  croppedCtx.drawImage(
    canvas,
    offsetWidth,
    offsetHeight,
    cropperWidth,
    cropperHeight,
    0,
    0,
    cropperWidth,
    cropperHeight,
  )

  croppedCtx.fillStyle = 'white'

  if (cropperType === 'ru') {
    croppedCtx.fillRect(
      0.025 * cropperWidth,
      0.58 * cropperHeight,
      0.31 * cropperWidth,
      0.3 * cropperHeight,
    )
    croppedCtx.fillRect(
      0.61 * cropperWidth,
      0.3 * cropperHeight,
      0.25 * cropperWidth,
      0.15 * cropperHeight,
    )
  }

  if (cropperType === 'kz') {
    croppedCtx.fillRect(
      0.025 * cropperWidth,
      0.58 * cropperHeight,
      0.31 * cropperWidth,
      0.3 * cropperHeight,
    )
    croppedCtx.fillRect(
      0.05 * cropperWidth,
      0.35 * cropperHeight,
      0.9 * cropperWidth,
      0.13 * cropperHeight,
    )
  }

  return new Promise((resolve) => {
    croppedCanvas.toBlob((file) => {
      if (file) {
        resolve({
          url: URL.createObjectURL(file),
          file: new File([file], 'passport.jpg'),
        })
      }
    }, 'image/jpeg')
  })
}

export default getCroppedImage
