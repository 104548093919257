import Cropper from 'react-easy-crop'
import { Dispatch, SetStateAction, useState, useRef, useMemo } from 'react'

import PassportRUS from './passRUS.svg?component'
import PassportKZ from './passKZ.svg?component'

import s from './style.module.scss'
import getCroppedImage from './_utils/getCropperImage'
import RootRangeInput from '$components/ui/fields/RangeInput'
import RotateIcon from './rotate.svg?component'
import TransformIcon from './transform.svg?component'
import { useAppSelector } from '$store/hooks'
import { useDispatch } from 'react-redux'
import { hidePopup } from '$store/slices/popup'
import { UploadButton } from '$components/form/fields/basicInput/FileInput/FileInput'
import Button from '$components/ui/buttons/RootButton/Button'
import classNames from 'classnames'

const ASPECT = 210 / 278

const CropSection: React.FC<{
  setCroppedImg: Dispatch<SetStateAction<string | null>>
}> = ({ setCroppedImg }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedArea, setCroppedArea] = useState<any | null>(null)
  const [rotation, setRotation] = useState(0)
  const [file, setFile] = useState<null | File>(null)

  const { passport_type } = useAppSelector(
    (state) => state.config.auth.personalInfoSettings!,
  )

  const image = useMemo(() => (file ? URL.createObjectURL(file) : null), [file])
  const inputRef = useRef<HTMLInputElement>(null)

  const croppedImage = async () => {
    try {
      const croppedImage = (await getCroppedImage(
        passport_type,
        image as string,
        croppedArea,
        rotation,
      )) as string
      setCroppedImg(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }

  return file ? (
    <>
      <div className={s['crop-container']}>
        <div className={s['crop-container__left']}>
          <div>
            <TransformIcon className={s['svg']} />
            <p>Перетащите изображение, чтобы изменить положение</p>
          </div>
          <p>Основная информация должна быть внутри рамки</p>
        </div>
        <div>
          <div className={s['crop']}>
            <Cropper
              rotation={rotation}
              objectFit="contain"
              aspect={ASPECT}
              zoom={zoom}
              image={image!}
              crop={crop}
              onZoomChange={setZoom}
              onCropChange={setCrop}
              onCropAreaChange={setCroppedArea}
              showGrid={false}
              zoomSpeed={0.1}
              classes={{
                cropAreaClassName: classNames(s['crop-area'], s[passport_type]),
              }}
            />
          </div>
          <div className={s['option-bar']}>
            <RootRangeInput
              step={0.1}
              value={zoom}
              minValue={1}
              maxValue={5}
              onChange={(e) => {
                setZoom(e as number)
              }}
            />
            <button
              onClick={() => setRotation((deg) => deg + 90)}
              className={s['rotate-btn']}
            >
              <RotateIcon />
            </button>
          </div>
        </div>
        <div className={s['crop-container__right']}>
          <p>Проверьте правильность расположения выделенных данных</p>
        </div>
      </div>

      <div className={s['confirm-button']}>
        <Button outline onClick={() => inputRef.current?.click()}>
          Загрузить другой файл
        </Button>
        <Button
          onClick={() => {
            croppedImage()
          }}
        >
          Продолжить
        </Button>
        <input
          style={{ display: 'none' }}
          onChange={(e) => {
            setFile(e.target.files![0])
          }}
          type="file"
          ref={inputRef}
        />
      </div>
    </>
  ) : (
    <div className={s['placeholder']}>
      <div className={s['passport']}>
        {passport_type === 'ru' ? <PassportRUS /> : <PassportKZ />}
      </div>
      <div className={s['info-text']}>
        <h4 className={s['info-text__title']}>Основные требования</h4>
        <p>Копия должна быть как на примере</p>
        <p>Без посторонних предметов, теней и бликов</p>
        <p>Цветное изображение</p>
      </div>
      <div className={s['attach_file']}>
        <div style={{ display: 'block' }}>
          <UploadButton onClick={() => inputRef.current?.click()} />
          <input
            style={{ display: 'none' }}
            onChange={(e) => {
              setFile(e.target.files![0])
            }}
            type="file"
            ref={inputRef}
          />
        </div>
      </div>
    </div>
  )
}

const CropPassportPopup = () => {
  const dispatch = useDispatch()
  const popupData = useAppSelector(({ popup }) => popup.popupConfig.popupData)
  const [croppedImg, setCroppedImg] = useState<any | null>(null)

  return (
    <div>
      <h3 className={s['title']}>Загрузите фото паспорта</h3>
      <p className={s['sub-header']}>2 и 3 страницу с фото</p>
      {croppedImg ? (
        <div>
          <img className={s['preview']} src={croppedImg.url} />
          <div className={s['text-info']}>
            Мы&nbsp;не&nbsp;используем паспортные данные для идентификации,
            поэтому ваша фотография и&nbsp;подпись у&nbsp;нас
            не&nbsp;обрабатываются и&nbsp;не&nbsp;хранятся. Мы&nbsp;работаем
            только с&nbsp;теми личными данными, на&nbsp;обработку которых
            мы&nbsp;получили ваше согласие и&nbsp;без которых невозможно участие
            в&nbsp;акции.
          </div>
          <div className={s['tool-bar']}>
            <Button
              outline
              onClick={() => {
                setCroppedImg(null)
              }}
            >
              Назад
            </Button>
            <Button
              onClick={() => {
                popupData?.onChange(croppedImg.file)
                dispatch(hidePopup())
              }}
            >
              Сохранить
            </Button>
          </div>
        </div>
      ) : (
        <CropSection setCroppedImg={setCroppedImg} />
      )}
    </div>
  )
}

export default CropPassportPopup
