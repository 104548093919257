import getParameters from '$constants/common/getParameters/getParameters'
import { PopupType } from '$constants/common/popupsID/popupsID'

export function popupLink(popup: PopupType, base = '') {
  return `${base}?${getParameters.POPUP_ID}=${popup}`
}

export function blockLink(block: string, base = '/') {
  return `${base}#${block}`
}
