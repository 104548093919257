import { createAsyncThunk } from '@reduxjs/toolkit'

import setCookie from '$utils/cookie/setCookie'

import { LanguagesType } from '../types'

export const setLanguageWitchCookie = createAsyncThunk(
  'language/setLanguage',
  async (language: LanguagesType) => {
    /* eslint no-useless-computed-key: "error" */
    setCookie('language', language, { 'Max-Age': 259000, Path: '/' })
    return language
  },
)
