import React from 'react'

import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'

import SignInForms from '$form/formik/forms/auth/SignInForms/SignInForms'

const SignInPopup = () => {
  const dictionary = useLanguageDictionary()
  return (
    <div className="form-popup">
      <h2 className="popup__title popup__title_md">
        {dictionary.header.signIn}
      </h2>
      <SignInForms />
    </div>
  )
}

export default SignInPopup
