import { PopupConfig } from '$store/slices/popup/types'

import { extractValidationErrors } from '$utils/validationError'

export default function createPopupErrorMiddleware(
  showPopup: (config: PopupConfig) => void,
  rethrow = true,
) {
  return (data: any) => {
    const err = extractValidationErrors(data)

    const { showIn, errors } = err

    if (showIn !== 'popup') {
      throw data
    }

    if (err.error) {
      showPopup({
        title: err.error.title,
        message: err.error.text,
        type: 'error',
      })
    } else {
      showPopup({
        title: errors,
        type: 'error',
      })
    }

    if (rethrow) {
      throw data
    }
  }
}
