import React from 'react'

import classNames from 'classnames'
import { useField } from 'formik'

import DateTimePickerField from '$form/fields/basicInput/DateTimePicker/DateTimePicker'
import Error from '$form/fields/other/errors/Error'

import { DateTimeFormikFieldInterface } from '../../type/FormikFieldType'

const DateTimeField: React.FC<DateTimeFormikFieldInterface> = ({
  ...props
}) => {
  const [field, { touched, error }] =
    useField<DateTimeFormikFieldInterface>(props)
  const isError = touched && error

  const inputWrapperClasses = classNames('input-wrapper', { error: isError })

  return (
    <div className={inputWrapperClasses}>
      <DateTimePickerField {...field} {...props} />
      {isError && <Error error={isError} />}
    </div>
  )
}

export default DateTimeField
