import { FC } from 'react'

import Link from 'next/link'

import { BottomLinksProps } from './types'

import s from './BottomFormsLinks.module.scss'

const BottomLinks: FC<BottomLinksProps> = ({ links }) => (
  <nav>
    <ul className={s['bottom-links']}>
      {links.map(({ href, name }) => (
        <li key={name}>
          <Link href={href} shallow scroll={false} legacyBehavior={false}>
            {name}
          </Link>
        </li>
      ))}
    </ul>
  </nav>
)
export default BottomLinks
