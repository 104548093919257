import React, {
  ButtonHTMLAttributes,
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import classNames from 'classnames'
import { FieldInputProps, useFormikContext } from 'formik'
import { FileDrop } from 'react-file-drop'
import { useDispatch } from 'react-redux'

import PopupId from '$constants/common/popupsID/popupsID'

import { showPopup } from '$store/slices/popup'

import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'

import CloseIconStrict from '$components/icons/close/CloseIconStrict'
import Preloader from '$components/layoutParts/Preloader/Preloader'
import Button from '$components/ui/buttons/RootButton/Button'

import { FileFieldInterface } from '$form/fields/formikField/type/FormikFieldType'

const setFileNamesStr = (val: FileList) => {
  let fileNameStr = ''
  for (let i = 0; i < val.length; i++) {
    fileNameStr += ` ${val[i].name}`
  }
  return fileNameStr
}

const btnWrapperClasses = classNames('btn-wrapper')
const fileNamesClasses = classNames('file-names')
const attachIconClasses = classNames('attach-icon')
const dragAreaClasses = classNames('drag-area')
const dragWrapperClasses = classNames('drag-wrapper')
const uploadRulesClasses = classNames('upload-rules')

const AllowFormat: React.FC<{
  allowFileFormat?: string
  maxFileSize?: string
}> = ({ allowFileFormat = '', maxFileSize = '' }) => (
  <>
    {(allowFileFormat || maxFileSize) && (
      <span className="option-label">
        {allowFileFormat && `Допустимые форматы: ${allowFileFormat}.`}
        <br />
        {maxFileSize && `Максимальный размер: ${maxFileSize}мб.`}
      </span>
    )}
  </>
)

export const UploadButton: React.FC<ButtonHTMLAttributes<unknown>> = ({
  onClick,
  disabled,
}) => (
  <div className={btnWrapperClasses}>
    <button
      disabled={disabled}
      onClick={onClick}
      type="button"
      className="button"
      data-test="upload-button"
    >
      <span className={attachIconClasses} /> Прикрепить файл
    </button>
  </div>
)

const FileInput: React.FC<
  FieldInputProps<FileFieldInterface> & FileFieldInterface
> = ({
  name,
  format,
  label = '',
  place,
  maxFileSize,
  allowFileFormat,
  disabled,
  value,
  cropperType,
  previewImage = {
    preview: '',
    isShow: false,
  },
}) => {
  const [fileNames, setFileNames] = useState('')
  const [filePreview, setFilePreview] = useState(previewImage.preview)
  const dictionary = useLanguageDictionary()

  const { setFieldValue, isSubmitting, submitForm } = useFormikContext()
  const dispatch = useDispatch()
  const shadowInputRef = useRef<HTMLInputElement>(null)

  // https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL#memory_management
  const safeSetFilePreview = useCallback((value: string) => {
    setFilePreview((prev) => {
      if (prev.startsWith('blob:')) {
        URL.revokeObjectURL(prev)
      }

      return value
    })
  }, [])

  const resetField = useCallback(() => {
    setFileNames('')
    safeSetFilePreview('')
    setFieldValue(name, '', true)
    if (shadowInputRef.current) {
      shadowInputRef.current.value = ''
    }
  }, [name, safeSetFilePreview, setFieldValue])

  useEffect(() => {
    if (!value || typeof value === 'string') {
      resetField()
    }
  }, [value])

  const setFieldFileValue = useCallback(
    (value: FileList) => {
      setFileNames((prev) => setFileNamesStr(value) || prev)
      let url = null
      try {
        url = URL.createObjectURL(value[0])
      } catch (error) {
        throw new Error(
          JSON.stringify({
            name: value[0].name,
            size: value[0].size,
            type: value[0].type,
          }),
        )
      }
      safeSetFilePreview(url)
      setFieldValue(name, value, true)
    },
    [name, safeSetFilePreview, setFieldValue],
  )

  const resetFieldFileValue: React.MouseEventHandler = useCallback(
    (e: MouseEvent<SVGSVGElement>) => {
      e.stopPropagation()
      resetField()
    },
    [resetField],
  )

  const onFileInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFieldFileValue(event.target.files!)
    },
    [setFieldFileValue],
  )

  const dragUploadFile = useCallback(
    (values: FileList | null) => {
      if (values) {
        setFieldFileValue(values)
      }
    },
    [setFieldFileValue],
  )

  const targetClick: React.MouseEventHandler = () => {
    // @ts-ignore
    // eslint-disable-next-line no-restricted-globals
    event.preventDefault()
    shadowInputRef.current!.click()
  }

  const uploadHandler = () => (filePreview ? () => submitForm() : targetClick)

  const PreviewBlock: React.FC<{ showCloseIcon?: boolean }> = useCallback(
    ({ showCloseIcon }) => (
      <div className="preview-wrapper">
        {showCloseIcon && (
          <CloseIconStrict
            className="reset-field"
            disabled={disabled}
            onClick={resetFieldFileValue}
          />
        )}
        <div
          style={{
            backgroundImage: `url(${
              filePreview.length ? filePreview : previewImage.preview
            })`,
          }}
          className={classNames('check-preview')}
        >
          {/* <img
            className="check-img"
            src={filePreview.length ? filePreview : previewImage.preview}
            alt="image-preview"
          /> */}
        </div>
      </div>
    ),
    [disabled, resetFieldFileValue, filePreview, previewImage],
  )

  useEffect(() => {
    if (previewImage.preview) {
      fetch(previewImage.preview)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], 'preview.png')
          const fileList = new DataTransfer()
          fileList.items.add(file)
          setFieldValue(name, fileList.files)
        })
    }
  }, [name, previewImage.preview, setFieldValue])

  return (
    <div
      className={classNames(
        'file-drop-wrap',
        place && `file-drop-wrap_${place}`,
      )}
    >
      <FileDrop {...{ onDrop: dragUploadFile }}>
        <div>
          {format === 'button' ? (
            <div className="upload-button-wrapper">
              <div className="btn-label">{label}</div>
              {previewImage.isShow &&
                (!!filePreview || !!previewImage.preview.length) && (
                  <div className="upload-button-preview">
                    <PreviewBlock />
                  </div>
                )}
              <div className={btnWrapperClasses}>
                <UploadButton
                  disabled={disabled}
                  onClick={
                    cropperType
                      ? () => {
                          dispatch(
                            showPopup({
                              popupId: PopupId.crop_passport,
                              popupData: {
                                onChange: (file: File) => {
                                  const dt = new DataTransfer()
                                  dt.items.add(file)
                                  setFieldFileValue(dt.files)
                                },
                              },
                            }),
                          )
                        }
                      : targetClick
                  }
                />
                {!!fileNames && (
                  <span className={fileNamesClasses}>{fileNames}</span>
                )}
              </div>
              <AllowFormat
                allowFileFormat={allowFileFormat?.join(', ')}
                maxFileSize={maxFileSize}
              />
            </div>
          ) : (
            <div className={dragAreaClasses}>
              <div className={dragWrapperClasses}>
                <h3>{dictionary.header.uploadFiles}</h3>
                {/* <UploadButton  onClick={targetClick}/> */}
                {!!filePreview && <PreviewBlock showCloseIcon />}
                <div className={uploadRulesClasses}>
                  <AllowFormat
                    allowFileFormat={allowFileFormat?.join(', ')}
                    maxFileSize={maxFileSize}
                  />
                  {place !== 'checkUpload' && (
                    <div className={classNames('upload-btn-wrapper')}>
                      <Button
                        disabled={disabled}
                        onClick={targetClick}
                        type="button"
                      >
                        {dictionary.button.changeFile}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              {place === 'checkUpload' && (
                <div className={classNames('check-btn-wrapper')}>
                  <Button
                    disabled={disabled}
                    onClick={uploadHandler()}
                    type="button"
                  >
                    {filePreview ? (
                      isSubmitting ? (
                        <Preloader />
                      ) : (
                        dictionary.button.upload
                      )
                    ) : (
                      dictionary.button.changeFile
                    )}
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </FileDrop>
      <input
        onChange={onFileInputChange}
        ref={shadowInputRef}
        type="file"
        accept="image/,.jpeg,.png,.jpg"
        style={{ display: 'none' }}
      />
    </div>
  )
}

export default FileInput
