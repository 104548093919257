import React, { useMemo } from 'react'

import { useDispatch } from 'react-redux'

import { useAppSelector } from '$store/hooks'
import { setLanguageWitchCookie } from '$store/slices/language/thunks/language'

import RootSelect from '$components/ui/selects/RootSelect'

const HeaderLanguage: React.FC = () => {
  const dispatch = useDispatch()
  const languages = useAppSelector((store) => store.config.site?.languages!)
  const { language } = useAppSelector((store) => store.language)
  const multilang = useAppSelector((store) => store.config.site?.multilang)
  const languageOptions = useMemo(
    () =>
      languages.map(({ name }: { name: string }) => ({
        label: name,
        value: name.toLowerCase(),
      })),
    [],
  )

  if (multilang) {
    return (
      <div className="header-menu__item language">
        <RootSelect
          isClearable={false}
          size="middle"
          defaultValue={{ label: language.toUpperCase(), value: language }}
          name="language"
          options={languageOptions}
          onChange={(option: any) => {
            dispatch(setLanguageWitchCookie(option.value))
          }}
        />
      </div>
    )
  }
  return <></>
}

export default HeaderLanguage
