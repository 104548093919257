import { PromoStatusType } from '$store/slices/config/types/promo/promo.types'
import { RootState } from '$store/store'

export interface IsPromoEndedOptions {
  status: PromoStatusType
  placeholderEnd: string | undefined
  promoEnd: string | undefined
}

export default function isPromoEnded({
  status,
  placeholderEnd,
  promoEnd,
}: IsPromoEndedOptions) {
  if (status === 'after-end') {
    return true
  }

  const endDate = placeholderEnd ?? promoEnd

  if (!endDate) {
    return false
  }

  return new Date(endDate) < new Date()
}

export function isPromoEndedFromRootState(state: RootState) {
  const {
    status,
    period: { placeholder_end, promo_end },
  } = state.config.promo

  return isPromoEnded({
    status,
    placeholderEnd: placeholder_end,
    promoEnd: promo_end,
  })
}
