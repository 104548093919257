import React, { PropsWithChildren, useCallback, useContext } from 'react'

import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { HeaderContext } from '$services/HeaderMenuService/HeaderMenuService'

import { combineUrl } from '$utils/routing'

interface MenuNavLinkInterface {
  to: string
  isPopup?: boolean
  classPrefix?: string
  isHash?: boolean
  scroll?: boolean
  isLogoLink?: boolean
}

const MenuNavLink: React.FC<PropsWithChildren<MenuNavLinkInterface>> = ({
  to,
  classPrefix = '',
  isPopup,
  children,
  scroll = false,
  isLogoLink,
}) => {
  const { setOpenMenuState } = useContext(HeaderContext)

  const { asPath: path } = useRouter()
  const linkClickHandler = useCallback(() => {
    setOpenMenuState(false)

    // scroll to top of page, fuck SimpleBar
    if (to.includes('#')) {
      return
    }

    const [pathWithoutHash] = path.split('#')

    if (pathWithoutHash === to) {
      document.getElementById('main-root')?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      })
    }
  }, [path, setOpenMenuState, to])

  const isActive = to === path
  const isSideLink = to.indexOf('http') >= 0
  const resolvedLink = isSideLink ? to : combineUrl(path, to)

  const hashLinkClasses = classNames(
    'header-menu__link',
    classPrefix,
    isActive && 'header-menu__link_active',
    isPopup && 'header-menu__link_popup',
    isLogoLink && 'logo',
    !resolvedLink && 'disabled',
  )

  return (
    <Link
      href={resolvedLink}
      rel="noreferrer"
      target={isSideLink ? '_blank' : ''}
      onClick={linkClickHandler}
      shallow
      className={hashLinkClasses}
      scroll={scroll && !isPopup}
      legacyBehavior={false}
    >
      {children}
    </Link>
  )
}

export default MenuNavLink
