const other = {
  serviceFor:
    'Сервис промо-науқандарды өткізу процесін автоматтандыруға арналған',
  createdBy: {
    0: '',
    1: 'PromoBuilding',
    2: 'негізінде жасалған',
  },
  sections: 'Бөлімдер',
  tags: 'Тегтер',
  majority: 'Мен кәмелетке толғанымды растаймын (18 жаста).',
  media: 'Ақпараттық бюллетеньдер алуға келісемін',
  codeUploadManual:
    'Жарнамалық пакеттердегі кодтарды іздеңіз. Олар қорғаныс пленкасының артқы жағында орналасқан және 5 таңбадан тұрады.',
  retryCall: 'Қоңырауды қайталау',
  retrySend: 'Жіберуді қайталаңыз',
  retryAllow: 'Қайта жіберу арқылы мүмкін болады',
  chooseCategory: 'Санатты таңдаңыз',

  // eslint-disable-next-line
  reviewDefaultDescription: () => 'NULL',
}

export default other
