import React, { useMemo } from 'react'

import { connect, ConnectedProps } from 'react-redux'

import pbApi from '$api/promobuilding/pbApi'

import authFieldCollection from '$constants/common/forms/auth/fields/AuthFieldsConstant'
import AuthFieldNames from '$constants/common/forms/auth/fields/AuthFieldsName'
import popupsID from '$constants/common/popupsID/popupsID'
import routePath from '$constants/common/routePath/routePath'

import { showPopup } from '$store/slices/popup'
import { PopupConfig } from '$store/slices/popup/types'
import { AppDispatch, RootState } from '$store/store'

import useFormSubmitHandler from '$hooks/useFormSubmitHandler/useFormSubmitHandler'
import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'
import usePromoStatus from '$hooks/usePromoStatus/usePromoStatus'
import useValidators from '$hooks/useValidators/useValidators'

import TextInputField from '$components/form/formik/fields/TextInputField'
import validatorCombiner from '$components/form/validators/validatorCombiner'
import BottomLinks from '$components/ui/forms/BottomLinks'

import { popupLink } from '$utils/link'

import FormikWrapper from '../../../constructor/FormConstructor/formikConstructor'
import initializeValue from '../../../utils/InitializeFormikValues'

const RestorePasswordForm: React.FC<Props> = ({
  viewConfig,
  showPopupAction,
}) => {
  const dictionary = useLanguageDictionary()
  const initialValues = useMemo(
    () => initializeValue([AuthFieldNames.email])(),
    [],
  )

  const { isRequired, maxLength, isValidEmail } = useValidators()
  const postPromoHandler = usePromoStatus(false)
  postPromoHandler()

  const submitHandler = useFormSubmitHandler<typeof initialValues>(
    [],
    (values) =>
      pbApi.restorePassword({ email: values.email! }).then(() => {
        showPopupAction({
          type: 'common',
          title:
            'На указанную почту отправили ссылку для восстановления пароля',
          message: '',
        })
      }),
  )

  return (
    <>
      <FormikWrapper
        name="restorepass"
        onSubmit={submitHandler}
        initialValues={initialValues}
        dataTest="restore-button"
        submitButtonName={dictionary.button.restore}
      >
        <TextInputField
          name={authFieldCollection.email.name}
          placeholder={dictionary.fields.email}
          data-test="email-input"
          validate={validatorCombiner([
            isRequired,
            isValidEmail,
            maxLength(255),
          ])}
        />
      </FormikWrapper>

      {postPromoHandler() ? (
        <BottomLinks
          links={[
            {
              href:
                viewConfig === 'modal'
                  ? popupLink(popupsID.sign_in)
                  : routePath.auth,
              name: dictionary.links.signIn,
            },
          ]}
        />
      ) : (
        <BottomLinks
          links={[
            {
              href:
                viewConfig === 'modal'
                  ? popupLink(popupsID.sign_in)
                  : routePath.auth,
              name: dictionary.links.signIn,
            },

            {
              href:
                viewConfig === 'modal'
                  ? popupLink(popupsID.sign_up)
                  : routePath.reg,
              name: 'Зарегистрироваться',
            },
          ]}
        />
      )}
    </>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({
    viewConfig: state.config.auth?.viewTemplate,
    promoStauts: state.config.promo.status,
  }),
  (dispatch: AppDispatch) => ({
    showPopupAction: (config: PopupConfig) => {
      dispatch(showPopup(config))
    },
  }),
)

export default connector(RestorePasswordForm)
