import { createSlice } from '@reduxjs/toolkit'

export interface SiteState {
  maxNews: number
}

const initialState: SiteState = {
  maxNews: 0,
}

/* eslint no-param-reassign: "error" */
const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    setMaxNewsState: (state, action) => {
      state.maxNews = action.payload
    },
  },
})

export const { setMaxNewsState } = siteSlice.actions
export const { name } = siteSlice
export const slice = siteSlice
export default siteSlice.reducer
