import { useMemo } from 'react'

import Head from 'next/head'

import { useAppSelector } from '$store/hooks'

import ParseInsertHeadHtml from '$utils/site/parseInsertHeadHtml'

export default function HeadHtml() {
  const html = useAppSelector((state) => state.config.site?.html_in_head)

  const HeadTags = useMemo(() => {
    if (!html) {
      return null
    }

    const str = html?.map(({ code }) => code).join()

    return ParseInsertHeadHtml(str)
  }, [html])

  return <Head>{HeadTags}</Head>
}
