const RouterPath = {
  reg: '/reg',
  auth: '/auth',
  feedback: '/feedback',
  news: '/news',
  newsCategory: '/news/:category',
  personal: '/account/*',
  personalData: `/account/:chapter`,
  newspage: '/news/:category/:news',
  stores: '/stores',
  FAQ: '/faq',
  help: '/help',
  restorePassword: '/restore-pass',
  products: '/products',
  productsCategory: '/products/:category',
} as const

export default RouterPath
