import React from 'react'

import { useAppSelector } from '$store/hooks'

import CustomForm from '$components/form/formik/forms/customForm/customForm'
import { CustomFormType } from '$components/form/formik/forms/customForm/type'

const CustomFormPopup: React.FC = () => {
  const popupConfig = useAppSelector((store) => store.popup.popupConfig)
  const form = popupConfig.popupData as CustomFormType

  return (
    <div className="form-popup">
      <h2 className="popup__title popup__title_md">{form.name}</h2>
      <CustomForm form={form} />
    </div>
  )
}

export default CustomFormPopup
