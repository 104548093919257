import React, { PropsWithChildren } from 'react'

import classNames from 'classnames'
import Link from 'next/link'

import errorsID from '$constants/common/errorsID/errorsID'

import { useAppSelector } from '$store/hooks'

import ball from '../../assets/img/elements/ball.svg'
import closeButton from '../../assets/img/elements/close-button.svg'
import cursorAndBall from '../../assets/img/elements/cursor-and-ball.svg'
import warningBlock from '../../assets/img/elements/warning-block.svg'
import Button from '../../components/ui/buttons/RootButton/Button'

import classes from './BaseError.module.scss'

const BaseError: React.FC<
  PropsWithChildren<{
    text: string
    title: string
    btnName: string
    goTo: string
    fullScreen?: boolean
    external?: boolean
    pbStyles?: boolean
    id?: number
  }>
> = ({
  children,
  text,
  title,
  btnName,
  goTo,
  fullScreen = true,
  external,
  id,
  pbStyles,
}) => {
  const placeholderAfterPromo = useAppSelector(
    (store) => store.config.site?.placeholder_after_promo,
  )

  const notShowButtonBlock =
    id === errorsID.PROMO_IS_END && placeholderAfterPromo

  return (
    <div
      className={classNames(
        classes['errorLayout'],
        fullScreen && classes['errorLayout_fullscreen'],
      )}
    >
      <div className={classes['sideElements']}>
        <div className={classes['sideElements__cursor-and-ball']}>
          <img src={cursorAndBall} alt="cursor and ball elements" />
        </div>
        <div className={classes['sideElements__close-button']}>
          <img src={closeButton} alt="close button" />
        </div>
        <div className={classes['sideElements__warning-block']}>
          <img src={warningBlock} alt="warning block" />
        </div>
        <div className={classes['sideElements__ball']}>
          <img src={ball} alt="ball" />
        </div>
      </div>
      <div className={classes['errorBlock']}>
        <div className={classes['errorBlock__status']}>{children}</div>
        <div className={classes['errorBlock__name']}>
          <h3>{title}</h3>
          {!notShowButtonBlock && <p>{text}</p>}
        </div>
        {!notShowButtonBlock && (
          <div className={classes['errorBlock__button']}>
            {external ? (
              <a href={goTo}>
                <Button colorTheme={pbStyles ? 'pb' : 'def'}>{btnName}</Button>
              </a>
            ) : (
              <Link href={goTo} legacyBehavior={false}>
                <Button colorTheme={pbStyles ? 'pb' : 'def'}>{btnName}</Button>
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default BaseError
