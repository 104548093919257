import { useCallback, useEffect, useRef, useState } from 'react'

export default function useTimer(
  seconds: number,
): [remaining: number, reset: () => void] {
  const initialTime = useRef(seconds)

  const [time, setTime] = useState(initialTime.current)

  useEffect(() => {
    if (time < 1) {
      return
    }

    const interval = setTimeout(() => {
      setTime(time - 1)
    }, 1000)

    return () => clearTimeout(interval)
  }, [time])

  const reset = useCallback(() => setTime(initialTime.current), [])

  return [time, reset]
}
