import classNames from 'classnames'
import { ClipLoader } from 'react-spinners'

import s from './ClipSpinner.module.scss'

const ClipSpinner: React.FC<{ size: number }> = ({ size }) => (
  <ClipLoader className={classNames(s['spinner'])} size={size} />
)

export default ClipSpinner
