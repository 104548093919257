import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useFormikContext } from 'formik'
import { SingleValue } from 'react-select'
import { Subject, debounceTime } from 'rxjs'

import {
  AsyncSelectFormikFieldInterface,
  SelectOption,
} from '$components/form/types/FormikFieldType'
import RootSelect from '$components/ui/selects/RootSelect'

import searchHandlerWrapper from '$utils/searchSelect/searchHandler'

import FieldWrapperConstructor from '../../constructor/FieldWrapperConstructor/FieldWrapperConstructor'

const AsyncSelectField: React.FC<AsyncSelectFormikFieldInterface> = ({
  loadOptions,
  ...props
}) => {
  const { name } = props
  const { setFieldValue, initialValues, setFieldTouched, values } =
    useFormikContext()
  const [selectValue, setSelectValue] = useState<{
    label: string
    value: string
  } | null>(null)

  const [options, setOption] = useState<SelectOption>([])

  const subj = useMemo(() => new Subject(), [])
  const searchHandler = useCallback(searchHandlerWrapper(subj), [subj])

  useEffect(() => {
    const initValue: any = initialValues
    if (initValue[name]) {
      setSelectValue({
        label: initValue[name],
        value: initValue[name],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChange = useCallback(
    (option: SingleValue<{ label: string; value: string }>) => {
      setFieldValue(name, option?.value)
      if (!option?.value) {
        setFieldTouched(name)
      }
      setSelectValue(option)
    },
    [name, setFieldValue],
  )

  useEffect(() => {
    subj.pipe(debounceTime(300)).subscribe((value) => {
      if (typeof value !== 'string') return
      loadOptions(value).then((option) => {
        setOption(option)
      })
    })

    subj.next('')
  }, [subj])

  useEffect(() => {
    if (values) {
      // @ts-ignore
      const formikContextValue = values[name]
      if (!formikContextValue) {
        setSelectValue(formikContextValue)
      }
    }
  }, [values, name])

  return (
    <FieldWrapperConstructor
      component={RootSelect}
      {...{
        onChange,
        value: selectValue,
        isSearchable: true,
        isMulti: false,
        isColor: true,
        options,
        onInputChange: searchHandler,
        ...props,
      }}
    />
  )
}

export default AsyncSelectField
