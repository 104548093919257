import React from 'react'

import classNames from 'classnames'

import {
  ConfigSocialMedias,
  SocialMediaType,
} from '$store/slices/config/types/site/footer.types'

import IgSvg from '../../assets/img/sn/ig.svg?component'
import OkSvg from '../../assets/img/sn/ok.svg?component'
import TgSvg from '../../assets/img/sn/tg.svg?component'
import VkSvg from '../../assets/img/sn/vk.svg?component'
import YtSvg from '../../assets/img/sn/yt.svg?component'
import normalizeLink from '../../utils/normalizeLink'

import classes from './SocialMedias.module.scss'

export interface SocialMediasProps {
  variant: 'white' | 'accent'
  data: ConfigSocialMedias[]
}

const iconsPaths: Record<SocialMediaType, typeof VkSvg> = {
  vk: VkSvg,
  odnoklassniki: OkSvg,
  youtube: YtSvg,
  telegram: TgSvg,
  instagram: IgSvg,
}

export default function SocialMedias({ variant, data: sn }: SocialMediasProps) {
  return (
    <div className={classes['icons']}>
      {sn.map(({ id, link, social_media }) => {
        const Icon = iconsPaths[social_media]

        return (
          <a
            key={`${link}-${id}`}
            href={normalizeLink(link)}
            target="_blank"
            rel="noopener noreferrer"
            className={classNames(
              classes['sn'],
              variant === 'accent' && classes['sn_accent'],
            )}
          >
            {Icon && <Icon className={classes['icon']} />}
          </a>
        )
      })}
    </div>
  )
}
