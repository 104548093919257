import React, { ButtonHTMLAttributes } from 'react'

import classNames from 'classnames'

import classes from './CloseIcon.module.scss'

const CloseIcon: React.FC<ButtonHTMLAttributes<unknown>> = ({
  onClick,
  disabled,
  className,
  ...props
}) => (
  <div
    {...props}
    onClick={onClick}
    className={classNames(
      classes['icon'],
      disabled && classes['icon_disabled'],
      className,
    )}
  >
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.625 1C2.51081 1.09436 3.38952 2.69632 3.93934 3.30903C5.4061 4.94355 6.65065 6.7932 8.07908 8.46528C9.05435 9.6069 10.1209 10.6644 11.0779 11.816C12.4848 13.5089 14.2941 14.6785 16 16"
        stroke="#BC955C"
        stroke-width="2"
        strokeLinecap="round"
      />
      <path
        d="M16 1C15.1155 1.74319 14.1769 2.84061 13.319 3.6184C11.2262 5.5156 9.86522 6.80396 7.875 8.82609C6.28827 10.4382 4.97714 11.6286 3.47104 13.3276C2.86814 14.0077 1.61776 15.0215 1 15.9229"
        stroke="#BC955C"
        stroke-width="2"
        strokeLinecap="round"
      />
    </svg>

    {/* <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.856 0.895202L17.963 0.992495C18.5144 1.54382 18.5468 2.41755 18.0603 3.00681L17.963 3.11382L11.5987 9.47738L17.9853 15.864C18.5366 16.4153 18.569 17.289 18.0826 17.8783L17.9853 17.9853C17.434 18.5366 16.5602 18.5691 15.971 18.0826L15.864 17.9853L9.47737 11.5987L3.1138 17.9631C2.56248 18.5144 1.68875 18.5468 1.09949 18.0604L0.992483 17.9631C0.441155 17.4117 0.408723 16.538 0.89519 15.9487L0.992483 15.8417L7.35605 9.47738L1.01472 3.13605C0.46339 2.58472 0.43096 1.71099 0.917426 1.12173L1.01472 1.01473C1.56605 0.463402 2.43978 0.430971 3.02904 0.917437L3.13604 1.01473L9.47737 7.35606L15.8417 0.992495C16.3931 0.441167 17.2668 0.408736 17.856 0.895202L17.963 0.992495L17.856 0.895202Z"
      />
    </svg> */}
  </div>
)

export default CloseIcon
