import React from 'react'

import classNames from 'classnames'

import { useAppSelector } from '$store/hooks'

import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'

import MainContainer from '../../container/MainContainer/MainContainer'
import SocialMedias from '../../SocialMedias/SocialMedias'
import MenuNavLink from '../Header/MenuNavLink'

import classes from './Footer.module.scss'

const Footer: React.FC = () => {
  const dictionary = useLanguageDictionary()
  const footer = useAppSelector((state) => state.config.site!.footer)
  const name = useAppSelector((state) => state.config.promo.name)
  const { logo } = useAppSelector((state) => state.config.promo.content)

  const baseURL = process.env['NEXT_PUBLIC_PB_API']!.replace('/api', '')

  const logoLink = useAppSelector(
    (store) => store.config.site?.logo_target_link,
  )

  // const confidentialityLink = useAppSelector(
  //   (state) => state.config.promo.confidentiality_agreement,
  // )

  const { social_media: socialMedia, config } = footer
  const { position } = config ?? {}

  const isLeft = position === 'left'
  const isRight = position === 'right'

  const serviceInfoClassName = classNames(
    classes['serviceInfo'],
    isLeft && classes['serviceInfo_right'],
    isRight && classes['serviceInfo_left'],
  )

  const itemClassName = classNames(
    classes['item'],
    isLeft && classes['item_right'],
    isRight && classes['item_left'],
  )

  return (
    <footer className={classes['footer']}>
      <MainContainer>
        <div className={classes['container']}>
          <div className={classNames('logo-container', classes['logo'])}>
            {logo &&
              (logoLink ? (
                <a
                  className="header-menu__link logo"
                  href={logoLink}
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src={logo} alt="logo" className="logo-image" />
                </a>
              ) : (
                <MenuNavLink to="/" scroll isLogoLink>
                  <img src={logo} alt="logo" className="logo-image" />
                </MenuNavLink>
              ))}
          </div>
          <div className={classes['content']}>
            <nav className={classes['navigation']}>
              <MenuNavLink to="?popupid=feedback">Обратная связь</MenuNavLink>
              <MenuNavLink to={`${baseURL}/rules.pdf`}>
                Правила акции
              </MenuNavLink>
              <MenuNavLink to={`${baseURL}/personal-data-policy.pdf`}>
                Политика&nbsp;обработки&nbsp;персональных данных
              </MenuNavLink>
            </nav>
            <div
              className={classNames(
                classes['inner'],
                isLeft && classes['inner_left'],
                isRight && classes['inner_right'],
              )}
            >
              {config?.copyright && (
                <div
                  className={classes['copyright']}
                  dangerouslySetInnerHTML={{ __html: config?.copyright }}
                />
              )}
            </div>
            <div className={classes['token-dt']}>
              {/* + токен присвоенный в ОРД */}{' '}
            </div>
          </div>

          <div className={serviceInfoClassName}>
            <div className={classes['social-wrap']}>
              <SocialMedias variant="white" data={socialMedia} />
            </div>
            <div className={classes['token-mob']}>
              {/* + токен присвоенный в ОРД */}{' '}
            </div>

            <div>
              {!config?.copyright && (
                <span className={itemClassName}>
                  {dictionary.other.serviceFor}
                </span>
              )}
              {/* <span className={itemClassName}>
                <a target="_blank" rel="noreferrer" href={confidentialityLink}>
                  Политика конфиденциальности
                </a>
              </span> */}
              {config?.show_promobuilding && (
                <span className={itemClassName}>
                  {dictionary.other.createdBy[0]}{' '}
                  <a
                    href={`https://promobuilding.ru/?utm_source=promobuilding&utm_medium=footer&utm_campaign=${name}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {dictionary.other.createdBy[1]}
                  </a>{' '}
                  {dictionary.other.createdBy[2]}
                </span>
              )}
            </div>
          </div>
        </div>
      </MainContainer>
    </footer>
  )
}

export default Footer
