import React from 'react'

import { useAppSelector } from '$store/hooks'

import CookiePolicy from './Content/CookiePolicy/CookiePolicy'
import DefaultTost from './Content/DefaultTost'
import BaseTostLayout from './Layout/BaseTostLayout'

import tostID, { TostType } from '../../constants/common/tostID'

const TostContent = {
  [tostID.COOKIE_POLICY]: CookiePolicy,
}

const TostManagement = () => {
  const { tostConfig, activeState } = useAppSelector((store) => store.tost)
  const tostId = tostConfig.id as TostType

  const Tost = TostContent[tostId] ?? DefaultTost

  return (
    <BaseTostLayout visible={activeState}>
      <Tost />
    </BaseTostLayout>
  )
}

export default TostManagement
