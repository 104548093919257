import { useState, useEffect } from 'react'
import InputRange from 'react-input-range'
import { RootRangeInputInterface } from './types'
import { MinusRangeButton, PlusRangeButton } from './buttons'

import s from './style.module.scss'

const RootRangeInput: React.FC<RootRangeInputInterface> = ({
  value,
  onChange,
  ...props
}) => {
  const [valueInside, setValueInside] = useState<number>(props.minValue ?? 0)

  useEffect(() => {
    setValueInside(value as number)
  }, [value])

  useEffect(() => {
    onChange(valueInside)
  }, [valueInside])

  return (
    <div className={s['root-range-input']}>
      <PlusRangeButton
        onClick={() =>
          setValueInside((value) => {
            if (typeof value === 'number') {
              return value === props.maxValue
                ? value
                : value + (props.step ?? 1)
            }
            return value
          })
        }
      />
      <InputRange
        onChange={(value) => {
          setValueInside(value as number)
        }}
        value={valueInside}
        {...props}
      />
      <MinusRangeButton
        onClick={() =>
          setValueInside((value) => {
            if (typeof value === 'number') {
              return value === props.minValue
                ? value
                : value - (props.step ?? 1)
            }
            return value
          })
        }
      />
    </div>
  )
}

export default RootRangeInput
