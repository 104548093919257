const links = {
  account: 'Личный кабинет',
  businessChallenge: 'Бизнес-задачи',
  profile: 'Профиль',
  signUp: 'Регистрация',
  signIn: 'Войти',
  restorePassword: 'Забыли пароль',
  policy: 'Политика конфиденциальности',
  rules: 'Правила проекта',
  getPrize: 'Получить приз',
}

export default links
