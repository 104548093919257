import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

import { setFullConfig } from '$store/slices/config/thunks/config'

import { MainConfigType } from './types'

export type ConfigReducerType = Omit<MainConfigType, 'user'>

export const initialState: ConfigReducerType = {
  auth: {
    viewTemplate: 'modal',
    authFields: [],
    promoRulesValue: 1,
    personalEnabledFor: null,
    personalInfoEnabled: false,
    personalInfoFields: [],
    referralProgramConfig: {
      isEnabled: false,
    },
    customInputConfig: {
      custom_input_is_required: 1,
      custom_input_name: '',
    },
    phoneConfirmConfig: {
      verify_method: 'sms',
      verify_required: 0,
    },
    signInConfig: {
      login_by: 'email',
    },
  },
  inviteCode: {
    isConfirm: false,
    code: null,
  },
  rewards: {
    prizes: [],
    settings: {
      order_opening_date: '',
      receive_opening_date: '',
      value_real_limit: '',
    },
    account: {
      balance: 0,
      orders: [],
      orders_value_real_sum: 0,
    },
    draw: {
      draws: [],
      drawTitles: [],
      prizes: [],
      provse_certificate_link: '',
    },
  },
  promo: {
    advertisement_agreement: '',
    confidentiality_agreement: '',
    name: '',
    content: {
      logo: '',
    },
    cookie_policy: '',
    favicon: '',
    name_readable: '',
    period: {
      placeholder_end: '',
      placeholder_start: '',
      promo_end: '',
      promo_start: '',
    },
    rules: '',
    status: 'active',
    google_analytics: '',
    google_tag_manager: '',
    yandex_metrika: '',
  },
}

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfig: (state, { payload }: PayloadAction<MainConfigType>) => {
      if (state) {
        return {
          state,
          ...payload,
        }
      }
      return state
    },
    setConfirmInviteCode: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      inviteCode: {
        code: payload,
        isConfirm: true,
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(setFullConfig.fulfilled, (state, { payload }) => {
      const { user, ...rest } = payload

      return {
        ...state,
        ...rest,
      }
    })

    builder.addCase(HYDRATE, (state, action) => {
      const {
        payload: { config },
      } = action as any

      return {
        ...state,
        ...config,
      }
    })
  },
})

export const { setConfig, setConfirmInviteCode } = configSlice.actions

export const { name } = configSlice

export const slice = configSlice

export default configSlice.reducer
